.modal_content {
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid var(--border-color);
}

.m_head h3 {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.m_body {
  overflow-y: auto;
  padding: 10px 24px 50px;
}

.block_section_filter {
  padding-top: 30px;
  /* border-bottom: 1px solid var(--border-color); */
}

.m_body .block_section_filter h3 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: #000;
  padding-bottom: 20px;
}

/*------checkbox-type logement----*/
.block_section_filter p {
  font-size: 13px;
}

.block_section_filter button {
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  color: var(--noir);
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  cursor: pointer;
  transition: 0.3s;
}

.block_section_filter button:active {
  transform: scale(0.9);
}

.grid_system {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.checkox_contener {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.checkox_contener input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 18px;
  height: 18px;
  padding: 0;
  margin: 0px;
  cursor: pointer;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #c6c6c6;
}

.checkox_contener input[type="checkbox"]:hover {
  border: 1px solid var(--btn_noir1);
}

.checkox_contener input[type="checkbox"]:after {
  content: "";
  display: none;
  position: absolute;
  top: 2px;
  left: 5px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.checkox_contener input[type="checkbox"]:checked {
  background: var(--btn_noir1);
  border: 1px solid var(--btn_noir1);
}

.checkox_contener input[type="checkbox"]:checked:after {
  display: block;
}

.checkox_contener label {
  width: 100%;
  font-size: 14px;
  padding-left: 10px;
  cursor: pointer;
}

/*------checkbox-chambre et lits----*/

.search_block_text label {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 15px;
  border-radius: 8px;
  color: var(--noirc);
  background: #f5f5f5;
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
  overflow: hidden;
  cursor: pointer;
}

.search_block_text label:focus-within {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.search_block_text input {
  box-sizing: border-box;
  padding-left: 5px;
  width: 100%;
  height: 40px;
  font-size: 15px;
  color: var(--noir);
  background-clip: padding-box;
  background: transparent;
  white-space: nowrap;
  border: none;
  outline: none;
}

.close_disable {
  opacity: 0;
}

.close_active {
  opacity: 1;
}

.search_block_text label button.close {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(221, 221, 221);
  border-radius: 50%;
  border: none;
  outline: none;
  color: rgb(34, 34, 34);
  cursor: pointer;
  touch-action: manipulation;
  transition: -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s;
}

.search_block_text button svg {
  display: block;
  fill: none;
  height: 12px;
  width: 12px;
  stroke: currentcolor;
  stroke-width: 5.33333;
  overflow: visible;
}

.radio_container {
  display: flex;
  flex-wrap: wrap;
}

.radio_container label {
  cursor: pointer;
}

.radio_container label input[type="radio"] {
  display: none;
}

.radio_container label span {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background: rgb(247, 247, 247);
  font-weight: var(--font_display_1_font-weight);
  font-size: 15px;
  color: var(--noir);
  user-select: none;
  overflow: hidden;
}

.radio_container label span:hover {
  background: rgb(235, 235, 235);
}

.radio_container label input[type="radio"]:checked ~ span {
  background: var(--noir);
  color: #fff;
}

/*------block button----*/
.m_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 16px 24px;
  background: #fff;
  border-top: 1px solid var(--border-color);
}

.m_footer .effacher {
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  color: var(--noir);
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  cursor: pointer;
  transition: 0.3s;
}

.m_footer .effacher:active {
  transform: scale(0.9);
}

.m_footer .appliquer {
  padding: 12px 24px;
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  color: #fff;
  border: none;
  outline: none;
  background: var(--btn_noir1);
  border-radius: 8px;
  transition: 0.3s;
}

.m_footer .appliquer:hover {
  cursor: pointer;
  background: var(--btn_noir2);
}

.m_footer .appliquer:active {
  transform: scale(0.9);
}

.m_footer .affiMobile {
  display: none;
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }
}
@media screen and (max-width: 540px) {
  .m_body::-webkit-scrollbar {
    display: none;
  }

  .block_section_filter {
    padding: 24px 0;
  }
}

@media screen and (max-width: 500px) {
  .grid_system {
    grid-template-columns: 1fr;
  }
}

.m_footer .appliquer {
  font-size: 15px;
}
