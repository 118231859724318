.container {
  padding: 100px 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

@media screen and (min-width: 1440px) {
  .container {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

.left {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.left h3 {
  font-size: 40px;
  color: var(--noir);
  font-weight: var(--font_heading_2_font-weight);
  font-family: var(--font-family_heading);
}

.left p {
  font-size: 20px;
  color: var(--noir8);
  margin-top: 5px;
}

.block_link {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.block_link a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-decoration: none;
  border: 1px solid var(--noir);
  border-radius: 40px;
  height: 48px;
  padding: 0 24px;
  font-weight: var(--font_display_2_font-weight);
  font-size: 16px;
  transition: transform 0.3s;
}

.block_link a:nth-child(1) {
  background-color: var(--btn_noir1);
  color: white;
}

.block_link a:nth-child(1):hover {
  background-color: var(--btn_noir2);
}

.block_link a:nth-child(2) {
  background-color: transparent;
  color: var(--noir);
}

.block_link a:nth-child(2):hover {
  background-color: var(--gray);
}

.block_link a:active {
  transform: scale(0.9);
}

.droit {
  background-image: linear-gradient(to right, #644c4c88, transparent),
    url(../../assets/profile/banner-2.png);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 350px;
  border-radius: 15px;
}

@media screen and (max-width: 1200px) {
  .container {
    padding: 100px 50px;
  }
}

@media screen and (max-width: 964px) {
  .container {
    padding: 50px 100px;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}

@media screen and (max-width: 743px) {
  .container {
    padding: 50px 24px;
  }

  .left h3 {
    font-size: 25px;
  }

  .left p {
    font-size: 16px;
  }

  .droit {
    height: 300px;
  }
}

@media screen and (max-width: 540px) {
  .block_link a {
    width: 100%;
  }
}

@media screen and (max-width: 414px) {
  .droit {
    height: 250px;
  }
}
