/* container_load */
.container_load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.voyage_container {
  display: flex;
  flex-direction: column;
  padding: var(--main-top) 24px 100px;
}

.voyage_container h1 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 25px;
  color: var(--noir);
  padding-bottom: 30px;
}
@media screen and (min-width: 1440px) {
  .voyage_container {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

.voyage_container .no_voyage h2 {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.voyage_container .no_voyage p {
  margin: 10px 0 15px;
}

.voyage_container .no_voyage a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: var(--tertiary-background);
  width: 270px;
  border: none;
  outline: none;
  padding: 0 20px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  color: var(--noir);
  transition: 0.3s;
}

.voyage_container .no_voyage a:hover {
  background: var(--tertiary-background-hover);
}

.voyage_container .no_voyage a:active {
  transform: scale(0.9);
}

.voyage_container .centre_aide {
  margin-top: 50px;
  padding-top: 30px;
  border-top: 1px solid #ccc;
}

.voyage_container .centre_aide p {
  font-size: 15px;
}

.voyage_container .centre_aide p a {
  color: var(--noir);
}

.no_connecte {
  padding: 40px 115px 100px;
  min-height: 100vh;
}

.no_connecte h1 {
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
}

.no_connecte .connexion {
  padding: 50px 0;
}

.no_connecte .connexion h2 {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.no_connecte .connexion p {
  padding: 18px 0;
}

.no_connecte .connexion button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 25px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  border: 1px solid var(--btn_noir1);
  outline: none;
  color: var(--noir);
  background: transparent;
  transition: 0.3s;
}

.no_connecte .connexion button:hover {
  background: #f1f1f1;
}

.no_connecte .connexion button:active {
  transform: scale(0.9);
}

@media screen and (max-width: 1200px) {
  .no_connecte {
    padding: 40px 115px 100px;
  }
}

@media screen and (max-width: 743px) {
  .no_connecte {
    padding: 30px 24px 100px;
  }
}

@media screen and (max-width: 743px) {
  .voyage_container {
    padding-top: 30px;
  }
}
