.celebrate {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 24px;
  background-color: #000;
}

.block {
  width: 550px;
  max-width: 100%;
  text-align: center;
  animation-duration: 1s;
  animation-name: slidein;
  overflow: hidden;
}

.block h1 {
  color: #fff;
  font-size: 50px;
  font-weight: var(--font_display_3_font-weight);
  z-index: 10;
}

.block p {
  color: #f1f1f1;
  margin: 20px 0;
}

/*--buttons publish--*/
.block button.publish {
  position: relative;
  border: none;
  outline: none;
  color: #fff;
  background: var(--button1);
  z-index: 2;
  overflow: hidden;
  width: 110px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.block button.publish::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--button2);
  z-index: -10;
  opacity: 0;
  transition: opacity 0.4s linear;
}

.block button.publish:hover::before {
  opacity: 1;
}

.block button.publish:active {
  transform: scale(0.9);
}

@keyframes slidein {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@media screen and (max-width: 743px) {
  .celebrate {
    height: 100dvh;
  }

  .block h1 {
    font-size: 30px;
  }
}
