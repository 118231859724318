.seting_container {
  padding: 40px 160px 50px;
  min-height: 100vh;
}

.seting_container h1 {
  font-size: 20px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
  padding: 10px 0 30px;
}

/*----seting_box------*/
.seting_container .seting_box {
  display: grid;
  grid-template-columns: 1fr 5px 1fr;
  grid-gap: 50px;
}

.separate {
  height: 100%;
  background-color: #ececec;
}

/*----- .securite_1-----*/
.seting_container .securite_1 p:nth-child(1) {
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  padding-bottom: 20px;
}

.seting_container .securite_1 p:nth-child(2) {
  font-size: 16px;
  color: var(--noir4);
  padding-bottom: 30px;
}

/*----- connexion_echec-----*/
.seting_container .securite_1 .connexion_echec {
  display: flex;
  gap: 15px;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  background: #fff5f5;
}

.seting_container .securite_1 .connexion_echec .notif h1 {
  font-weight: var(--font_display_2_font-weight);
  padding: 0;
  font-size: 15px;
  color: var(--noir4);
}

.seting_container .securite_1 .connexion_echec .notif p {
  font-size: 15px;
  padding: 0;
  color: var(--noir4);
  text-align: left;
}

.seting_container .securite_1 .connexion_echec .notif p a {
  text-decoration: underline;
}

/*----- .reussit_conexion-----*/
.seting_container .securite_1 .reussit_conexion {
  border-radius: 5px;
  margin-bottom: 20px;
}

.seting_container .securite_1 .reussit_conexion .notif {
  padding: 20px;
  width: 100%;
  background: rgb(229, 246, 253);
}

.seting_container .securite_1 .reussit_conexion .notif h1 {
  font-weight: var(--font_display_2_font-weight);
  padding: 0;
  font-size: 15px;
  color: var(--noir4);
}

.seting_container .securite_1 .reussit_conexion .notif p {
  font-size: 15px;
  padding: 0;
  color: var(--noir4);
  text-align: left;
}

.reussit_conexion button.connexion {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: var(--noir);
}

.seting_container .securite_1 .block_input {
  position: relative;
  margin: 5px 0 15px;
}

.seting_container .securite_1 input {
  box-sizing: border-box;
  padding: 0 15px;
  margin-top: 5px;
  width: 100%;
  height: 50px;
  font-size: 15px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.seting_container .securite_1 input:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.seting_container .securite_1 .erreur input {
  box-shadow: rgb(193, 53, 21) 0px 0px 0px 1px inset;
  font-size: 14px;
}

.seting_container .securite_1 .erreur input:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.seting_container .securite_1 span.erreur {
  color: rgb(193, 53, 21);
  font-size: 14px;
}

.link_new a {
  display: inline-flex;
  color: var(--buttonblue1);
  font-size: 15px;
  margin-top: 5px;
}

.seting_container .securite_1 .block_input ion-icon {
  position: absolute;
  right: 10px;
  top: 42px;
  font-size: 20px;
  cursor: pointer;
}

.seting_container .buttoms button,
.seting_container .buttoms button.loading {
  padding: 8px 10px;
  margin-top: 10px;
  height: 48px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border: none;
  outline: none;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.seting_container .buttoms button {
  background: var(--btn_noir1);
}

.seting_container .buttoms button:hover {
  background: var(--btn_noir2);
}

.seting_container .buttoms button:active {
  transform: scale(0.9);
}

.seting_container .buttoms button.loading {
  background: rgb(226, 226, 226);
  cursor: no-drop;
}

.seting_container .buttoms button.loading:hover {
  background: rgb(226, 226, 226);
}

.seting_content {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.link_versement p {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.link_versement a {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 24px;
  width: fit-content;
  background: var(--btn_noir1);
  color: #fff;
  text-decoration: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.link_versement a p {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #fff !important;
  padding: 0 !important;
}

.link_versement a:hover {
  background: var(--btn_noir2);
}

.link_versement a:active {
  transform: scale(0.9);
}

/*----------seting_box_2------*/
.seting_container .securite_2,
.seting_container .seting_box_2 {
  margin-top: 100px;
  width: 600px;
  max-width: 100%;
}

.seting_container .securite_2 h2,
.seting_container .seting_box_2 h2 {
  color: var(--noir3);
  font-weight: var(--font_display_3_font-weight);
  font-size: 19px;
  padding: 15px 0;
}

.seting_container .securite_2 p,
.seting_container .seting_box_2 p {
  font-size: 15px;
  color: var(--noir6);
}

.seting_container .securite_2 a,
.seting_container .seting_box_2 a {
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
  color: var(--noir);
}

/*----------seting_box_2------*/

.seting_container .seting_box_2 p.p_1 {
  margin-bottom: 40px;
}

@media screen and (min-width: 1440px) {
  .seting_container {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

@media screen and (max-width: 1271px) {
  .seting_container {
    padding: 40px 50px 50px;
  }
}

@media screen and (max-width: 950px) {
  .seting_container {
    padding: 20px 24px 50px;
  }
}

@media screen and (max-width: 800px) {
  .seting_container h1 {
    font-size: 22px;
  }

  .seting_container .seting_box {
    display: block;
  }

  .AllAccount_setings {
    display: none;
  }
}

@media screen and (max-width: 743px) {
  .seting_container .buttoms button,
  .seting_container .buttoms button.loading {
    width: 100%;
  }
}
