.main {
  padding: 30px 50px 50px;
  min-height: 100vh;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 30px;
}

.header_title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.header button,
.header a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: none;
  background: transparent;
  margin-left: -10px;
  color: var(--noir);
  font-size: 19px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: transform 3s;
}

.header a {
  background: var(--gray);
}

.header a:hover {
  background-color: var(--gray-hover);
}

.header button:hover {
  background: var(--gray);
  cursor: pointer;
}

.header button:active,
.header a:active {
  transform: scale(0.9);
}

.header_title h1 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 25px;
  color: var(--noir);
}

@media screen and (min-width: 1440px) {
  .main {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

@media screen and (max-width: 743px) {
  .main {
    padding: 24px 24px 30px;
  }
}

@media screen and (max-width: 540px) {
  .header {
    gap: 15px;
    padding-bottom: 20px;
  }
  .header_title h1 {
    font-size: 20px;
  }
}

.container {
  display: grid;
  grid-template-columns: 220px 1fr;
  gap: 50px;
}

/*----navigations-----*/
.navigations {
  position: sticky;
  top: 40px;
}

.left_block {
  display: flex;
  flex-direction: column;
}

.left_block button {
  padding: 10px;
  margin-bottom: 3px;
  color: var(--noir);
  border: none;
  text-align: left;
  background-color: transparent;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
}

.left_block button:hover {
  background-color: var(--gray);
}

.left_block button.active {
  background-color: var(--noir6);
  color: #fff;
}

.left_block button.active:hover {
  background-color: var(--noir6);
}

.image {
  width: 100%;
  height: 150px;
  background-color: var(--gray);
  border-radius: 15px;
  overflow: hidden;
  margin-top: 24px;
}

.image a {
  text-decoration: none;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*----block_rigth-----*/
.block h2 {
  color: var(--noir);
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
}

.content {
  padding: 24px 0;
  border-bottom: 1px solid var(--border-color);
}

.content h3 {
  color: var(--noir);
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  margin-bottom: 5px;
}

.content .demande {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}

.content .demande svg {
  display: block;
  height: 16px;
  width: 16px;
}

.content .demande svg.no {
  fill: var(--border-color);
}

.content .demande svg.yes {
  fill: #008a05;
}

.content .demande span {
  font-size: 14px;
  color: var(--noir);
}

.statut_modif {
  margin: 10px 0;
}

.statut_modif p {
  display: flex;
  gap: 5px;
  align-items: center;
  width: fit-content;
  padding: 6px 8px;
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 5px;
}

.statut_modif p.attente {
  color: rgb(84 89 105);
  background-color: #ebeef1;
}

.statut_modif p.accepter {
  color: #006908;
  background-color: #d7f7c2;
}

.statut_modif p.echec {
  color: #690000;
  background-color: #ffddd7;
}

.content p strong {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin: 0 5px;
}

.statut_modif_btn button.delete {
  margin-top: 8px;
  color: #d33;
  background-color: transparent;
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
  transition: 0.3s;
  border: none;
  text-decoration: underline;
}

.statut_modif_btn button.delete:hover {
  color: #e01313;
}

.statut_modif_btn button:active {
  transform: scale(0.9);
}

.content p {
  color: #717171;
  font-size: 15px;
  margin-bottom: 5px;
}

.content a {
  color: var(--noiir);
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
}

.modification {
  display: flex;
  justify-content: space-between;
}

.dates_block {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.commentes button.open_commente,
.modification button {
  background-color: transparent;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  margin-left: -15px;
  text-decoration: underline;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
}

.commentes button.open_commente:hover,
.commentes button.icon:hover,
.modification button:hover {
  background-color: var(--gray);
}

.commentes button.open_commente:active,
.commentes button.icon:active,
.modification button:active,
.content .sup a:active,
.content .sup button:active {
  transition: 0.3s;
  transform: scale(0.9);
}

.commentes {
  display: flex;
  align-items: center;
}

.commentes button.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
}

.secure {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 30px;
  padding: 24px 0;
}

.secure p {
  color: #717171;
  font-size: 14px;
}

.content .sup button,
.content .sup a {
  display: inline-block;
  background-color: transparent;
  text-decoration: underline;
  border: none;
  outline: none;
  margin-top: 8px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
}

.content .sup button:hover,
.content .sup a:hover {
  transition: 0.3s;
  color: #717171;
}

@media screen and (max-width: 743px) {
  .container {
    display: block;
  }

  .navigations {
    position: none;
    top: auto;
  }

  .left_block button.active {
    background-color: var(--noir);
  }

  .left_block button.active:hover {
    background-color: var(--noir);
  }

  .image {
    width: 80px;
    height: 60px;
    margin-bottom: 24px;
    border-radius: 8px;
  }

  .left_block {
    flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 10px;
  }

  .left_block button {
    background-color: var(--gray);
    margin-left: 5px;
  }
}
