.container {
  padding: 30px 115px;
  min-height: 100vh;
}

.container .header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.container .header button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  color: var(--noir);
  font-size: 19px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: -15px;
}

.container .header button:hover {
  background: var(--gray);
  cursor: pointer;
}

.container .header h2 {
  font-size: 30px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

/*------main----*/
.container .recap_block {
  display: grid;
  grid-template-columns: 1fr 450px;
  padding: 40px 0;
  gap: 40px;
}

.left {
  padding-right: 50px;
}

@media screen and (min-width: 1440px) {
  .container {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

@media screen and (max-width: 1100px) {
  .container {
    padding: 30px 50px;
  }

  .left {
    padding-right: 0;
  }
}

@media screen and (max-width: 964px) {
  .container {
    padding: 30px 24px;
  }

  .container .recap_block {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 790px) {
  .container .recap_block {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
  }
}

@media screen and (max-width: 743px) {
  .container {
    padding-top: 0;
  }

  .container .header {
    display: none;
  }
}
