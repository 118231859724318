.navbar {
  display: none;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  background: #fff;
  box-shadow: rgb(235 235 235) 0px 1px 0px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  height: 64px;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 24px;
  z-index: 10;
}

.navbar button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  color: var(--noir);
  font-size: 19px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: -15px;
}

.navbar button:hover {
  background: var(--gray);
  cursor: pointer;
}

.navbar h1 {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

@media screen and (max-width: 743px) {
  .navbar {
    display: flex;
  }
}
