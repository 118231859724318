/*----updated legende-modal-----*/

.modal_content {
  display: flex;
  flex-direction: column;
  width: 1200px;
  max-width: 100%;
  max-height: 90vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid var(--border-color);
}

.m_head h3 {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.modal_content .m_body {
  display: flex;
  gap: 30px;
  overflow-y: auto;
  padding: 30px 24px;
}

.m_body .img_modal {
  flex: 2;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 400px;
  background-color: var(--gray);
  border-radius: 5px;
}

.m_body .img_modal img {
  display: inline-block;
  vertical-align: bottom;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.m_body .placeHolder {
  flex: 1;
}

.m_body .placeHolder label h2 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.m_body .placeHolder label p {
  font-size: 14px;
  color: var(--noir9);
}

.m_body .placeHolder textarea {
  width: 100%;
  height: 200px;
  resize: vertical;
  border-radius: 8px;
  margin-top: 8px;
  box-sizing: border-box;
  appearance: none;
  padding: 8px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.m_body .placeHolder textarea:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.m_body .placeHolder span {
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  color: #555;
}

/*-----footer-modale---*/
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  padding-top: 16px;
  background: #fff;
  border-top: 1px solid rgb(221, 221, 221);
}

.footer button.retour {
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  padding: 10px;
  color: var(--noir);
  text-decoration: underline;
  cursor: pointer;
}

.footer button.retour:hover {
  background: #f1f1ff;
}

.footer button.next {
  position: relative;
  border: none;
  outline: none;
  color: #fff;
  background: var(--btn_noir1);
  height: 50px;
  width: 150px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.footer button.next:hover {
  background: var(--btn_noir2);
}

.footer button.next:active,
.footer button.loading_modif:active {
  transform: scale(0.9);
}

.footer button.loading_modif {
  border: none;
  outline: none;
  background: rgb(226, 226, 226);
  width: 150px;
  height: 50px;
  border-radius: 8px;
  cursor: no-drop;
  transition: 0.3s;
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }

  .modal_content .m_body {
    flex-direction: column;
    gap: 24px;
  }

  .m_body .img_modal {
    flex: none;
  }

  .modal_content .m_body::-webkit-scrollbar {
    display: none;
  }
}
