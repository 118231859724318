.section_2 h3 {
  font-size: 35px;
  font-weight: var(--font_heading_1_font-weight);
  font-family: var(--font-family_heading);
  color: var(--noir);
  padding-bottom: 40px;
}

.action_block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 16%);
  overflow: hidden;
}

.action_block .img_content {
  background-color: var(--gray);
}

.action_block .text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 40px;
  background: #000;
}

.action_block .text h4 {
  font-size: 40px;
  font-family: var(--font-family_heading);
  color: #fff;
  margin-bottom: 10px;
}

.action_block .text p {
  font-size: 18px;
  color: #f3f3f3;
}

.action_block .text a,
.action_block .text button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  text-decoration: none;
  margin-top: 20px;
  border: none;
  outline: none;
  color: #fff;
  background: var(--button1);
  overflow: hidden;
  z-index: 2;
  padding: 0 20px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.action_block .text a::before,
.action_block .text button::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--button2);
  z-index: -10;
  opacity: 0;
  transition: opacity 0.4s linear;
}

.action_block .text a:hover::before,
.action_block .text button:hover::before {
  opacity: 1;
}

.action_block .text a:active,
.action_block .text button:active {
  transform: scale(0.9);
}

.action_block img {
  width: 100%;
  height: 600px;
  vertical-align: middle;
  object-fit: cover;
}

@media screen and (max-width: 766px) {
  .action_block {
    grid-template-columns: 1fr;
  }

  .action_block img {
    height: 500px;
  }

  .action_block .text h4 {
    font-size: 23px;
  }

  .action_block .text p {
    font-size: 16px;
  }
}

@media screen and (max-width: 540px) {
  .section_2 h3 {
    font-size: 22px;
    line-height: 28px;
    padding-bottom: 30px;
  }

  .action_block .text {
    padding: 40px 20px;
  }
}

@media screen and (max-width: 414px) {
  .action_block img {
    height: 400px;
  }
}

@media screen and (max-width: 393px) {
  .action_block .text h4 {
    font-size: 25px;
  }
}
