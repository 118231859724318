.container {
  margin: 30px 0;
}

.container h3 {
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  font-size: 17px;
}

.container p {
  font-size: 15px;
}

.block_link {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0;
}

.block_link a {
  text-decoration: none;
  border: 1px solid var(--noir);
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
}

.block_link a:nth-child(1) {
  background-color: var(--btn_noir1);
  padding: 10px 20px;
  color: white;
}

.block_link a:nth-child(1):hover {
  background-color: var(--btn_noir2);
}

.block_link a:nth-child(2) {
  background-color: transparent;
  color: var(--noir);
}

.block_link a:nth-child(2):hover {
  background-color: var(--gray);
}

.block_link a:active {
  transition: transform 0.3s;
  transform: scale(0.9);
}

@media screen and (max-width: 540px) {
  .container {
    margin: 24px 0;
  }
}
