.block_center {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  padding: 30px 50px 50px;
  overflow-y: auto;
}

.block_center::-webkit-scrollbar {
  display: none;
}

.block_center .block_form {
  width: 100%;
  max-width: 564px;
  margin-left: auto;
  margin-right: auto;
}

.block_form h1,
.create h2 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  padding-bottom: 16px;
}

.block_form .annonce,
.create a {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  background: #fff;
  padding: 20px 16px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: 0.3s transform;
}

.block_form .annonce:hover,
.create a:hover {
  border: 1px solid #222222;
}

.block_form .annonce:active,
.create a:active {
  transform: scale(0.9);
}

.block_form .annonce .card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: #ddd;
  overflow: hidden;
}

.annonce .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.annonce .card svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: #fff;
}

.annonce p,
.create p {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-y: hidden;
}

/*-----create-------*/
.create {
  margin-top: 50px;
}

.create svg {
  display: block;
  fill: none;
  height: 32px;
  width: 32px;
  stroke: var(--noir);
  stroke-width: 2;
  overflow: visible;
}

@media screen and (max-width: 900px) {
  .block_center {
    padding: 10px 0 50px;
  }

  .block_center .block_form {
    padding: 0 24px 24px;
  }
}

@media screen and (max-width: 743px) {
  .block_form .annonce:hover,
  .create a:hover {
    border: 1px solid var(--border-color);
    background: var(--white);
  }
  .annonce p {
    font-size: 15px;
  }
}

@media screen and (max-width: 540px) {
  .block_form h1,
  .create h2 {
    font-size: 18px;
    padding-bottom: 14px;
  }
}
