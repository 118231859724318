.container {
  display: none;
}

@media screen and (max-width: 743px) {
  .container {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 64px;
    background-color: rgba(255, 255, 255);
    z-index: 500;
    border-top: 1px solid rgb(235, 235, 235);
    contain: paint !important;
    transition: -ms-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s,
      -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s,
      transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, visibility 0.2s ease 0s !important;
    transform: none;
  }

  .container.disable {
    transform: translateY(100%);
  }

  .block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    max-width: 560px;
    height: 100%;
  }

  .navigation {
    position: relative;
    display: flex;
    flex: 1 1 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #b0b0b0;
    gap: 4px 0;
    text-align: center;
    text-decoration: none;
    max-width: 20%;
    min-width: 0px;
    height: 100%;
    overflow-wrap: break-word;
    padding: 0px 2px;
  }

  /* .navigation.active::after {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    width: 100%;
    background: var(--color-bottom-bar);
    border-radius: 5px;
  } */

  .navigation svg {
    display: block;
    height: 24px;
    width: 24px;
    stroke: #b0b0b0;
    fill: #b0b0b0;
  }

  .navigation svg.search {
    fill: none;
    stroke-width: 4;
    overflow: visible;
  }

  .navigation svg.favori {
    fill: none;
    stroke-width: 2.66667;
  }

  .navigation svg.add_home {
    stroke-width: 2;
  }

  .navigation:active {
    color: var(--color-bottom-bar);
  }

  .navigation:active svg {
    stroke: var(--color-bottom-bar);
  }

  .navigation:active svg.voyage,
  .navigation:active svg.message,
  .navigation:active svg.profil {
    fill: var(--color-bottom-bar);
  }

  .navigation.active svg {
    stroke: var(--color-bottom-bar);
  }

  .navigation.active svg.voyage,
  .navigation.active svg.message,
  .navigation.active svg.profil {
    fill: var(--color-bottom-bar);
  }

  .navigation p {
    font-size: 10px;
    line-height: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: var(--font_display_2_font-weight);
    overflow-wrap: break-word;
    color: #717171;
  }

  .navigation.active p {
    color: var(--color-bottom-bar);
  }

  button.navigation {
    border: none;
    outline: none;
    background: transparent;
  }

  .image {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    overflow: hidden;
    background: #ccc;
    border: 2px solid #b0b0b0;
  }

  .navigation:active .image {
    border: 2px solid var(--color-bottom-bar);
  }

  .navigation.active .image {
    border: 2px solid var(--color-bottom-bar);
  }

  .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    position: relative;
  }

  .navigation span {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--color-notif);
    top: -2px;
    right: -7px;
    animation-duration: 0.3s;
    animation-name: notification;
  }

  @keyframes notification {
    from {
      transform: scale(0);
      opacity: 0;
    }

    to {
      transform: scale(1);
      opacity: 1;
    }
  }
}
