/*------liste message-----*/
.liste_container {
  padding-top: 80px;
  height: 100vh;
}

.block_grid {
  display: grid;
  grid-template-columns: 260px 1fr;
  height: 100%;
}

/*-------block 2-detail----*/
.block_grid_2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.block_grid_2 .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  border-bottom: 1px solid var(--border-color);
  padding: 20px 24px;
}

.block_grid_2 .head .left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.block_grid_2 .head .left button {
  display: none;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  color: var(--noir);
  cursor: pointer;
}

.block_grid_2 .head .left button:hover {
  background: var(--gray-hover);
}

.block_grid_2 .head h2 {
  font-size: 18px;
  color: var(--noir);
  font-weight: var(--font_display_3_font-weight);
}

.block_grid_2 .buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.block_grid_2 .head button.delete {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  width: 35px;
  height: 35px;
  color: var(--noir);
  border-radius: 50%;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.3s;
}

.block_grid_2 .head button.delete:hover {
  background: var(--gray-hover);
}

.block_grid_2 .head button.delete:active {
  transform: scale(0.9);
}

/*-------no result-----*/
.no_result {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  text-align: center;
}

.no_result p {
  color: var(--noir);
  font-size: 15px;
}

/*-------block_liste_and_detail-----*/
.block_grid_2 .block_liste_and_detail {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  overflow: hidden;
}

/*-------liste all message-----*/
.block_grid_2 .loading_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 24px;
  border-right: 1px solid var(--border-color);
}

.block_grid_2 .liste_block {
  overflow: hidden;
}

.block_grid_2 .liste {
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  height: 100%;
  width: 100%;
  padding-bottom: 50px;
  border-right: 1px solid var(--border-color);
  overflow: auto;
}

.block_grid_2 .liste .liste_content {
  display: flex;
  gap: 15px;
  text-decoration: none;
  width: 100%;
  height: 150px;
  padding: 20px 15px;
  margin-bottom: 5px;
  border-bottom: 1px solid var(--border-color);
}

.block_grid_2 .liste .liste_content:last-child {
  border-bottom: none;
}

.liste .liste_content.active {
  background: var(--gray);
  border-radius: 15px;
  border: none;
}

.liste .liste_content .img_block {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gray);
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
  flex: 1;
}

.liste .liste_content .img_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.liste .liste_content .img_block h2 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir3);
}

.liste .profil_text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 2;
}

.liste .profil_text .non_lue h5,
.liste .profil_text .non_lue p {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.liste .profil_text h5 {
  font-size: 16px;
  color: var(--noir6);
  font-weight: 400;
  line-height: 24px;
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.liste .profil_text h5 span {
  font-size: 14px;
  color: var(--noir8);
}

.profil_text .last_message {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.profil_text .last_message span {
  font-size: 14px;
  color: var(--noir8);
}

.liste .profil_text p {
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-y: hidden;
}

.liste .profil_text span.statut {
  margin-right: 5px;
  font-size: 12px;
  color: var(--noir6);
}

.liste .profil_text h6 {
  text-align: right;
  font-size: 12px;
  color: var(--noir8);
}

/*-------boite de conversation------*/
.conversation_block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.detail_block {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  padding-bottom: 50px;
  overflow: auto;
}

.detail {
  display: flex;
  flex-direction: column;
}

.detail .rappel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding: 24px 0 15px;
}

.detail .rappel h4 {
  color: var(--noir6);
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
}

.detail .rappel p {
  color: var(--noir6);
  font-size: 12px;
  font-weight: var(--font_display_2_font-weight);
  padding: 13px 24px;
  border-radius: 50px;
  background: var(--gray);
}

.detail .rappel p a {
  color: var(--noir6);
  font-size: 12px;
  font-weight: var(--font_display_2_font-weight);
  margin-left: 5px;
}

.detail .rappel p a:hover {
  color: var(--noir);
}

/*----message-block----*/
.detail .message {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.message_block {
  display: flex;
  gap: 12px;
  margin-bottom: 15px;
}

.message_block .card {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: var(--gray);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.message_block .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.message_block .card h2 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir3);
}

.message_text {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.message_text h4 {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
  padding-bottom: 5px;
}

.message_text h4 span {
  font-size: 13px;
  color: var(--noir8);
}

.message_text p {
  line-height: 22px;
  font-size: 15px;
}

.block_grid_2 .send {
  display: flex;
  justify-content: center;
  padding: 16px 24px;
}

.block_grid_2 .input_block {
  position: relative;
  width: 100%;
}

.block_grid_2 .send textarea {
  width: 100%;
  height: 42px;
  min-height: 42px;
  max-height: 280px;
  padding: 9px 15px;
  font-size: 16px;
  color: var(--noir3);
  border-radius: 20px;
  resize: none;
  -webkit-appearance: none;
  -moz-apperarance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  overflow: auto;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.block_grid_2 .send textarea:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.block_grid_2 .send textarea::placeholder {
  font-size: 14px;
  color: var(--noir8);
  font-weight: var(--font_display_2_font-weight);
}

.block_grid_2 .send textarea::-webkit-scrollbar {
  display: none;
}

.block_grid_2 .input_block button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 12px;
  right: 8px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: var(--noir);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}

.block_grid_2 .input_block button:hover {
  background: var(--noir);
}

/*--------modal-Snackbar--pc-----*/
.modal_Snackbar {
  position: fixed;
  display: flex;
  align-items: center;
  gap: 5px;
  bottom: 30px;
  left: 60px;
  padding: 13px 24px;
  color: var(--noir);
  box-shadow: rgb(0 0 0 / 20%) 0px 6px 20px;
  border-radius: 12px;
  border: 1px solid rgb(221, 221, 221);
  background-color: rgb(255, 255, 255);
  z-index: 2000;
  transform: translateY(250%);
  opacity: 0;
  transition: 0.5s all ease-in-out;
}

.modal_Snackbar.active {
  transform: none;
  opacity: 1;
}

.modal_Snackbar p {
  font-size: 15px;
}

@media screen and (max-width: 1200px) {
  .block_grid_2 .liste {
    padding: 10px 24px;
  }

  .block_grid_2 .liste::-webkit-scrollbar {
    display: none;
  }

  .detail_block {
    padding: 0 24px;
  }
}

@media screen and (max-width: 800px) {
  .block_grid {
    grid-template-columns: 1fr;
  }

  .block_grid_2 .head {
    padding-left: 15px;
  }

  .block_grid_2 .head .left button {
    display: flex;
  }

  .block_grid_2 .liste .liste_content {
    padding: 20px 0;
  }

  /*-------block_liste_and_detail-----*/
  .block_grid_2 .block_liste_and_detail {
    grid-template-columns: 1fr;
  }

  .block_grid_2 .loading_container {
    border: none;
  }

  .block_grid_2 .liste_block {
    display: none;
  }

  .detail_block::-webkit-scrollbar {
    display: none;
  }

  .modal_Snackbar {
    left: 45px;
    right: 45px;
    bottom: 100px;
  }
}

@media screen and (max-width: 743px) {
  .liste_container {
    height: 100dvh;
  }
}
