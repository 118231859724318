.container {
  padding: 30px 50px 15px;
}

@media screen and (min-width: 1440px) {
  .container {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

.button_searchResult {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 7px 7px 25px;
  width: fit-content;
  cursor: pointer;
  background-color: var(--white);
  border: 1px solid rgb(221, 221, 221);
  border-radius: 40px;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 4px 12px;
  color: rgb(34, 34, 34);
  transition: box-shadow 0.2s ease-in-out;
}

.button_searchResult:hover {
  box-shadow: none;
}

.button_searchResult .icon_search {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--noir);
  width: 32px;
  height: 32px;
  border-radius: 50px;
  background-color: #f0f0f0;
}

.block_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.block_1 .buttonms {
  display: flex;
  align-items: center;
}

.block_1 .buttonms button {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  padding: 6px 10px;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 5px;
  background: transparent;
  text-decoration: underline;
}

.block_1 .buttonms button:nth-child(2) {
  margin-right: -10px;
}

.block_1 .buttonms button span {
  font-weight: var(--font_display_2_font-weight);
  font-size: 14px;
  color: var(--noir);
}

.block_1 .buttonms button:hover {
  background: var(--gray);
}

.block_1 .buttonms button:active {
  transition: 0.2s;
  transform: scale(0.9);
}

.buttonms_mobile {
  display: none;
}

.block_1 .buttonms svg.partage {
  cursor: pointer;
  display: block;
  height: 16px;
  width: 16px;
  stroke-width: 2;
  stroke: currentColor;
  overflow: visible;
}

.detail_block_1 h2 {
  padding-top: 20px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

/*-------titre et block favoris et partage----*/
.block_3 {
  padding-top: 2px;
}

.block_3 .avis {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 4px;
}

.block_3 .avis p {
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
}

.block_3 .avis button {
  text-decoration: underline;
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir4);
}

.block_3 p button {
  text-decoration: underline;
  background-color: transparent;
  border: none;
  font-size: 15px;
  color: var(--noir);
}

.block_3 p button:hover {
  text-decoration: none;
}

@media screen and (max-width: 900px) {
  .container {
    padding: 30px 24px 15px;
  }
}

@media screen and (max-width: 700px) {
  .container {
    display: none;
  }

  .block_1 .buttonms button span {
    display: none;
  }
}
