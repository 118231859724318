.block_checkbox {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 2px;
}

.block_checkbox label {
  cursor: pointer;
}

.block_checkbox label input[type="radio"] {
  display: none;
}

.block_checkbox label .block {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-radius: 10px;
  padding: 25px 20px;
  box-shadow: 0 0 0 1px var(--border-color);
  color: var(--noir);
  background: transparent;
  user-select: none;
  overflow: hidden;
}

.block_checkbox .block:hover {
  box-shadow: 0 0 0 1px var(--noir);
}

.block_checkbox .block:active {
  transition: 0.3s transform;
  transform: scale(0.9);
}

.block_checkbox input[type="radio"]:checked ~ .block {
  background: var(--gray);
  box-shadow: var(--box-shadow-border);
}

.block_content {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
}

.block .block_content h2 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 18px;
  color: var(--noir);
}

.block .block_content p {
  font-size: 13px;
  color: var(--noir8);
}

@media screen and (max-width: 743px) {
  .block_checkbox {
    gap: 15px;
  }

  .block_checkbox .block:hover {
    box-shadow: 0 0 0 1px var(--border-color);
  }
}
