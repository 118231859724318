.statut h2 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 25px;
  color: var(--noir);
}

.statut h2 strong {
  font-weight: var(--font_display_3_font-weight);
  font-size: 28px;
  color: var(--noir);
}

.statut p {
  font-size: 15px;
  color: var(--noir);
  margin-top: 5px;
}

.statut a {
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
}

.succeeded,
.processing,
.echec {
  display: flex;
  gap: 15px;
  padding: 20px;
  border-radius: 10px;
}

.succeeded {
  align-items: center;
  gap: 20px;
  background: rgb(229, 246, 253);
}

.succeeded i {
  color: #3bb900;
  font-size: 30px;
}

.processing {
  background: var(--gray);
}

.echec {
  background: #fff5f5;
}

@media screen and (max-width: 743px) {
  .statut h2 {
    font-size: 15px;
  }

  .statut h2 strong {
    font-size: 17px;
  }

  .statut p {
    font-size: 14px;
  }
}
