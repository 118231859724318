/*---modal-message-----*/
.modal_content {
  display: flex;
  flex-direction: column;
  width: 600px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.modal_content .m_body {
  overflow: auto;
  padding: 10px 30px 50px;
}

.modal_content .m_body h3 {
  color: var(--noir);
  font-weight: var(--font_display_3_font-weight);
  font-size: 22px;
  margin-bottom: 5px;
}

.modal_content .m_body p {
  color: var(--noir6);
}

.notif {
  display: flex;
  gap: 20px;
  margin-top: 24px;
}

.grid_system {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.radio_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 24px 0;
  cursor: pointer;
}

.radio_container:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.radio_container input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(176, 176, 176);
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 50%;
  vertical-align: top;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  height: 22px;
  width: 22px;
  margin: 0px;
}

.radio_container input[type="radio"]:hover {
  border-color: rgb(34, 34, 34);
}

.radio_container input[type="radio"]:checked {
  border-color: rgb(34, 34, 34);
  border-width: 7px;
  background-color: white;
}

.button_action {
  padding: 16px 30px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--border-color);
}

.button_action button {
  border: none;
  color: #fff;
  padding: 0 20px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

button.retour {
  margin-left: -20px;
  background-color: transparent;
  color: var(--noir);
  text-decoration: underline;
}

button.retour:hover {
  background-color: var(--gray-hover);
}

button.next {
  background-color: var(--btn_noir1);
  color: #fff;
}

button.next:hover {
  background-color: var(--btn_noir2);
}

button.next:active,
button.retour:active {
  transform: scale(0.9);
}

button.next.disabled {
  cursor: no-drop;
  background-color: rgb(226, 226, 226);
}

/*-------messages------*/
.message_content {
  margin: 24px 0;
}

.message_content label {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 16px;
  color: var(--noir);
}

.message_content textarea {
  box-sizing: border-box;
  width: 100%;
  height: 120px;
  padding: 10px;
  border-radius: 8px;
  resize: vertical;
  font-size: 16px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.message_content textarea:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }
}
