.container {
  margin: 90px 0;
}

.container h3 {
  font-size: 35px;
  font-weight: var(--font_heading_1_font-weight);
  font-family: var(--font-family_heading);
  margin-bottom: 30px;
  color: var(--noir);
}

.flex_layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 30px;
}

.img_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_block {
  width: 400px;
  height: 400px;
  background-color: var(--gray);
  border-radius: 50%;
  overflow: hidden;
}

.img_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.acceuil_voyage {
  display: flex;
  gap: 20px;
  padding-top: 30px;
  color: var(--noir3);
}

.acceuil_voyage p a {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

@media screen and (max-width: 950px) {
  .flex_layout {
    grid-template-columns: 1fr;
  }

  .img_container {
    justify-content: center;
  }
}

@media screen and (max-width: 743px) {
  .img_block {
    width: 200px;
    height: 200px;
  }

  .acceuil_voyage p {
    font-size: 15px;
  }
}

@media screen and (max-width: 540px) {
  .container h3 {
    font-size: 25px;
  }
}
