.annonce_list {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
}

.annonce_list .head {
  padding: 30px;
}

.head .filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head .filter .loading {
  width: 50%;
  height: 15px;
  background-color: hsl(200, 20%, 90%);
}

.head .close_modal button,
.head .filter button {
  position: relative;
  background: transparent;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;
}

.head .close_modal button:hover,
.head .filter button:hover {
  background-color: var(--gray);
}

.head .close_modal button:active,
.head .filter button:active {
  transform: scale(0.9);
}

.head .close_modal button {
  display: none;
  margin-top: -13px;
  margin-left: -13px;
}

.head .close_modal button svg {
  display: block;
  fill: none;
  height: 15px;
  width: 15px;
  stroke: var(--noir);
  stroke-width: 5.33333;
  overflow: visible;
}

.head .filter h2 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
}

.head .filter button {
  display: flex;
  margin-right: -13px;
}

.head .filter svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: var(--noir);
}

.head .filter button.active::after {
  position: absolute;
  content: "";
  display: block;
  inset: 0;
  border: 2px solid #000;
  background: transparent;
  border-radius: 50px;
}

.head .filter button span.length {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: -2px;
  right: -2px;
  width: 18px;
  height: 18px;
  font-size: 10px;
  font-weight: 700;
  border-radius: 50%;
  background: #000;
  color: #fff;
  z-index: 10;
}

.head .filter button.active span.length {
  display: flex;
}

.head .search_block {
  padding-top: 24px;
}

/*-------content_list-------*/
.content_list {
  height: 100%;
  overflow: auto;
  padding-bottom: 24px;
}

.content_list_block {
  padding-bottom: 24px;
}

.content_list button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-bottom: 2px;
  background: transparent;
  border: none;
  background-clip: padding-box;
}

.content_list button:hover {
  background-color: var(--gray);
}

.content_list button.active {
  background-color: var(--gray);
}

.content_list .card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 40px;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.content_list .card svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: #fff;
}

.content_list .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content_list h3 {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.content_list p {
  font-size: 12px;
  color: #444;
}

@media screen and (max-width: 912px) {
  .annonce_list {
    position: fixed;
    inset: 0;
    background-color: #fff;
    z-index: 100;
    display: none;
  }

  .annonce_list.active {
    display: block;
  }

  .annonce_list .head {
    padding: 30px 24px;
  }

  .head .close_modal button {
    display: flex;
  }

  .content_list button {
    padding: 20px 24px;
  }
}
