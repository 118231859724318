.checkbox_container {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding: 20px 0;
}

.checkbox_container label {
  cursor: pointer;
}

.checkbox_container p {
  width: 100%;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  font-size: 16px;
}

.checkbox_container span {
  display: inline-block;
  color: var(--noir6);
  font-size: 13px;
  margin-top: 8px;
}
