.hotel_confirmation {
  padding: 50px 115px;
  min-height: 100vh;
}

.hotel_confirmation .recap_block {
  display: grid;
  grid-template-columns: 1fr 450px;
  padding: 40px 0;
  gap: 100px;
}

@media screen and (min-width: 1440px) {
  .hotel_confirmation {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

@media screen and (max-width: 1100px) {
  .hotel_confirmation {
    padding: 30px 50px;
  }

}

@media screen and (max-width: 964px) {
  .hotel_confirmation {
    padding: 30px 24px 50px;
  }

  .hotel_confirmation .recap_block {
    grid-template-columns: 1fr 1fr;

  }
}

@media screen and (max-width: 790px) {
  .hotel_confirmation .recap_block {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
    padding: 24px 0;
  }
}