.hotel_bloc {
  display: grid;
  grid-template-columns: 58vw 1fr;
  min-height: 100vh;
}

.hotel {
  padding: 0 24px;
  padding-bottom: 50px;
}

/*------liste_hotel--------*/
.liste_hotel {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1440px) {
  .hotel_bloc {
    grid-template-columns: 48vw 1fr;
  }
}

@media screen and (max-width: 1150px) {
  .hotel_bloc {
    display: block;
  }
}