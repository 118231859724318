.container {
  width: 100%;
  height: 100%;
}

.container_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.loading_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 24px;
}

.no_result {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 24px;
}

.no_result p {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir6);
}

.wrapper_profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  padding: 24px;
}

.image_block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-decoration: none;
  background: #ddd;
  overflow: hidden;
}

.image_block svg {
  display: block;
  height: 100%;
  width: 100%;
  fill: #999;
  background: #fff;
  stroke-width: 3;
}

.image_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper_profile h2 {
  font-size: 16px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  margin-top: 10px;
}

.wrapper_profile .guideline {
  text-align: center;
  font-size: 13px;
  color: var(--noir8);
  margin-top: 10px;
}

.send_message {
  display: flex;
  justify-content: center;
  padding: 16px 90px;
}

.send_message .input_block {
  position: relative;
  width: 100%;
}

.send_message textarea {
  width: 100%;
  height: 42px;
  min-height: 42px;
  max-height: 280px;
  padding: 9px 15px;
  font-size: 16px;
  color: var(--noir3);
  border-radius: 20px;
  resize: none;
  -webkit-appearance: none;
  -moz-apperarance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  overflow: auto;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.send_message textarea:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.send_message textarea::placeholder {
  font-size: 14px;
  color: var(--noir8);
  font-weight: var(--font_display_2_font-weight);
}

.send_message textarea::-webkit-scrollbar {
  display: none;
}

.send_message .input_block button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 12px;
  right: 8px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: var(--noir);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}

.send_message .input_block button:hover {
  background: var(--noir);
}

@media screen and (max-width: 1300px) {
  .send_message {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 1263px) {
  .send_message {
    padding-left: 24px;
    padding-right: 24px;
  }
}
