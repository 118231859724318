.container {
  margin-bottom: 30px;
}
.container button {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: var(--noir6);
  display: flex;
  gap: 10px;
  align-items: center;
  transition: 0.3s;
}
.container button:active {
  transform: scale(0.9);
}
.container button span.text {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
}
.container svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: currentcolor;
}
