/*---modal-message-----*/
.modal_content {
  display: flex;
  flex-direction: column;
  width: 700px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.m_body {
  overflow: auto;
  padding: 0 24px 50px;
}

.modal_content p.notice {
  font-size: 22px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.m_body .block_head {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-top: 24px;
}

.m_body .block_img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--gray);
  overflow: hidden;
}

.m_body .block_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.m_body .block_img h2 {
  font-size: 22px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
}

.m_body .block_head h2 {
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.container_avis {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  padding: 30px 0;
}

.block_avis {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.block_avis .star {
  display: flex;
  gap: 15px;
}
.block_avis .star button {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}
.block_avis .star button:hover {
  background-color: var(--gray);
}
.block_avis .star button:active {
  transition: 0.3s;
  transform: scale(0.9);
}
.form_content label {
  display: inline-block;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: #4e4e4e;
  padding-bottom: 10px;
  cursor: pointer;
}

.form_content textarea {
  width: 100%;
  height: 200px;
  padding: 10px 15px;
  border-radius: 8px;
  resize: vertical;
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.form_content textarea:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.form_content p {
  font-size: 14px;
}

.form_content .button_block {
  display: flex;
  justify-content: flex-start;
}

.form_content button,
.form_content button.loading {
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  margin-top: 15px;
  outline: none;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  transition: 0.2s ease-in-out;
}

.form_content button {
  background: rgb(226, 226, 226);
}

.form_content button:hover {
  background: rgb(226, 226, 226);
  cursor: no-drop;
}

.form_content button.active {
  background: var(--btn_noir1);
  cursor: pointer;
}

.form_content button.active:hover {
  background: var(--btn_noir2);
}

.form_content button:active {
  transform: scale(0.9);
}

.form_content button.loading {
  background: rgb(226, 226, 226);
  cursor: no-drop;
}

.form_content button.loading:hover {
  background: rgb(226, 226, 226);
}

.politique {
  overflow: auto;
  padding: 0 30px 50px;
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }

  .container_avis {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding: 30px 0;
  }
}
