.home_nav {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  height: 80px;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(12px);
}

.home_logo a {
  display: none;
  align-items: center;
  vertical-align: middle;
}

.links_block {
  display: flex;
  align-items: center;
}

.links_block a {
  color: var(--noir);
  font-size: 15px;
  font-weight: var(--font_display_1_font-weight);
  padding: 8px 15px;
  border-radius: 30px;
  background: transparent;
}

.links_block a:hover {
  background-color: var(--gray);
}

.links_block a.active {
  background-color: var(--gray);
}

@media screen and (max-width: 912px) {
  .home_nav {
    padding: 0 24px;
  }
}

@media screen and (max-width: 743px) {
  .home_nav {
    height: 70px;
  }

  .home_logo a {
    display: flex;
  }
}
