.container {
  padding: 30px 160px 50px;
  min-height: 100vh;
}

.succes {
  display: flex;
  align-items: center;
  gap: 30px;
  border-radius: 15px;
  padding: 24px;
  background-color: rgb(202, 239, 255);
  color: var(--noir);
}

.succes svg {
  display: block;
  height: 24px;
  width: 24px;
  stroke: var(--noir);
  fill: var(--noir);
}

.succes p,
.error_server p {
  color: var(--noir);
}

.error_server {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.error_server p {
  border-radius: 15px;
  padding: 24px;
  background-color: #fad7d7;
  color: var(--noir);
}

.error_server button {
  width: fit-content;
  padding: 10px 30px;
  border: 1px solid var(--noir);
  background-color: #fff;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  font-size: 16px;
  border-radius: 8px;
  transition: 0.3s;
}

.error_server button:hover {
  background-color: var(--gray);
}

.error_server button:active {
  transform: scale(0.9);
}

.error_server a {
  color: var(--noir);
}

@media screen and (min-width: 1440px) {
  .container {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

@media screen and (max-width: 1280px) {
  .container {
    padding: 30px 100px 50px;
  }
}

@media screen and (max-width: 950px) {
  .container {
    padding: 30px 24px 50px;
  }
}
