.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 30px 0;
  width: 400px;
}

@media screen and (max-width: 743px) {
  .container {
    width: 100%;
  }
}
