.add_number .input_groupe_phone {
  border: 1px solid var(--noir9);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 5px;
}
.add_number .input_groupe .email_de_confirme {
  font-size: 14px;
}
.add_number input {
  box-sizing: border-box;
  padding: 0 15px;
  margin: 5px 0;
  width: 100%;
  height: 50px;
  font-size: 15px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}
.add_number input::placeholder {
  font-size: 15px;
  color: var(--noir9);
}
.add_number input:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}
.erreur_input input {
  box-shadow: rgb(193, 53, 21) 0px 0px 0px 2px inset;
}
.erreur_input input:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}
.add_number .error_message {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #c13515;
}
.add_number .error_message span {
  color: #c13515;
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
}
