.container {
  padding: 26px 0 24px;
}

.container h3 {
  font-size: 22px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  padding: 24px 0;
  line-height: 1.2;
}

.container .block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.container .icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.container svg {
  display: block;
  height: 24px;
  width: 24px;
  fill: currentcolor;
}

.container .icons p {
  color: var(--noir);
  font-weight: var(--font_display_1_font-weight);
}

.container .button {
  margin-top: 30px;
  height: 48px;
}

@media screen and (max-width: 700px) {
  .container {
    padding: 0 0 20px;
  }
  .container .block {
    display: flex;
    flex-direction: column;
  }

  .container .button {
    width: 100%;
    margin-top: 30px;
  }
}

/*---modal_equipement-----*/

.modal_content {
  display: flex;
  flex-direction: column;
  width: 750px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

@media screen and (max-width: 743px) {
  .m_head {
    min-height: 48px;
  }
}

.modal_content .m_body {
  overflow-y: auto;
  padding: 20px 24px 50px;
}

.modal_content .m_body h3 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.container_equip h4 {
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  padding-top: 50px;
}

.container_equip .liste {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 24px 0;
  color: var(--noir);
  border-bottom: 1px solid #dcdfe4;
}

.container_equip .liste p {
  font-size: 15px;
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .modal_content h3 {
    padding-bottom: 5px;
  }

  .modal_content .m_body::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .container .block {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
