.block {
  display: none;
}

@media screen and (max-width: 743px) {
  .block {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #fff;
    height: 64px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(235, 235, 235);
    z-index: 999;
  }

  .block button {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    background: transparent;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .block button:hover {
    background: rgb(247, 247, 247);
  }

  .block button svg {
    display: block;
    fill: none;
    height: 16px;
    width: 16px;
    stroke: var(--noir);
    stroke-width: 4;
    overflow: visible;
  }
}