/*---modal-message-----*/

.modal_content {
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.m_head h3 {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

@media screen and (max-width: 743px) {
  .m_head {
    min-height: 48px;
  }
}

.m_body {
  padding: 0 24px 24px;
  overflow-y: auto;
}

.dateContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 15px;
}

.dateContent h4 {
  font-size: 17px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.gauch p {
  font-size: 15px;
  color: var(--noir6);
}

.block_error {
  display: flex;
  align-items: center;
  gap: 8px;
}

.block_error p {
  font-size: 14px;
  color: #c13515;
}

.droit p {
  font-size: 15px;
  color: var(--noir6);
}

.tileClassName:disabled {
  text-decoration: line-through;
}

.btn_action {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: var(--modal-footer-background-color);
}

.btn_action button {
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  cursor: pointer;
  height: 48px;
  border-radius: 8px;
  transition: 0.3s;
}

.btn_action button:nth-child(1) {
  background: transparent;
  color: var(--noir);
  text-decoration: underline;
  padding: 0 10px;
  margin-left: -10px;
}

.btn_action button:nth-child(1):hover {
  background-color: var(--gray-hover);
}

.btn_action button:nth-child(2) {
  background: var(--btn_noir1);
  color: #fff;
  padding: 0 24px;
}

.btn_action button:nth-child(2):hover {
  background: var(--btn_noir2);
}

.btn_action button:active {
  transform: scale(0.9);
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }
}
