.wrapper_img_profile {
  width: 100%;
  height: 200px;
  background-color: var(--noirc);
  border-radius: 16px;
  margin-bottom: 24px;
}

.wrapper_img_profile .image {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: #ddd;
  overflow: hidden;
}

.wrapper_img_profile .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 890px) {
  .wrapper_img_profile {
    height: 150px;
  }
}

@media screen and (max-width: 743px) {
  .wrapper_img_profile {
    height: 140px;
  }
}

@media screen and (max-width: 540px) {
  .wrapper_img_profile {
    height: 97px;
    margin-bottom: 15px;
  }
}
