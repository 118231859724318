.container {
  position: fixed;
  left: 80px;
  right: 80px;
  bottom: 8px !important;
  z-index: 2000;
  padding: 24px;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px;
  border-radius: 8px;
}

.txt_content {
  margin-bottom: 20px;
}

.block h1 {
  font-size: 16px;
  font-weight: var(--font_display_3_font-weight);
  margin-bottom: 10px;
}

.block p {
  font-size: 14px;
  color: var(--noir);
}
.block a {
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.btn_wrapper {
  display: flex;
  gap: 15px;
}

.btn_accept {
  width: 150px;
  border-radius: 8px;
  padding: 14px 24px;
  border: none;
  background: var(--btn_noir1);
  font-weight: var(--font_display_3_font-weight);
  color: var(--white);
  font-size: 16px;
}

.btn_accept:hover {
  background: var(--btn_noir2);
}

.btn_decline {
  background: transparent;
  border: 0;
  cursor: pointer;
  text-decoration: underline;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  font-size: 14px;
}

.btn_accept:active,
.btn_decline:active {
  transition: transform 0.3s;
  transform: scale(0.9);
}

@media screen and (max-width: 744px) {
  .container {
    left: 50px;
    right: 50px;
  }
}
@media screen and (max-width: 540px) {
  .container {
    left: 24px;
    right: 24px;
  }

  .btn_wrapper {
    flex-direction: column;
    align-items: center;
  }

  .btn_accept {
    width: 100%;
  }
}
