.container {
  padding: 30px 0 10px;
  margin-top: 30px;
  border-top: 1px solid var(--border-color);
}

.container h2 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  padding-bottom: 15px;
}

.grid_system {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.radio_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.radio_container h3 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 5px;
}

.radio_container p {
  font-size: 14px;
  color: var(--noir8);
}

@media screen and (max-width: 540px) {
  .container h2 {
    font-size: 20px;
  }
}
