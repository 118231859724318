.container {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
}

.link_reservation {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  border-bottom: 1px solid #ccc;
  padding-top: 20px;
}

.link_reservation button {
  position: relative;
  border: none;
  background-color: transparent;
  font-size: 15px;
  color: var(--noir8);
  font-weight: var(--font_display_2_font-weight);
  padding-bottom: 20px;
}

.link_reservation button.active {
  color: var(--noir);
}

.link_reservation button.active::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: var(--noir);
}

@media screen and (max-width: 760px) {
  .link_reservation {
    overflow-x: auto;
    white-space: nowrap;
    margin: 0 -24px;
    padding: 20px 24px 0;
  }

  .link_reservation::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 390px) {
  .link_reservation {
    gap: 20px;
  }
}

@media screen and (max-width: 360px) {
  .link_reservation {
    gap: 15px;
  }
}
