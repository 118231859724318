.container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
  margin-top: 15px;
}

.button {
  display: flex;
  justify-content: center;
  text-decoration: none;
  border: none;
  background: var(--tertiary-background);
  padding: 8px 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s;
}

.button span {
  color: var(--noir);
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.button:hover {
  background: var(--tertiary-background-hover);
}

.button:active {
  transform: scale(0.9);
}

@media screen and (max-width: 743px) {
  .container {
    justify-content: flex-start;
  }

  .button {
    flex: 1;
    padding: 8px 15px;
  }

  .button:hover {
    background: var(--tertiary-background);
  }
}
