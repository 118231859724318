.modal_content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 550px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid var(--border-color);
}

.m_head h3 {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

@media screen and (max-width: 743px) {
  .m_head {
    min-height: 48px;
  }
}

.m_body {
  overflow: auto;
  padding: 30px 24px 24px;
}

.m_body select,
.m_body input {
  width: 100%;
  height: 50px;
  padding: 5px 15px;
  font-size: 16px;
  border-radius: 8px;
  margin-top: 5px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}
.m_body select:focus,
.m_body input:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.m_body .erreur {
  color: rgb(193, 53, 21);
  font-size: 14px;
}

.btn_action button {
  border: none;
  color: #fff;
  background: var(--btn_noir1);
  margin-top: 30px;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.btn_action button:hover {
  background: var(--btn_noir2);
}

.btn_action button:active {
  transform: scale(0.9);
}

.btn_action button.loading {
  background: rgb(226, 226, 226);
  cursor: no-drop;
}

.btn_action button.loading:hover {
  background: rgb(226, 226, 226);
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_body {
    padding: 16px 24px;
  }

  .m_body::-webkit-scrollbar {
    display: none;
  }
}
