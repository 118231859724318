.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 24px;
  z-index: 2000;
}
.block {
  display: flex;
  align-items: center;
  gap: 10px;
}
.block p {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: rgb(193, 53, 21);
}
.block svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: rgb(193, 53, 21);
}
