.container .center {
  padding: 20px 0;
}

.container .center h2 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 17px;
}

.container .center h3 {
  font-size: 16px;
}

.container button {
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  text-decoration: underline;
  border: none;
  background-color: transparent;
  outline: none;
  color: var(--noir);
}

.container button:active {
  color: var(--noir9);
}

.container .content,
.container .content_list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.content svg {
  display: block;
  height: 64px;
  width: 64px;
  fill: currentcolor;
}

.content_list svg {
  display: block;
  height: 32px;
  width: 32px;
  fill: currentcolor;
}

@media screen and (max-width: 743px) {
  .container {
    margin-bottom: 100px;
  }
}
