.block_center {
  padding: 0 50px;
  overflow-y: auto;
}
.block_center::-webkit-scrollbar {
  display: none;
}
.block_center .block_form {
  width: 100%;
  max-width: 464px;
  margin-left: auto;
  margin-right: auto;
}
.container {
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 20px 0;
}
.card {
  border: 1px solid #ebebeb;
  width: 336px;
  border-radius: 12px;
  overflow: hidden;
}
/*---card_content----*/

.card_content .photo {
  width: 100%;
  height: 200px;
  background: var(--gray);
}
.card_content .photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/*---block description----*/
.card_content .descr {
  padding: 24px;
}
.card_content .title {
  margin-bottom: 24px;
}
.card_content .title h1,
.card_content .title p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-y: hidden;
}
.card_content .title h1 {
  font-size: 20px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
}
.card_content .title p {
  padding-top: 5px;
  font-size: 15px;
  color: var(--noir8);
}
/*---profile----*/
.profile {
  padding: 24px 0;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}
.profile h2 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 15px;
}
.profile .pro_img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  background: var(--gray);
}
.profile .pro_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile .pro_img h3 {
  color: var(--noir);
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
}

/**----voyageur---*/
.voyageur {
  padding: 24px 0;
}
.voyageur p {
  font-size: 15px;
}
.aprops {
  padding: 24px 0;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}
.aprops p {
  font-size: 14px;
}
/*----equipement---*/
.equipement h3 {
  padding-top: 24px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}
.equipement p {
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb;
}
/*----emplacement---*/
.emplacement h3 {
  padding: 24px 0;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}
.emplacement h4 {
  font-weight: 400;
  font-size: 15px;
  color: var(--noir4);
}
.emplacement p {
  padding-top: 15px;
  font-size: 12px;
  color: var(--noir8);
}
@media screen and (max-width: 900px) {
  .block_center {
    padding: 10px 0 0;
  }
  .block_center .block_form {
    padding: 0 24px 24px;
  }
}
@media (max-width: 744px) {
  .block_center {
    padding: 10px 0 50px;
  }
  .card {
    padding: 0;
    border: none;
  }

  .card_content .descr {
    padding: 24px 0;
  }
}
