.asavoir_block {
  padding-bottom: 30px;
}
.asavoir_block h4 {
  font-size: 20px;
  font-weight: var(--font_display_2_font-weight);
}

.asavoir_block p {
  font-size: 16px;
  margin: 7px 0;
}

.savoir_btn button {
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-top: 3px;
  text-decoration: underline;
  cursor: pointer;
}

/*---modal_savoir-----*/
.modal_content {
  display: flex;
  flex-direction: column;
  width: 700px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.modal_content .m_body {
  overflow: auto;
  padding: 0 24px 50px;
}

.modal_content .m_body h2 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.modal_content .infos {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 24px;
}

.infos .content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.infos .content h3 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 3px;
}

.modal_content .content p {
  font-size: 16px;
}

@media screen and (max-width: 743px) {
  .asavoir_block h4 {
    font-size: 16px;
  }

  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }

  .modal_content .m_body::-webkit-scrollbar {
    display: none;
  }

  .modal_content .content p {
    font-size: 15px;
  }
}
