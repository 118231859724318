.flex_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.sponsor_card {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 12px;
  padding: 20px;
}

.wrapper_img_profile {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
  text-decoration: none;
}

.wrapper_img_profile .image_block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #ddd;
  border: 0;
  outline: none;
  overflow: hidden;
}

.wrapper_img_profile .image_block svg {
  display: block;
  height: 100%;
  width: 100%;
  fill: #999;
  background: #fff;
  stroke-width: 3;
}

.wrapper_img_profile .image_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about_name {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--noir);
}

.about_name h2 {
  font-size: 16px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.wrapper_name p {
  font-size: 12px;
  color: var(--noir8);
  line-height: 13px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.body_block {
  margin-top: 15px;
}

.body_block p.about {
  font-size: 13px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-y: hidden;
}

.footer_block {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  margin-top: auto;
}

.footer_block p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.send_message {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  margin-right: -10px;
  margin-bottom: -10px;
  color: var(--noir);
  height: 40px;
  width: 40px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s;
}

.send_message:hover {
  background: var(--tertiary-background-hover);
}

.send_message:active {
  transform: scale(0.9);
}

@media screen and (max-width: 990px) {
  .flex_container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 540px) {
  .search_block {
    width: 100%;
  }

  .flex_container {
    grid-template-columns: 1fr;
  }

  .sponsor_card {
    padding: 16px;
  }
}
