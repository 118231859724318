.nombre_and_tri {
  padding: 20px 0 10px;
}

.nombre_and_tri h3 {
  font-size: 16px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  margin-bottom: 5px;
}

.nombre_and_tri .array_vide h4 {
  padding-top: 30px;
  font-size: 25px;
  font-weight: var(--font_display_2_font-weight);
}

.nombre_and_tri .array_vide p {
  font-size: 16px;
}

@media screen and (max-width: 743px) {
  .array_result h3 {
    font-size: 14px;
    margin-bottom: 2px;
  }

  .nombre_and_tri .array_vide h4 {
    padding-top: 5px;
  }

  .array_result p {
    font-size: 13px;
    color: var(--noir8);
  }
}
