.nav_scroll {
  position: fixed;
  top: 0;
  right: 0;
  left: var(--sidebar-with);
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(12px);
  border-bottom: 1px solid #dcdfe4;
  z-index: 20;
  transform: translateY(-255px);
  transition: 0.3s ease-in-out;
}

.nav_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}

.nav_scroll .nav_liens {
  display: flex;
  gap: 20px;
  align-items: center;
}

.nav_scroll .nav_liens button {
  background-color: transparent;
  border: none;
  padding: 27px 0;
  color: var(--noir4);
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  border-bottom: 3px solid transparent;
  transition: 0.1s ease-in-out;
}

.nav_scroll .nav_liens button:hover {
  border-bottom-color: #333;
}

.nav_scroll .btn_block {
  display: flex;
  align-items: center;
  gap: 15px;
}

.nav_scroll .head {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.nav_scroll p.promo {
  font-family: var(--font-family_heading);
  font-size: 15px;
  font-weight: var(--font_heading_1_font-weight);
  margin-right: 10px;
  color: #999;
  text-decoration: line-through;
}

.nav_scroll .head .default_price {
  display: flex;
  align-items: center;
}

.nav_scroll .head h3 {
  font-family: var(--font-family_heading);
  font-size: 15px;
}

.nav_scroll .head button {
  background: transparent;
  border: none;
  font-family: var(--font-family_heading);
  font-size: 17px;
  font-weight: var(--font_heading_1_font-weight);
  text-decoration: underline;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.nav_scroll .head button:active {
  color: #999;
  transform: scale(0.96);
}

.nav_scroll .head span {
  font-size: 15px;
  font-weight: 400;
  margin-left: 5px;
}

.nav_scroll button.nav_button,
.nav_scroll .btn_block a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: var(--button1);
  border: none;
  color: #fff;
  padding: 0 20px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  overflow: hidden;
  z-index: 2;
}

.nav_scroll button.nav_button::before,
.nav_scroll .btn_block a::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--button2);
  z-index: -10;
  opacity: 0;
  transition: opacity 0.4s linear;
}

.nav_scroll button.nav_button:hover::before,
.nav_scroll .btn_block a:hover::before {
  opacity: 1;
}

.nav_scroll button.nav_button:active,
.nav_scroll .btn_block a:active {
  transform: scale(0.9);
}

.nav_scroll.active {
  transform: none;
}

@media screen and (min-width: 1440px) {
  .nav_content {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

@media screen and (max-width: 900px) {
  .nav_content {
    padding: 0 24px;
    height: 80px;
  }

  .nav_scroll .nav_liens button {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .nav_scroll {
    display: none;
  }
}
