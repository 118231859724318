.section {
  padding: 10px 0;
}

.section h3 {
  font-size: 22px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  padding: 24px 0;
  line-height: 1.2;
}

.marker_text {
  padding-bottom: 8px;
}

.marker_text p {
  font-size: 14px;
  color: var(--noir8);
}

.maps .map_block {
  width: 100%;
  height: 460px;
  background: #ddd;
  border-radius: 15px;
  overflow: hidden !important;
}

.map_block .marker_map {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--noir);
  color: #fff;
  cursor: pointer;
  position: absolute;
  transform: translate(-50%, -50%);
}

.marker_map svg {
  display: block;
  height: 20px;
  width: 20px;
  fill: var(--white);
}

.address p {
  padding-bottom: 24px;
}

@media screen and (max-width: 743px) {
  .address p {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 700px) {
  .section {
    padding: 0 0 20px;
  }
}

@media screen and (max-width: 540px) {
  .maps .map_block {
    overflow: hidden;
    backface-visibility: hidden;
    transform: translate3d(0px, 0px, 0px);
    position: relative;
    height: 250px;
    border-radius: 12px;
  }
}

/*------description_quartier de l'hôtel----*/
.description h3 {
  padding: 25px 0 15px;
}

.description .content p {
  font-size: 16px;
  padding: 0;
  font-weight: var(--font_display_1_font-weight);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow-y: hidden;
}
