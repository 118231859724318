.section_1 {
  display: grid;
  grid-template-columns: 1fr 500px;
  margin-bottom: 90px;
}

.block_left h3 {
  font-size: 35px;
  font-weight: var(--font_heading_1_font-weight);
  font-family: var(--font-family_heading);
  margin-bottom: 30px;
  color: var(--noir);
}

.acceuil_voyage {
  display: flex;
  gap: 20px;
  padding-top: 30px;
  color: var(--noir3);
}

.block_left p {
  color: currentColor;
}

.acceuil_voyage.valeur {
  font-weight: var(--font_display_2_font-weight);
  color: var(--color-notif);
}

.acceuil_voyage.valeur a {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.acceuil_voyage.valeur p {
  font-weight: var(--font_display_2_font-weight);
}
.acceuil_voyage p strong {
  font-weight: var(--font_display_3_font-weight);
}

.img_droit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.img_droit img {
  width: 400px;
}
@media screen and (max-width: 950px) {
  .section_1 {
    grid-template-columns: 1fr;
    padding-bottom: 80px;
  }
  .img_droit {
    justify-content: center;
  }
}
@media screen and (max-width: 766px) {
  .section_1 {
    margin-bottom: 40px;
  }

  .img_droit img {
    width: 100%;
  }
  .acceuil_voyage p {
    font-size: 15px;
  }
}

@media screen and (max-width: 540px) {
  .section_1 .block_left h3 {
    font-size: 22px;
    line-height: 28px;
  }
}
