.container {
  padding: 10px 50px;
  width: 600px;
  max-width: 100%;
}

.profil_comment h3 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 19px;
  padding-bottom: 30px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.profil_comment .comment_list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.profil_block_img {
  display: flex;
  gap: 10px;
}

.profil_block_img .image_block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  text-decoration: none;
  background: #f1f1f1;
  border-radius: 50%;
  overflow: hidden;
}

.profil_block_img .image_block img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  object-fit: cover;
}

.profil_block_img .image_block h2 {
  text-transform: uppercase;
  color: #000;
}

.pro_date h4 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.pro_date p {
  color: var(--noir9);
  font-size: 14px;
  margin: 0;
}

.profil_comment .message {
  margin-top: 10px;
  margin-bottom: 5px;
}

.profil_comment .hotel_img {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.profil_comment .hotel_img_card {
  width: 70px;
  height: 47px;
  background: #f1f1f1;
  border-radius: 10px;
  overflow: hidden;
}

.profil_comment .hotel_img_card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profil_comment .message h4 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.profil_comment .message h5 {
  color: var(--noir9);
  font-size: 14px;
}

.profil_comment .message p {
  margin: 15px 0 5px;
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow-y: hidden;
}

.message_block_footer {
  margin-top: 30px;
}

.message_block_footer a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  text-decoration: none;
  color: var(--noir);
  background: var(--tertiary-background);
  padding: 0 25px;
  width: fit-content;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s;
}

.message_block_footer a span {
  color: var(--noir);
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
}

.message_block_footer a:hover {
  background: var(--tertiary-background-hover);
}

.message_block_footer a:active {
  transform: scale(0.9);
}

@media screen and (max-width: 743px) {
  .container {
    padding: 10px 0;
  }

  .message_block_footer a {
    width: 100%;
  }

  .message_block_footer a:hover {
    background: var(--tertiary-background);
  }
}
