.head {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: #fff;
  z-index: 50;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 8px;
}

.head .go_back svg {
  display: block;
  fill: none;
  height: 20px;
  width: 20px;
  stroke: var(--noir);
  stroke-width: 4;
  overflow: visible;
}

.head button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 50%;
  transition: 0.3s;
}

.head button:hover {
  background-color: var(--gray);
}

.head button:active {
  transform: scale(0.9);
}

.block_right {
  display: flex;
  align-items: center;
}

.block_right svg.partage {
  display: block;
  fill: none;
  height: 16px;
  width: 16px;
  stroke: var(--noir);
  stroke-width: 4;
  overflow: visible;
}

.block_right svg.plus {
  display: block;
  height: 18px;
  width: 18px;
  fill: var(--noir);
}

/*------titre-----*/
.titre_block {
  padding: 10px 24px;
}

.titre {
  width: var(--fav-list-width);
  max-width: 100%;
  margin: 0 auto;
}

.titre h1 {
  color: var(--noir);
  font-size: 20px;
  font-weight: var(--font_display_2_font-weight);
}
