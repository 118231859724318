.form_modif {
  padding: 0 100px;
  margin-top: 24px;
}

.form_modif .modif_box_input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 20px 0;
}

.form_modif .modif_box_input input {
  box-sizing: border-box;
  padding: 0 15px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.form_modif .modif_box_input input:focus,
.form_modif textarea:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.form_modif textarea {
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  margin-top: 5px;
  font-size: 16px;
  padding: 10px;
  border-radius: 8px;
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
  resize: vertical;
}
.form_modif span {
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir8);
}

.form_modif .modif_button {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc;
}

.modif_button button:nth-child(1) {
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  padding: 0 10px;
  height: 48px;
  margin-left: -10px;
  color: var(--noir);
  text-decoration: underline;
  cursor: pointer;
}

.modif_button button:nth-child(1):hover {
  background-color: var(--gray);
}

.modif_button button:nth-child(2) {
  width: 130px;
  text-align: center;
  border: none;
  outline: none;
  color: #fff;
  background: var(--btn_noir1);
  padding: 0 20px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
}

.modif_button button:nth-child(2):hover {
  background: var(--btn_noir2);
}

.modif_button button:nth-child(1):active,
.modif_button button:nth-child(2):active {
  transition: 0.3s;
  transform: scale(0.9);
}

.modif_button button.loading {
  padding: 8px 20px;
  height: 48px;
  width: 130px;
  background: rgb(226, 226, 226);
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  cursor: no-drop;
}

.modif_button button.loading:hover {
  background: rgb(226, 226, 226);
}

@media screen and (max-width: 768px) {
  .form_modif {
    padding: 0;
  }
}

@media screen and (max-width: 540px) {
  .form_modif .modif_box_input {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding: 10px 0;
  }

  .form_modif .modif_button {
    padding-top: 10px;
  }
}
