.signal button {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  transition: 0.3s;
}

.signal svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: var(--noir6);
}

.signal button:hover {
  background-color: var(--gray);
}

.signal button:active {
  transform: scale(0.9);
}