.container_summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.container h3 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 10px;
  line-height: 1.2;
}

.container .avis {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 4px;
}

.container .avis p {
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
}

.container .avis button {
  text-decoration: underline;
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir4);
}

.descrip_detail {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  padding-top: 5px;
}

.descrip_detail p {
  color: var(--noir4);
  font-size: 16px;
}

.descrip_detail span {
  display: inline-block;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: var(--noir6);
}

.address {
  display: none;
  margin-top: 10px;
}

.address button {
  text-align: left;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

@media screen and (max-width: 1023px) {
  .address {
    display: block;
  }
}

.host_profile {
  display: flex;
  align-items: center;
  gap: 15px;
}

.host_profile button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.photo_block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  background: #f1f1f1;
  border-radius: 50%;
  overflow: hidden;
}

.photo_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo_block h2 {
  text-transform: uppercase;
  color: var(--noir);
  font-size: 18px;
}

.about_name {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--noir);
}

.host_profile_date h5 {
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 3px;
}

.about_name h2 {
  font-size: 18px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.host_profile_date p {
  color: var(--noir8);
  font-size: 15px;
}

/*-----bonne_affaire-----*/
.bonne_affaire {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
}

.affaire_text h4 {
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
}

.affaire_text p {
  font-size: 16px;
  color: var(--noir);
}

.bonne_affaire svg {
  display: block;
  height: 32px;
  width: 32px;
  fill: rgb(227, 28, 95);
  stroke: currentcolor;
}

@media screen and (max-width: 768px) {
  .container_summary {
    display: block;
  }
  .host_profile {
    margin-top: 20px;
  }

  .container h3 {
    font-size: 24px;
  }
}

@media screen and (max-width: 540px) {
  .bonne_affaire {
    margin-top: 30px;
    padding: 20px 0 0;
    border: 0;
    border-top: 1px solid var(--border-color);
    border-radius: 0;
  }

  .affaire_text h4 {
    padding-bottom: 7px;
    font-size: 15px;
  }

  .affaire_text p {
    font-size: 15px;
  }
}

@media screen and (max-width: 414px) {
  .container p {
    margin-left: 0;
  }
}

.block {
  margin-top: 50px;
  padding-top: 35px;
  border-top: 1px solid var(--border-color);
}

.block .icons {
  display: flex;
  gap: 15px;
  padding: 15px 0;
  color: var(--noir);
}

.block svg {
  display: block;
  height: 24px;
  width: 24px;
  fill: currentcolor;
}

.block .icons h3 {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  font-size: 16px;
  padding-bottom: 3px;
}

.block .icons p {
  font-weight: var(--font_display_1_font-weight);
  color: var(--noir6);
  font-size: 14px;
}

@media screen and (max-width: 743px) {
  .block {
    margin-top: 24px;
    padding-top: 15px;
  }
  .descrip_detail p {
    font-size: 14px;
  }
}
