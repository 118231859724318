.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 30px 0;
}

.container h3 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 25px;
  color: var(--noir3);
}

.flter_block {
  display: flex;
  align-items: center;
  gap: 15px;
}

.all_filter button {
  position: relative;
  background: transparent;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;
}

.all_filter button:hover {
  background-color: var(--gray);
}

.all_filter svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: #222222;
}

.all_filter button:active {
  transform: scale(0.9);
}

.all_filter button.active::after {
  position: absolute;
  content: "";
  display: block;
  inset: 0;
  border: 2px solid #000;
  background: transparent;
  border-radius: 50px;
}

.all_filter button span.length {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: -2px;
  right: -2px;
  width: 18px;
  height: 18px;
  font-size: 10px;
  font-weight: var(--font_display_3_font-weight);
  border-radius: 50%;
  background: #000;
  color: var(--white);
  z-index: 10;
}

.all_filter button.active span.length {
  display: flex;
}

.search_block {
  width: 250px;
}

@media screen and (max-width: 743px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
  }

  .flter_block,
  .search_block {
    width: 100%;
  }
}
