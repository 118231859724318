.section {
  padding: 30px 0;
}

.heading {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding: 24px 0;
}

.heading h3 {
  width: 450px;
  font-size: 22px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  line-height: 1.2;
}

.heading_navigation {
  display: flex;
  align-items: center;
  gap: 14px;
}

.heading_navigation .prev,
.heading_navigation .next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: var(--noir);
  border: 1px solid #ccc;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.heading_navigation .prev:hover,
.heading_navigation .next:hover {
  transform: scale(1.1);
}

.heading_navigation .prev.disable,
.heading_navigation .next.disable {
  color: #f1f1f1;
  border: 1px solid #f1f1f1;
  cursor: no-drop;
}

.heading_navigation .prev.disable:hover,
.heading_navigation .next.disable:hover {
  transform: scale(1);
}

/* swiper_mobile */
.swiper_mobile {
  display: flex;
  gap: 15px;
  overflow-x: auto;
  margin: 0 -24px;
  scroll-snap-type: x mandatory;
}

.swiper_mobile::-webkit-scrollbar {
  display: none;
}

.swiper_mobile_content {
  min-width: 200px;
  max-width: 200px;
  scroll-snap-align: center;
}

/* slider pc  */
.box_image {
  overflow: hidden;
}

/* slider  pc and mobile  */
.image {
  display: block;
  width: 100%;
  height: 180px;
  border-radius: 15px;
  overflow: hidden;
  background: #ddd;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.section a {
  text-decoration: none;
}

.recomande_desc {
  padding-top: 10px;
}

.recomande_desc p {
  font-size: 12px;
}

.hotel_evaluation {
  display: flex;
}

.hotel_evaluation .block_eval {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--noir);
}

.hotel_evaluation p {
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.descrip_detail p {
  color: var(--noir4);
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.price_block {
  display: flex;
  align-items: center;
}

.price_block .promo {
  font-family: var(--font-family_heading);
  font-size: 14px;
  margin-right: 10px;
  color: #999;
  text-decoration: line-through;
}

.price_block .price_normal {
  display: flex;
  align-items: center;
}

.price_block .price_normal p {
  font-family: var(--font-family_heading);
  color: var(--noir);
  font-size: 15px;
}

.price_block .price_normal span {
  font-size: 15px;
  font-weight: var(--font_display_1_font-weight);
  margin-left: 5px;
}

@media screen and (max-width: 950px) {
  .image {
    height: 240px;
  }
}

@media screen and (max-width: 743px) {
  .section {
    padding: 0 0 20px;
  }
}

@media screen and (max-width: 600px) {
  .heading_navigation {
    display: none;
  }

  .image {
    height: 180px;
  }
}

@media screen and (max-width: 414px) {
  .heading h3 {
    width: 100%;
  }

  .image {
    height: 150px;
  }
}
