/*-----voir toutes les photos 1 par 1----*/
.modal_image_one {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100dvh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  padding-top: 50px;
  overflow-y: hidden;
}

.modal_image_one.active {
  visibility: visible;
  opacity: 1;
}

.container_image_one .entete {
  position: absolute;
  top: 10px;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}

.container_image_one .entete p {
  color: #fff;
  font-size: 15px;
}

.container_image_one button.close {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  outline: none;
  background: transparent;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.container_image_one button.close:hover {
  background: rgb(77, 77, 77);
}

.container_image_one button.close:active {
  transition: 0.2s;
  transform: scale(0.9);
}

.container_image_one button.close:focus-visible {
  outline: none;
  transition: box-shadow 0.2s cubic-bezier(0.2, 0, 0, 1);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.8), 0 0 0 4px #222222;
}

.container_image_one .container_buttons {
  position: absolute;
  top: 46%;
  left: 0;
  right: 0;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  color: #ddd;
  z-index: 999;
}

.container_image_one .container_buttons .prev,
.container_image_one .container_buttons .next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49px;
  height: 49px;
  background: #242424;
  border-radius: 50%;
  font-size: 14px;
  cursor: pointer;
  visibility: visible;
}

.prev svg,
.next svg {
  display: block;
  fill: none;
  height: 16px;
  width: 16px;
  stroke: var(--white);
  stroke-width: 4;
  overflow: visible;
}

.container_image_one .container_buttons .prev:hover,
.container_image_one .container_buttons .next:hover {
  background: var(--noir5);
}
.container_image_one .container_buttons .prev:active,
.container_image_one .container_buttons .next:active {
  transition: transform 0.25s cubic-bezier(0.2, 0, 0, 1);
  transform: scale(0.9);
}

.container_image_one .container_buttons .prev.disable,
.container_image_one .container_buttons .next.disable {
  visibility: hidden;
}

.swiper_one_1 .swiper_image_one {
  text-align: center;
  font-size: 18px;
  background: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  -webkit-tap-highlight-color: transparent;
}

.slider_container_one {
  width: 80vw;
  padding: 0 15px;
}

.swiper_one_1 {
  width: 100%;
}

.block_image {
  width: 100%;
}

.block_image img {
  display: inline-block;
  height: 70vh;
  max-width: 100%;
  margin-bottom: 5px;
  cursor: pointer;
  object-fit: contain;
}

.block_image p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: 400;
}

@media screen and (max-width: 800px) {
  .container_image_one .entete {
    padding: 0 10px;
  }

  .container_image_one .container_buttons {
    display: none;
  }

  .slider_container_one {
    padding: 0;
    width: 100vw;
    margin-top: -20px;
  }
}

@media screen and (max-width: 743px) {
  .block_image img {
    height: 60vh;
  }

  .block_image p {
    padding: 0 10px;
  }
}
