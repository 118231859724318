.navbar {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 30px;
  height: 80px;
  width: auto;
  z-index: 100;
  transition: 0.5s ease-in-out;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 0px;
}

.navbar .logo {
  display: flex;
  align-items: center;
  gap: 30px;
}

.navbar .logo a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.navbar .right {
  display: flex;
  gap: 10px;
}

.navbar .liens {
  display: flex;
  align-items: center;
}

.navbar .liens a {
  display: flex;
  position: relative;
  height: fit-content;
  text-decoration: none;
  color: var(--noir);
  font-size: 15px;
  font-weight: var(--font_display_1_font-weight);
  padding: 8px 15px;
  border-radius: 30px;
  background: transparent;
}

.navbar .liens a:hover {
  background-color: var(--gray);
}

.navbar .liens a.active {
  background-color: var(--gray);
}

.navbar .liens a span {
  position: absolute;
  top: -2px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  padding: 3px 5px;
  font-weight: var(--font_display_2_font-weight);
  height: 18px;
  min-width: 18px;
  max-width: 36px;
  border-radius: 50px;
  color: #fff;
  background: rgb(255, 56, 92);
  animation-duration: 0.3s;
  animation-name: notification;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.buttons .add_home {
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  color: var(--noir);
  font-weight: 400;
  padding: 3px 20px;
  border: 1px solid var(--noir);
  background: transparent;
  border-radius: 50px;
  font-size: 15px;
  cursor: pointer;
}

.buttons .add_home:hover {
  background-color: var(--gray);
}

.buttons .add_home:active {
  transition: 0.3s;
  transform: scale(0.9);
}

.send_message {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  color: var(--noir);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s;
}

.send_message:hover {
  background: var(--gray);
}

.send_message:active {
  transform: scale(0.9);
}

.toogle {
  position: relative;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 14px;
  color: #222222;
  background-color: transparent;
  border: 1px solid var(--border-color);
  padding: 8px 8px 8px 14px;
  border-radius: 30px;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  -webkit-align-items: center;
  vertical-align: middle;
  cursor: pointer;
}

.wrapper:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
}

.toogle.active .wrapper {
  box-shadow: var(--box-shadow-border);
}

.toggle_menu svg {
  display: block;
  fill: none;
  height: 16px;
  width: 16px;
  stroke: currentcolor;
  stroke-width: 3;
  overflow: visible;
}

.toggle_profile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--gray);
  cursor: pointer;
  overflow: hidden;
}

.toggle_profile svg {
  display: block;
  height: 100%;
  width: 100%;
  fill: #717171;
  background: #fff;
  stroke-width: 3;
}

.toggle_profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper span {
  position: absolute;
  top: -2px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 5px;
  font-size: 10px;
  font-weight: var(--font_display_2_font-weight);
  height: 18px;
  min-width: 18px;
  max-width: 36px;
  border-radius: 50px;
  color: #fff;
  background: rgb(255, 56, 92);
  animation-duration: 0.3s;
  animation-name: notification;
}

@keyframes notification {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@media screen and (max-width: 1024px) {
  .buttons .add_home {
    padding: 0;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .buttons .add_home span {
    display: none;
  }
}

@media screen and (max-width: 970px) {
  .navbar {
    padding: 0 24px;
  }
}

@media screen and (max-width: 770px) {
  .navbar {
    height: 70px;
  }
  .buttons .add_home {
    display: none;
  }
  .navbar .liens {
    display: none;
  }

  .wrapper {
    gap: 12px;
    padding: 6px 6px 6px 12px;
  }

  .wrapper:hover {
    box-shadow: none;
  }

  .toogle.active .wrapper {
    box-shadow: none;
  }
}
