/*-------display pc pour l'image----*/
.block_image_hotel {
  padding: 0 50px;
}

.block_image_hotel .image_slide {
  display: none;
}

@media screen and (min-width: 1440px) {
  .block_image_hotel {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

.pc_detail_image {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, calc(38vh - 80px));
  grid-gap: 8px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.box {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pc_detail_image .box3 {
  grid-column: 2 / 4;
  grid-row: 1 / 3;
}

.pc_detail_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: middle;
}

.pc_detail_image .box1,
.pc_detail_image .box2,
.pc_detail_image .box3,
.pc_detail_image .box4,
.pc_detail_image .box5 {
  position: relative;
  background: hsl(180, 4%, 90%);
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.pc_detail_image .box1::before,
.pc_detail_image .box2::before,
.pc_detail_image .box3::before,
.pc_detail_image .box4::before,
.pc_detail_image .box5::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
  opacity: 0;
  transition: 0.2s ease-in;
}

.pc_detail_image .box1:hover::before,
.pc_detail_image .box2:hover::before,
.pc_detail_image .box3:hover::before,
.pc_detail_image .box4:hover::before,
.pc_detail_image .box5:hover::before {
  opacity: 1;
  cursor: pointer;
}

.pc_detail_image .all_image_pc {
  position: absolute;
  bottom: 10px;
  right: 20px;
  padding: 7px 10px;
  border-radius: 10px;
  font-weight: bold;
  color: var(--noir);
  background: #fff;
  outline: none;
  border: 1px solid var(--noir3);
  cursor: pointer;
  transition: 0.2s ease-in-out;
  z-index: 2;
}

.pc_detail_image button.all_image_pc:hover {
  background: #fffc;
}

@media screen and (min-width: 1428px) {
  .pc_detail_image {
    grid-template-rows: repeat(2, calc(30vh - 80px));
  }
}

@media screen and (max-width: 1200px) {
  .pc_detail_image {
    grid-template-rows: repeat(2, 200px);
  }
}

@media screen and (max-width: 900px) {
  .block_image_hotel {
    padding: 0 24px;
  }
}

@media screen and (max-width: 700px) {
  .block_image_hotel {
    padding: 0;
  }

  .pc_detail_image {
    display: none;
  }
}
