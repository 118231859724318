.main {
  min-height: 100vh;
  max-width: 600px;
  margin: 120px auto 50px;
}

.container {
  border: 1px solid var(--border-color);
  border-radius: 16px;
  padding: 24px;
  padding-top: 0;
}

@media screen and (max-width: 743px) {
  .main {
    margin-top: 20px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    border: none;
    border-radius: none;
  }
}