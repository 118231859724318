.modal_content {
  display: flex;
  flex-direction: column;
  width: 570px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid var(--border-color);
}

.m_head h3 {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

@media screen and (max-width: 743px) {
  .m_head {
    min-height: 48px;
  }
}

.m_body {
  overflow-y: auto;
  padding: 20px 24px 50px;
}

.icon_block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 19px;
}

.reseaux_partage .icon {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 16px !important;
  border-radius: 8px;
  border: 1px solid #ddd !important;
  transition: 0.3s;
}

.reseaux_partage .icon h4 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.m_body button.copy_lien {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #dcdfe4;
  padding: 15px 16px;
  color: var(--noir);
  border-radius: 8px;
  cursor: pointer;
  background-color: transparent;
  transition: 0.3s;
}

.m_body button.copy_lien span {
  color: var(--noir);
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
}

.reseaux_partage .icon:hover,
.m_body button.copy_lien:hover {
  background-color: var(--gray) !important;
}

.m_body button.copy_lien:active,
.reseaux_partage .icon:active {
  transform: scale(0.9);
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .modal_content .m_body::-webkit-scrollbar {
    display: none;
  }

  .m_body button.copy_lien:hover,
  .reseaux_partage .icon:hover {
    background-color: transparent !important;
  }
}

@media screen and (max-width: 540px) {
  .icon_block {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .reseaux_partage .icon h4 {
    font-size: 14px;
  }
}
