/* container_load */
.container_load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/*------liste message-----*/
.liste_container {
  height: 100vh;
}

.block_grid {
  display: grid;
  grid-template-columns: 400px 1fr;
  height: 100%;
}

.block_grid_1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  border-right: 1px solid rgb(235, 235, 235);
  overflow: hidden;
}

/*-------liste-----*/
.liste_container h1.boite {
  color: var(--noir);
  font-size: 25px;
  font-weight: var(--font_display_3_font-weight);
  font-size: 25px;
  padding-left: 24px;
  padding-bottom: 24px;
  display: none;
}

.block_grid_1 .liste {
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 50px;
  overflow: auto;
}

.block_grid_1 .liste .liste_content {
  display: flex;
  gap: 15px;
  text-decoration: none;
  width: 100%;
  padding: 20px;
  margin-bottom: 5px;
  border-bottom: 1px solid rgb(235, 235, 235);
}

.block_grid_1 .liste .liste_content:last-child {
  border-bottom: none;
}

.liste .liste_content .img_block {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
  flex: 1;
}
.liste .liste_content .img_block h2 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir3);
}

.liste .profil_text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  height: 100%;
  flex: 2;
}

.liste .profil_text h5 {
  font-size: 16px;
  color: var(--noir6);
  font-weight: 400;
  line-height: 24px;
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.liste .profil_text p {
  line-height: 22px;
  font-size: 15px;
}

/*-------block 2-detail----*/
.block_grid_2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.block_grid_2 .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid rgb(235, 235, 235);
  padding: 20px 30px;
}

@media screen and (min-width: 1440px) {
  .liste_container {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

@media screen and (max-width: 800px) {
  .block_grid {
    grid-template-columns: 1fr;
    border: none;
  }

  .liste_container h1.boite {
    display: block;
    padding-bottom: 15px;
  }

  .block_grid_1 {
    border-right: none;
  }

  .block_grid_1 .liste {
    padding: 6px;
  }

  .liste .liste_content .img_block {
    width: 40px;
    height: 40px;
  }

  .block_grid_2 {
    display: none;
  }
}

@media screen and (max-width: 743px) {
  .liste_container {
    padding-top: 30px;
  }

  .block_grid_1 .liste {
    padding-bottom: 80px;
  }

  .block_grid_1 .liste::-webkit-scrollbar {
    display: none;
  }
}
