.block {
  padding: 24px;
  border: 1px solid #dcdfe4;
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.block h2 {
  color: var(--noir);
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  padding-bottom: 24px;
}

/*-------content------*/
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/*-------body------*/
.body {
  width: 100%;
  max-width: 600px;
}

.block_body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 24px 0;
  border-bottom: 1px solid var(--border-color);
}

.frais,
.reduction,
.total,
.revenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total_block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 0;
}

.reduction p {
  color: green;
  font-weight: var(--font_display_2_font-weight);
}

.total p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noiir);
}

/*-----footer----*/
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  background: #fff;
  border-top: 1px solid rgb(221, 221, 221);
}

.footer button.retour {
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  padding: 10px;
  color: var(--noir);
  text-decoration: underline;
  cursor: pointer;
}

.footer button.retour:hover {
  background: #f1f1ff;
}

.footer button.next {
  position: relative;
  border: none;
  outline: none;
  color: #fff;
  background: var(--btn_noir1);
  width: 130px;
  height: 40px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.footer button.next:hover {
  background: var(--btn_noir2);
}

.footer button.next:active {
  transform: scale(0.9);
}

.footer button.next.disable {
  background: rgb(226, 226, 226);
  color: #fff;
  cursor: no-drop;
}

.footer button.btn_loading {
  width: 130px;
  height: 40px;
  border: none;
  outline: none;
  background: rgb(226, 226, 226);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

@media screen and (max-width: 974px) {
  .body {
    width: 100%;
  }
}
