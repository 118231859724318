/*------modal de partage-------*/
.modal_content {
  display: flex;
  flex-direction: column;
  width: 550px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.m_body {
  overflow: auto;
  padding: 0 24px 24px;
}

.m_body h3 {
  padding-bottom: 20px;
  color: var(--noir);
  text-align: center;
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
}

.m_body button,
.m_body a {
  display: inline-block;
  text-decoration: none;
  margin-top: 10px;
  background: transparent;
  border: none;
  outline: none;
  touch-action: manipulation;
  border-radius: 16px;
  cursor: pointer;
}

.m_body .block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  text-align: left;
  border: 1px solid rgb(235, 235, 235);
  width: 100%;
  padding: 15px 24px;
  min-height: 80px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir4);
  border-radius: 16px;
  cursor: pointer;
  background: transparent;
  box-sizing: border-box;
  color: var(--noir4);
}

.m_body .block:hover {
  border: 1px solid #333;
}

.m_body .block h4 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 16px;
  color: var(--noir);
  padding-bottom: 2px;
}

.m_body .block p {
  font-size: 14px;
  color: #888;
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }
}
