.seting_container {
  padding: 40px 160px 50px;
  min-height: 100vh;
}

.seting_container h1 {
  font-size: 20px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
  padding: 10px 0 30px;
}

/*----seting_box------*/
.seting_container .seting_box {
  display: grid;
  grid-template-columns: 1fr 5px 1fr;
  grid-gap: 50px;
}

.separate {
  height: 100%;
  background-color: #ececec;
}

  .form_content {
  padding: 20px 0;
  border-bottom: 1px solid rgb(226, 226, 226);
}

  .form_content .text {
  display: flex;
  justify-content: space-between;
}

  .form_content .text h3 {
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  font-size: 16px;
}

  .form_content .text button {
  color: var(--noir);
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
}

  .form_content .modif_block p {
  color: var(--noir6);
  font-size: 14px;
  padding: 10px 0 0;
}

  .form_content .modif_block .input_box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  padding: 20px 0;
}

  .input_box input,
  .input_box select,
 .input_box_phone select,
 .input_box_phone input {
  box-sizing: border-box;
  padding: 0 15px;
  margin-top: 5px;
  width: 100%;
  height: 50px;
  font-size: 15px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

 .input_box input:focus,
 .input_box select:focus,
 .input_box_sexe select:focus,
 .input_box_sexe input:focus,
 .input_box_phone select:focus,
 .input_box_phone input:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

 .input_box_sexe select,
 .input_box_sexe {
  margin: 20px 0;
}

 .input_box_sexe select,
 .input_box_sexe input {
  box-sizing: border-box;
  padding: 0 15px;
  width: 100%;
  height: 50px;
  font-size: 15px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

 .input_box_phone {
  padding: 20px 0;
}

 .input_box_phone label {
  display: inline-block;
  margin-bottom: 8px;
  color: var(--noir3);
  font-size: 15px;
}

.seting_container .erreur {
  color: rgb(193, 53, 21);
  font-size: 14px;
}

 .footer_btn_block button {
  width: fit-content;
  text-align: center;
  border: none;
  outline: none;
  color: #fff;
  background: var(--btn_noir1);
  padding: 0 20px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

 .footer_btn_block button:hover {
  background: var(--btn_noir2);
}

 .footer_btn_block button:active {
  transform: scale(0.9);
}

 .footer_btn_block button.loading {
  padding: 8px 20px;
  height: 47px;
  width: 130px;
  background: rgb(226, 226, 226);
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  cursor: no-drop;
}

 .footer_btn_block button.loading:hover {
  background: rgb(226, 226, 226);
}

 .form_content.disable {
  cursor: no-drop;
}

 .form_content.disable .text h3 {
  color: rgb(226, 226, 226);
}

 .form_content.disable .text button {
  color: rgb(226, 226, 226);
  cursor: no-drop;
}

 .form_content.disable .modif_block p {
  color: rgb(226, 226, 226);
}

/*----------seting_box_2------*/
 .seting_box_2 {
  margin-top: 100px;
  width: 600px;
  max-width: 100%;
}

 .seting_box_2 h2 {
  color: var(--noir3);
  font-weight: var(--font_display_3_font-weight);
  font-size: 19px;
  padding: 15px 0;
}

 .seting_box_2 p {
  font-size: 15px;
  color: var(--noir6);
  line-height: 24px;
}

 .seting_box_2 p.p_1 {
  margin-bottom: 40px;
}

 .seting_box_2 a {
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
  color: var(--noir);
}

@media screen and (min-width: 1440px) {
  .seting_container {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

@media screen and (max-width: 1271px) {
  .seting_container {
    padding: 40px 50px 50px;
  }
}

@media screen and (max-width: 950px) {
  .seting_container {
    padding: 20px 24px 50px;
  }
}

@media screen and (max-width: 800px) {
  .seting_container h1 {
    font-size: 22px;
  }

  .seting_container .seting_box {
    display: block;
  }

  .AllAccount_setings {
    display: none;
  }

  .seting_container .modif_block .input_box {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
