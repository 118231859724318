.container h4 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  padding-bottom: 24px;
}

.payment_content {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  padding: 30px 0;
  margin-top: 20px;
}

.container .payment_content h4 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  padding-bottom: 10px;
}

.payment_content p {
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow-y: hidden;
}

.payment_content button {
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-top: 3px;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 743px) {
  .container h4,
  .container .payment_content h4 {
    font-size: 18px;
  }
}

/*---user_infos----*/

.user_infos {
  padding-top: 24px;
  margin-bottom: 30px;
}
.header .required {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}
.header .required h5 {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}
.header .required p {
  font-size: 14px;
  color: var(--noir3);
}
.header .button {
  padding: 0 15px;
  height: 35px;
  font-size: 15px;
  border-radius: 5px;
}

.container_modewrite {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container_modewrite h6 {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  padding-bottom: 10px;
}

.container_modewrite label {
  display: inline-block;
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.addphone_block {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.addphone_block p {
  font-size: 15px;
}
.required .error_message {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #c13515;
}

.required .error_message span {
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
  color: #c13515;
}

/*------message-----*/
.user_infos textarea {
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-top: 5px;
  font-size: 15px;
  border-radius: 8px;
  resize: vertical;
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
  -webkit-box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
  -moz-box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}
.user_infos textarea:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}
.user_infos textarea::placeholder {
  font-size: 14px;
}
.user_infos p.demande_special {
  padding: 10px 0;
  font-size: 14px;
  border-radius: 4px;
}
.erreur_input textarea {
  box-shadow: rgb(193, 53, 21) 0px 0px 0px 2px inset;
}
.erreur_input textarea:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}
.demande_block .error_message {
  display: none;
  align-items: center;
  gap: 5px;
  color: #c13515;
}
.erreur_input .error_message {
  display: flex;
}
.erreur_input .error_message span {
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
  color: #c13515;
}
/*----hote-profile-----*/
.profil_block {
  display: flex;
  align-items: center;
  gap: 15px;
}
.img_block {
  position: relative;
}
.profil_img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f1f1f1;
}
.profil_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profil_img h2 {
  color: var(--noir);
  font-size: 18px;
}

.about_name {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--noir);
}

.about_name h2 {
  font-size: 18px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.profil_block h6 {
  padding-bottom: 0 !important;
}
.profil_block p {
  font-size: 13px;
  color: var(--noir8);
}

.recap_form button.submit {
  position: relative;
  width: fit-content;
  border: none;
  outline: none;
  color: #fff;
  background: var(--button1);
  padding: 0 30px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  overflow: hidden;
  z-index: 2;
}
.recap_form button.submit::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--button2);
  z-index: -10;
  opacity: 0;
  transition: opacity 0.4s linear;
}
.recap_form button.submit:hover::before {
  opacity: 1;
}
.recap_form button.submit:active {
  transform: scale(0.9);
}
.recap_form button.submit.loader_btn {
  opacity: 0.4;
  cursor: no-drop;
}
.recap_form button.submit.loader_btn:hover {
  background: var(--button1);
}

@media screen and (max-width: 540px) {
  .recap_form .input_bloc {
    display: block;
  }
  .recap_form button.cotunue_button,
  .recap_form button.submit {
    width: 100%;
  }
}
