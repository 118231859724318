.container {
  padding: 30px 0 60px;
  min-height: 30dvh;
}

.container_wrapper h3 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 19px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.wrapper_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.box_image a {
  text-decoration: none;
}

.box_image .image {
  display: block;
  width: 100%;
  height: 180px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  background: #ddd;
}

.box_image .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.block_content {
  padding: 10px 0;
}

.descrip_detail p,
.block_content h5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.descrip_detail p {
  color: var(--noir4);
  font-size: 15px;
}

.block_content h5 {
  font-size: 16px;
  color: var(--noir);
}

@media screen and (max-width: 950px) {
  .wrapper_list {
    grid-template-columns: repeat(2, 1fr);
  }

  .box_image .image {
    height: 230px;
  }
}

@media screen and (max-width: 743px) {
  .wrapper_list {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .box_image {
    display: flex;
    gap: 15px;
  }

  .box_image .image {
    width: 200px;
    height: 130px;
  }

  .block_content {
    padding: 0;
  }

  .block_content h5 {
    -webkit-line-clamp: 2;
  }
}

@media screen and (max-width: 540px) {
  .box_image .image {
    border-radius: 8px;
    width: 130px;
    height: 90px;
  }
}
