/*------hebergement-----*/
.section {
  padding-bottom: 30px;
}

@media screen and (max-width: 700px) {
  .section {
    padding-bottom: 20px;
  }
}

.section h3 {
  font-size: 22px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  padding: 24px 0;
  line-height: 1.2;
}

.hebergement h4 {
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
}

.hebergement .dispositif {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px 0;
}

.hebergement p {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 100px;
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow-y: hidden !important;
}

.hebergement button {
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  text-decoration: underline;
  cursor: pointer;
}

.hebergement ul {
  padding: 10px 0;
  margin-left: 18px;
}

.hebergement ul li {
  padding: 5px 0;
}

@media screen and (max-width: 768px) {
  .hebergement .dispositif {
    grid-template-columns: 1fr;
    grid-gap: 24px 0;
  }

  .hebergement p {
    margin-right: 0;
    -webkit-line-clamp: 10 !important;
  }

  .hebergement .apropos {
    display: none;
  }
}

/*---modal_apropos-----*/
.modal_content {
  display: flex;
  flex-direction: column;
  width: 700px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.modal_content h3 {
  font-weight: var(--font_display_2_font-weight);
}

.modal_content .m_body {
  overflow-y: auto;
  padding: 10px 24px 30px;
}

.modal_content .m_body h4 {
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  margin-top: 20px;
}

.modal_content .m_body p {
  margin: 10px 0;
  font-size: 15px;
}

.modal_content .m_body ul {
  padding: 10px 0;
  margin-left: 18px;
}

.modal_content .m_body ul li {
  padding: 5px 0;
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }

  .modal_content .m_body::-webkit-scrollbar {
    display: none;
  }

  .modal_content h3 {
    padding-bottom: 5px;
  }
}
