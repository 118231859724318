/*-------mobile button verifier la disponibilite------*/
.bottom_bar {
  display: none;
}

@media screen and (max-width: 743px) {
  .price_normal {
    display: none;
  }

  .bottom_bar {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 8px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 24px;
    background: #fff;
    border-top: 1px solid #dcdfe4;
    z-index: 20;
    box-sizing: border-box;
  }

  .bottom_bar .price_book {
    display: flex;
    align-items: center;
  }

  .bottom_bar .head {
    display: flex;
    align-items: center;
  }

  .bottom_bar .promo {
    font-family: var(--font-family_heading);
    font-size: 15px;
    font-weight: var(--font_heading_1_font-weight);
    margin-right: 10px;
    color: #999;
    text-decoration: line-through;
  }

  .bottom_bar .head button {
    background: transparent;
    border: none;
    font-family: var(--font-family_heading);
    color: var(--noir);
    font-size: 16px;
    font-weight: var(--font_heading_1_font-weight);
    text-decoration: underline;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .bottom_bar .head button:active {
    color: #999;
    transform: scale(0.96);
  }

  .bottom_bar .head span {
    font-size: 15px;
    font-weight: var(--font_display_1_font-weight);
    margin-left: 5px;
  }

  .bottom_bar .price_normal {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .bottom_bar .price_normal h3 {
    font-family: var(--font-family_heading);
    font-size: 18px;
    font-weight: var(--font_heading_1_font-weight);
  }

  .bottom_bar button.nav_button,
  .bottom_bar a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    background: var(--button1);
    border: none;
    outline: none;
    color: #fff;
    overflow: hidden;
    z-index: 2;
    padding: 0 20px;
    height: 48px;
    font-size: 16px;
    font-weight: var(--font_display_2_font-weight);
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s;
  }

  .bottom_bar button.nav_button::before,
  .bottom_bar a::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--button2);
    z-index: -10;
    opacity: 0;
    transition: opacity 0.4s linear;
  }

  .bottom_bar button.nav_button:hover::before,
  .bottom_bar a:hover::before {
    opacity: 1;
  }

  .bottom_bar button.nav_button:active,
  .bottom_bar a:active {
    transform: scale(0.9);
  }
}

@media screen and (max-width: 360px) {
  .bottom_bar button.nav_button,
  .bottom_bar a {
    padding: 0 15px;
    font-size: 15px;
  }
}
