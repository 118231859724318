.header {
  padding-top: 100px;
}

.header h1 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
  padding-bottom: 30px;
  margin: 0 50px;
}

.header h1 a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: transparent;
  color: var(--noir);
  font-size: 19px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: -15px;
}

.header h1 a:hover {
  background: var(--gray);
  cursor: pointer;
}

.block_link_search {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 0 50px;
  margin-bottom: 30px;
}

.link_reservation {
  display: flex;
  gap: 30px;
  border-bottom: 1px solid #ccc;
}

.link_reservation a {
  position: relative;
  text-decoration: none;
  font-size: 15px;
  color: var(--noir8);
  font-weight: var(--font_display_2_font-weight);
  padding-bottom: 10px;
}

.link_reservation a.active {
  color: var(--noir);
}

.link_reservation a.active::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: var(--noir);
}

@media screen and (max-width: 955px) {
  .header h1 {
    margin: 0 24px;
  }

  .block_link_search {
    padding: 0 24px;
  }
}

@media screen and (max-width: 831px) {
  .block_link_search {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }

  .link_reservation {
    overflow-x: auto;
    white-space: nowrap;
    margin: 0 -24px;
    padding: 0 24px;
  }
}

@media screen and (max-width: 743px) {
  .header {
    padding-top: 80px;
  }

  .link_reservation::-webkit-scrollbar {
    display: none;
  }

  .search_block {
    margin-top: 10px;
  }

  .block_link_search input {
    width: 100%;
  }
}
