.auto_pc .city_mobile {
  width: 100%;
  height: 50px;
  min-height: 1px;
  padding: 0 15px;
  border-radius: 30px;
  background: rgb(247, 247, 247);
  border: 1px solid rgb(233, 233, 233);
  font-size: 15px;
  color: var(--noir);
  outline: none;
  display: none;
  align-items: center;
  text-align: left;
}

.auto_pc .city_mobile span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.auto_complete_pc {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 15px;
  border-radius: 30px;
  background: rgb(247, 247, 247);
  border: 1px solid rgb(233, 233, 233);
  overflow: hidden;
  cursor: pointer;
}

.auto_complete_pc:hover {
  background: rgb(223, 223, 223);
}

.city.active .auto_complete_pc {
  cursor: default;
}

.city.active .auto_complete_pc {
  background: #fff;
  box-shadow: rgb(0 0 0 / 30%) 0px 2px 16px;
}

.auto_pc .city_mobile:hover,
.city.active .auto_complete_pc:hover {
  background: #fff;
}

.left_icon {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  margin-right: 10px;
  height: 100%;
  color: rgb(34, 34, 34);
}

.left_icon svg.maps {
  display: block;
  height: 16px;
  width: 16px;
  fill: var(--noirc);
}

.auto_complete_pc input.selecte_pc {
  white-space: nowrap;
  font-size: 15px;
  width: 100%;
  height: 100%;
  background: transparent;
  color: var(--noir);
  border: none;
  outline: none;
  overflow: hidden;
}

.auto_complete_pc input.selecte_pc::placeholder {
  color: var(--noir);
  font-size: 15px;
}

.city.active .auto_complete_pc input.selecte_pc {
  background: #fff;
}

.auto_complete_pc button.button_content {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(221, 221, 221);
  border-radius: 50%;
  border: none;
  outline: none;
  color: rgb(34, 34, 34);
  cursor: pointer;
  touch-action: manipulation;
  transition: -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s;
}

.auto_complete_pc svg.close_svg {
  display: block;
  fill: none;
  height: 12px;
  width: 12px;
  stroke: currentcolor;
  stroke-width: 5.33333;
  overflow: visible;
}

.city {
  position: relative;
}

.cite_search {
  position: absolute;
  z-index: 2010;
  top: 65px;
  left: 0px;
  width: 500px;
  max-height: 57vh;
  overflow-y: auto;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 30px;
  box-shadow: rgb(0 0 0 / 20%) 0px 6px 20px;
  background: #fff;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  transition: 0.1s ease-in-out;
}

.city.active .cite_search {
  visibility: visible;
  opacity: 1;
}

.cite_search p.notice {
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  padding: 0 30px 24px;
}

.suggest_container {
  position: relative;
  width: 100%;
}

.suggest_container .suggest_load {
  position: absolute;
  left: 50%;
  top: -10px;
  transform: translateX(-50%);
}

.auto_mobile {
  display: none;
  padding: 0 30px 24px;
}

.auto_complete_mobile {
  position: relative;
  padding: 0 20px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  border-radius: 12px;
  background: rgb(247, 247, 247);
  color: rgb(113, 113, 113);
  overflow: hidden;
}

.auto_complete_mobile svg.search {
  display: block;
  fill: none;
  height: 16px;
  width: 16px;
  stroke: currentcolor;
  stroke-width: 4;
  overflow: visible;
}

.auto_complete_mobile input.selecte_mobile {
  white-space: nowrap;
  font-size: 15px;
  width: 100%;
  height: 100%;
  background: rgb(247, 247, 247);
  color: rgb(113, 113, 113);
  border: none;
  outline: none;
  overflow: hidden;
}

.auto_complete_mobile input.selecte_mobile::placeholder {
  color: rgb(113, 113, 113);
  font-size: 15px;
}

.auto_complete_mobile button.button_content {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(221, 221, 221);
  border-radius: 50%;
  border: none;
  outline: none;
  color: rgb(34, 34, 34);
  cursor: pointer;
  touch-action: manipulation;
  transition: -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s;
}

.auto_complete_mobile svg.close_svg {
  display: block;
  fill: none;
  height: 12px;
  width: 12px;
  stroke: currentcolor;
  stroke-width: 5.33333;
  overflow: visible;
}

.cite_search button.name {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  padding: 7px 0 7px 30px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

.cite_search button.name:hover {
  background-color: rgb(247, 247, 247);
}

.cite_search button.name.active {
  background-color: rgb(247, 247, 247);
}

.cite_search button .block_icon {
  padding: 8px;
  color: var(--noir5);
  background-color: rgb(241, 241, 241);
  border: 1px solid rgba(176, 176, 176, 0.2);
  border-radius: 5px;
}

.cite_search button p {
  font-size: 15px;
  text-align: left;
}

@media screen and (max-width: 1018px) {
  .cite_search {
    top: 55px;
    left: 0;
    right: 0px;
    width: auto;
  }
}

@media screen and (max-width: 743px) {
  .auto_pc .auto_complete_pc {
    display: none;
  }

  .auto_pc .city_mobile {
    display: flex;
  }

  .cite_search::-webkit-scrollbar {
    display: none;
  }

  .citeModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100dvh;
    background: var(--gray);
    z-index: 2010;
    display: none;
  }

  .city.active .citeModal {
    display: block;
  }

  .cite_search {
    top: 10%;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    border-radius: 24px 24px 0 0;
    padding: 30px 0 100px;
    max-height: 100vh;
    overflow-y: auto;
    visibility: visible;
    opacity: 1;
  }

  .cite_search p.notice {
    padding: 0 24px 24px;
  }

  .auto_mobile {
    display: block;
    padding: 0 24px 24px;
  }

  .cite_search button.name {
    padding: 7px 24px;
  }

  .cite_search button.name:hover {
    background-color: transparent;
  }
}
