.home_head {
  position: relative;
  height: 100vh;
  background: radial-gradient(circle at 85% 33%, rgba(0, 255, 235, 0.085), hsla(0, 0%, 100%, 0) 25%),
    radial-gradient(circle at 12% 55%, rgba(108, 99, 255, 0.1), hsla(0, 0%, 100%, 0) 25%);
  width: 100%;
}

.home_nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 115px;
  height: 80px;
  z-index: 100;
}

.home_nav.active {
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(12px);
}

.home_nav .home_logo a {
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.hero_section {
  display: grid;
  grid-template-columns: 1fr 400px;
  align-items: center;
  height: 100%;
  gap: 50px;
  padding: 0 50px;
  max-width: 1200px;
  margin: 0 auto;
}

.wrapper_img_profile {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.wrapper_img_profile .image_block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #ddd;
  border: 0;
  outline: none;
  overflow: hidden;
}

.wrapper_img_profile .image_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper_name h2 {
  font-size: 16px;
  color: var(--noir);
}

.wrapper_name p {
  font-size: 18px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.hero_section h1 {
  color: var(--noir);
  font-size: 50px;
  font-family: var(--font-family_heading);
  font-weight: var(--font_heading_2_font-weight);
}

.title_block p {
  margin-top: 10px;
  font-size: 20px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--color-notif);
}

.title_block p strong {
  font-size: 20px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
}

.title_block p span {
  background-color: rgba(0, 255, 200, 0.534);
  color: #333;
  font-weight: var(--font_display_2_font-weight);
  font-size: 16px;
  cursor: pointer;
}

.title_block p span:hover {
  text-decoration: underline;
}

.form_block {
  padding: 25px;
  border-radius: 15px;
}

.form_block .form_head {
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  padding-bottom: 30px;
}

.form_head h2 {
  font-size: 20px;
  font-family: var(--font-family_heading);
  font-weight: var(--font_heading_1_font-weight);
}

.form_head p {
  font-size: 16px;
}

.form_block a,
.form_block button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
  border: none;
  outline: none;
  color: #fff;
  background: var(--button1);
  overflow: hidden;
  z-index: 2;
  padding: 0 20px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.form_block a::before,
.form_block button::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--button2);
  z-index: -10;
  opacity: 0;
  transition: opacity 0.4s linear;
}

.form_block a:hover::before,
.form_block button:hover::before {
  opacity: 1;
}

.form_block a:active,
.form_block button:active {
  transform: scale(0.9);
}

.condition_text {
  margin-top: 10px;
  font-size: 11px;
}

.condition_text p {
  font-size: 11px;
}

@media screen and (max-width: 1228px) {
  .hero_section h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .home_nav {
    padding: 0 50px;
  }

  .hero_section {
    padding: 90px 50px 0;
  }
}

@media screen and (max-width: 912px) {
  .home_nav {
    padding: 0 24px;
  }

  .hero_section {
    display: flex;
  }

  .title_block {
    display: flex;
    flex-direction: column;
  }

  .title_block p {
    font-size: 18px;
  }

  .form_block {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px 50px;
    border-radius: 15px 15px 0 0;
    background: #fff;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px;
    z-index: 100;
  }

  .form_block .form_head {
    padding-bottom: 10px;
  }

  .form_block h2 {
    font-size: 16px;
  }

  .form_head p {
    display: none;
  }
}

@media screen and (max-width: 743px) {
  .home_head {
    height: 67vh;
  }
  .hero_section {
    padding: 20px 24px 0;
  }

  .form_block {
    padding: 16px 24px;
  }
}

@media screen and (max-width: 540px) {
  .home_nav {
    height: 70px;
  }

  .home_nav.active {
    box-shadow: none;
  }

  .hero_section h1 {
    font-size: 28px;
  }
}
