.wrapper_img_profile {
  position: relative;
  width: 140px;
  height: 140px;
}

.wrapper_img_profile .image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #ddd;
  overflow: hidden;
}

.wrapper_img_profile .image svg {
  display: block;
  height: 100%;
  width: 100%;
  fill: #999;
  background: #fff;
  stroke-width: 3;
}

.wrapper_img_profile .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn_update {
  position: absolute;
  right: 0;
  bottom: 0;
}

.btn_update input {
  display: none;
}

.btn_update label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;
  color: var(--noir);
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
    rgb(0 0 0 / 12%) 0px 6px 16px;
  cursor: pointer;
}

.btn_update label svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: currentcolor;
}

@media screen and (max-width: 743px) {
  .wrapper_img_profile {
    width: 80px;
    height: 80px;
  }
  .btn_update label {
    width: 24px;
    height: 24px;
  }

  .btn_update label svg {
    height: 12px;
    width: 12px;
  }
}
