.welcom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.welcom h1 {
  font-weight: var(--font_display_2_font-weight);
}

.welcom a {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 10px;
  color: var(--noir);
  text-decoration: none;
  border: none;
  background: var(--tertiary-background);
  border-radius: 8px;
}

.welcom a svg {
  display: block;
  fill: none;
  height: 16px;
  width: 16px;
  stroke: currentcolor;
  stroke-width: 3;
  overflow: visible;
}

.welcom a p {
  font-weight: var(--font_display_2_font-weight);
  font-size: 13px;
}

.welcom a:hover {
  background-color: var(--tertiary-background-hover);
}

.welcom a:active {
  transition: 0.2s;
  transform: scale(0.9);
}

@media screen and (max-width: 551px) {
  .welcom {
    align-items: flex-start;
    flex-direction: column;
  }
}
