/*---modal_savoir-----*/
.modal_content {
  display: flex;
  flex-direction: column;
  width: 700px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.modal_content .m_body {
  overflow: auto;
  padding: 0 24px 50px;
}

.modal_content .m_body h2 {
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  padding: 15px 0 7px;
}

.asavoir .block {
  display: flex;
  align-items: center;
  gap: 8px;
}

.asavoir p {
  padding: 5px 0;
  font-size: 16px;
}

p.no {
  text-decoration: line-through;
}

.asavoir p i {
  margin-right: 15px;
}

.modal_content .infos {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
}

.modal_content .infos p {
  font-size: 16px;
}

.asavoir a {
  color: var(--noir);
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }

  .modal_content .m_body::-webkit-scrollbar {
    display: none;
  }

  .asavoir p {
    font-size: 15px;
  }

  .modal_content .infos p {
    font-size: 15px;
  }
}
