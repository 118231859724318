.container {
  width: 310px;
  border-radius: 16px;
  border: 1px solid var(--border-color);
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
  overflow: hidden;
}

.container .photo_block {
  height: 100%;
}

.container .photo {
  width: 100%;
  height: 200px;
  background-color: var(--gray);
}

.container .photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: middle;
}

.content {
  padding: 20px;
}

.content h4 {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.avis {
  display: flex;
  align-items: center;
  gap: 4px;
}

.avis p {
  font-weight: var(--font_display_2_font-weight);
  font-size: 14px;
}

.avis span {
  display: inline-block;
  font-size: 13px;
  color: var(--noir4);
}

.content p {
  color: var(--noir6);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

@media screen and (max-width: 743px) {
  .container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 110px;
    border-radius: 8px;
  }

  .container .photo {
    width: 90px;
    height: 100%;
  }

  .content {
    padding: 15px;
  }
}
