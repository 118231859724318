/* Modal Overlay */
.modal_overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3000;
  animation-duration: 600ms;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-name: modal_Overlay_SlideOut;
}

.modal.active .modal_overlay {
  animation-name: modal_Overlay_SlideIn;
}

.container {
  position: fixed;
  inset: 0;
  bottom: 0;
  width: 100%;
  height: 100dvh;
  z-index: 4000;
  animation-duration: 400ms;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-name: modal_Overlay_SlideOut;
}

.modal.active .container {
  animation-name: modal_Overlay_SlideIn;
}

/* Animation Keyframes modal_Overlay_SlideIn*/
@keyframes modal_Overlay_SlideIn {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes modal_Overlay_SlideOut {
  to {
    visibility: hidden;
    opacity: 0;
  }
}
