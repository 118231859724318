.block_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.block_flex h3 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}
.block_flex .occup_count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
}
.block_flex button {
  width: 50px;
  height: 50px;
  cursor: pointer;
  font-size: 19px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #999;
  color: var(--noir5);
  outline: none;
  touch-action: manipulation;
  background: rgb(255, 255, 255);
  border-radius: 50%;
}
.block_flex button:hover {
  border: 1px solid #333;
  color: var(--noir);
}
.block_flex button.disable {
  cursor: not-allowed;
  color: rgb(235, 235, 235);
  border-color: rgb(235, 235, 235);
  background: rgb(255, 255, 255);
}
.block_flex button.disable:hover {
  border: 1px solid #f1f1f1;
  color: #ddd;
  cursor: no-drop;
}
.occup_count .inputs {
  flex: 1;
}
.occup_count input {
  width: 100%;
  height: 100px;
  border-radius: 8px;
  padding: 10px;
  font-size: 48px;
  font-weight: var(--font_display_2_font-weight);
  line-height: 80px;
  appearance: none;
  text-align: center;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}
.occup_count input:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}
.occup_count input[type="number"]::-webkit-outer-spin-button,
.occup_count input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.occup_count .inputs.error input {
  box-shadow: rgb(193, 53, 21) 0px 0px 0px 1px inset;
}
.occup_count .inputs.error input:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}
.price {
  text-align: center;
  padding: 15px 0;
}
.price h2 {
  font-size: 16px;
}
.price p {
  font-size: 15px;
  padding-top: 10px;
  color: rgb(193, 53, 21);
}
@media screen and (max-width: 743px) {
  .block_flex .occup_count {
    gap: 15px;
  }
  .occup_count input {
    height: 60px;
    font-size: 32px;
    line-height: 38px;
  }
}
