.hotel_activite {
  padding: 15px 0;
}

.hotel_activite:not(:last-child) {
  background: #fff;
  border-bottom: 1px solid var(--border-color);
}

.hotel_activite a {
  display: block;
  text-decoration: none;
  z-index: 2;
}

.hotel_image {
  display: grid;
  grid-template-columns: 300px 1fr;
  justify-content: space-between;
}

.block_photo_result {
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  background: hsl(180, 4%, 90%);
}

.hotel_image .imgslider {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  vertical-align: bottom;
}

.prev,
.next {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.959);
  top: 45%;
  z-index: 20;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  opacity: 0;
}

.prev svg,
.next svg {
  display: block;
  fill: none;
  height: 12px;
  width: 12px;
  stroke: currentcolor;
  stroke-width: 4;
  overflow: visible;
}

.hotel_image:hover .prev,
.hotel_image:hover .next {
  opacity: 1;
}

.hotel_image:hover .prev.disable,
.hotel_image:hover .next.disable {
  opacity: 0;
}

.prev:hover,
.next:hover {
  background: #fff;
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
    rgb(0 0 0 / 12%) 0px 6px 16px;
  transform: scale(1.04);
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.prev.disable,
.next.disable {
  opacity: 0;
}

.container {
  position: relative;
}

.container .infos {
  position: absolute;
  left: -5px;
  top: 10px;
  color: var(--noir5);
  padding: 3px 10px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  font-weight: var(--font_display_3_font-weight);
  border-radius: 4px;
  background: #fff;
  border: 1px solid #dcdfe4;
  max-width: 150px;
  z-index: 20;
}

.use_fav_button {
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 20;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 50%;
  background-color: transparent;
}

.use_fav_button:active {
  transition: 0.3s;
  transform: scale(0.9);
}

.hotel_descrip {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding-left: 15px;
}

.hotel_descrip .hotel_header {
  display: grid;
  grid-template-columns: 1fr 180px;
  grid-gap: 10px;
  padding-bottom: 5px;
}

.hotel_header .type h3 {
  font-size: 14px;
  color: var(--noir6);
  font-weight: var(--font_display_1_font-weight);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.hotel_header .hotel_evaluation {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.hotel_header .hotel_evaluation .block_eval {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--noir);
}

.hotel_header .hotel_evaluation p {
  font-size: 14px;
}

.hotel_descrip h4.name {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: var(--font_display_1_font-weight);
  color: var(--noir);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.container_desc {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.hotel_descrip .text_descrip {
  font-size: 14px;
  font-weight: var(--font_display_1_font-weight);
  color: var(--noir6);
  padding: 2px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.equipement {
  display: flex;
  gap: 5px;
  padding: 10px 0;
}

.equipement span {
  display: inline-block;
  font-size: 12px;
  border: 1px solid var(--noirc);
  color: var(--noir6);
  padding: 1px 5px;
  border-radius: 3px;
}

.descrip_detail {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
}

.descrip_detail p {
  color: var(--noir6);
  font-size: 14px;
}

.descrip_detail span {
  display: inline-block;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: var(--noir6);
}

.descrip_droite .prix_bloc {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 200px;
  height: 100%;
  text-align: right;
  text-align: bottom;
  font-size: 14px;
}

.descrip_droite .prix_bloc p {
  font-family: var(--font-family_heading);
  color: #000;
}

.descrip_droite p.prix_barer {
  font-family: var(--font-family_heading);
  color: #999;
  font-size: 14px;
  font-weight: var(--font_heading_1_font-weight);
  text-decoration: line-through;
}

.descrip_droite p.prix_barer.mobile {
  display: none;
}

.descrip_droite .descrip_prix {
  font-weight: var(--font_heading_1_font-weight);
  font-size: 17px;
  color: var(--noir);
}

.descrip_droite p.descrip_prix span {
  display: inline-block;
}

.total_content {
  position: relative;
}

.total_content p.total {
  display: block;
  text-decoration: underline;
}

.decompte_price {
  position: absolute;
  text-align: left;
  bottom: 25px;
  background: rgb(255, 255, 255);
  width: 350px;
  height: auto;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 17%) 0px 2px 16px;
  visibility: hidden;
  transform: scale(0);
  opacity: 0;
  transition: 0.3s ease-in-out;
  transform-origin: left bottom;
  z-index: 5;
}

.decompte_price h2 {
  color: var(--noir);
  text-align: center;
  font-weight: var(--font_display_2_font-weight);
  font-size: 16px;
  padding: 16px 24px;
  border-bottom: 1px solid #dcdfe4;
}

.decompte_price .dec_container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.decompte_price .dec_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.decompte_price .dec_block p.reduction {
  color: green;
  font-weight: var(--font_display_2_font-weight);
}

.total_content:hover .decompte_price {
  visibility: visible;
  transform: scale(1);
  opacity: 1;
  cursor: default;
}

@media screen and (max-width: 1150px) {
  .decompte_price {
    right: 0;
    transform-origin: right bottom;
  }
}

@media screen and (max-width: 800px) {
  .hotel_activite {
    padding: 15px 0 0;
  }

  .hotel_activite:not(:last-child) {
    overflow: hidden;
    border: 0;
  }

  .hotel_image {
    display: block;
  }

  .block_photo_result .imgslider {
    height: 400px;
  }

  .hotel_image:hover .prev {
    display: none;
  }

  .hotel_image:hover .next {
    display: none;
  }

  .hotel_descrip {
    padding: 10px 0px 5px;
  }

  .hotel_descrip .hotel_header {
    grid-template-columns: 1fr;
    grid-gap: 1px;
    padding-bottom: 0;
  }

  .hotel_header .hotel_evaluation {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .hotel_descrip .name {
    font-size: 15px;
  }

  .hotel_descrip .text_descrip {
    display: none;
  }

  .equipement {
    display: none;
  }

  .container_desc {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 5px;
  }

  .container_desc .descrip_detail {
    width: 100%;
    text-align: left;
  }

  .descrip_droite .prix_bloc {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: right;
    gap: 0px 8px;
  }

  .descrip_droite p.prix_barer {
    display: none;
  }

  .descrip_droite p.prix_barer.mobile {
    display: block;
    font-size: 15px;
  }

  .descrip_droite p.descrip_prix {
    font-size: 15px;
  }

  .total_content p.total {
    text-decoration: none;
  }

  .decompte_price {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .block_photo_result .imgslider {
    height: 350px;
  }

  .hotel_descrip h4.name {
    font-size: 15px;
    font-weight: var(--font_display_2_font-weight);
  }
}

@media screen and (max-width: 414px) {
  .block_photo_result .imgslider {
    height: 250px;
  }
}

@media screen and (max-width: 370px) {
  .descrip_detail {
    flex-wrap: wrap;
    gap: 0 5px;
  }
}
