.container {
  position: relative;
  margin-top: 100px;
  padding: 50px 50px;
  background-image: url("../../assets/profile/nuit.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container::before {
  display: block;
  content: "";
  position: absolute;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.9) 80%,
    rgb(0, 0, 0)
  );
  top: 0;
  left: 0;
  bottom: 0;
  width: 55%;
  z-index: 0;
}

.container::after {
  display: block;
  content: "";
  position: absolute;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.9) 80%,
    rgb(0, 0, 0)
  );
  top: 0;
  right: 0;
  bottom: 0;
  width: 55%;
  z-index: 0;
}

.container h2 {
  margin-bottom: 40px;
  font-size: 33px;
  font-weight: var(--font_display_2_font-weight);
  letter-spacing: -0.02em;
  color: #fff;
}

.content {
  z-index: 1;
}

.poisition {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}

.block {
  background-color: rgba(112, 112, 112, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 15px;
}

.block svg {
  display: block;
  height: 32px;
  width: 32px;
  fill: #fff;
  stroke: currentColor;
}

.block h3 {
  font-size: 18px;
  color: #fff;
  margin: 10px 0;
  font-weight: var(--font_display_2_font-weight);
}

.block p {
  color: rgb(226, 226, 226);
}

@media screen and (min-width: 1440px) {
  .content {
    max-width: 1280px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    padding: 50px;
    height: auto;
  }
}

@media screen and (max-width: 964px) {
  .container {
    padding: 50px 100px;
  }

  .poisition {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}

@media screen and (max-width: 743px) {
  .container {
    background-position: center;
    margin-top: 50px;
    padding: 50px 24px;
  }

  .container h2 {
    font-size: 25px;
  }
}

@media screen and (max-width: 650px) {
  .block h3 {
    font-size: 17px;
  }
}
