.container h3 {
  font-size: 35px;
  font-weight: var(--font_heading_1_font-weight);
  font-family: var(--font-family_heading);
  margin-bottom: 35px;
  color: var(--noir);
}

.container_block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
}

.block_content h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  color: var(--noir);
  border: 2px solid var(--noir);
  font-size: 16px;
  font-weight: var(--font_heading_1_font-weight);
  font-family: var(--font-family_heading);
}

.block_content h5 {
  font-size: 20px;
  font-weight: var(--font_display_3_font-weight);
  margin: 15px 0;
}

@media screen and (max-width: 950px) {
  .container_block {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
}

@media screen and (max-width: 766px) {
  .block_content p {
    font-size: 15px;
  }
}

@media screen and (max-width: 540px) {
  .container h3 {
    font-size: 25px;
    margin-bottom: 30px;
  }
}
