.navigation {
  display: flex;
  flex-direction: column;
}

.block_link_search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.link_reservation {
  display: flex;
  gap: 30px;
  border-bottom: 1px solid #ccc;
}

.link_reservation a {
  position: relative;
  text-decoration: none;
  font-size: 16px;
  color: var(--noir7);
  font-weight: var(--font_display_2_font-weight);
  padding-bottom: 15px;
}

.link_reservation a.active {
  color: var(--noir);
}

.link_reservation a.active::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: var(--noir);
}

.block_link_search .search {
  position: relative;
  color: var(--noirc);
}

.block_link_search .search svg {
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 18px;
}

.block_link_search .search input {
  box-sizing: border-box;
  padding: 0 15px;
  width: 280px;
  height: 40px;
  font-size: 15px;
  border-radius: 8px;
  border: none;
  outline: none;
  color: var(--noir);
  background-clip: padding-box;
  background-color: var(--gray);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.block_link_search .search input:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

@media screen and (max-width: 900px) {
  .block_link_search {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 863px) {
  .block_link_search {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .link_reservation {
    overflow-x: auto;
    white-space: nowrap;
    margin: 0 -24px;
    padding: 0 24px;
    border: none;
  }

  .link_reservation::-webkit-scrollbar {
    display: none;
  }

  .block_link_search .search {
    margin-top: 10px;
  }

  .block_link_search .search input {
    width: 100%;
  }
}

@media screen and (max-width: 390px) {
  .link_reservation {
    gap: 20px;
  }
}

@media screen and (max-width: 360px) {
  .link_reservation {
    gap: 15px;
  }
}
