.button {
  border: none;
  background: var(--tertiary-background);
  color: var(--noir);
  padding: 0 25px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s;
}

.button:hover {
  background: var(--tertiary-background-hover);
}

.button:active {
  transform: scale(0.9);
}

@media screen and (max-width: 743px) {
  .button:hover {
    background: var(--tertiary-background);
  }
}
