/*--------modal-Snackbar--pc-----*/
.modal_Snackbar {
  position: fixed;
  display: flex;
  align-items: center;
  width: max-content;
  gap: 5px;
  bottom: 30px;
  left: 25px;
  padding: 13px 24px;
  box-shadow: rgb(0 0 0 / 20%) 0px 6px 20px;
  border-radius: 12px;
  border: 1px solid rgb(221, 221, 221);
  background-color: rgb(255, 255, 255);
  z-index: 2000;
  transform: translateY(250px);
  opacity: 0;
  transition: 0.9s all ease-in-out;
}

.modal_Snackbar.active {
  transform: none;
  opacity: 1;
}

.modal_Snackbar svg.add_favori {
  cursor: pointer;
  display: block;
  height: 20px;
  width: 20px;
  stroke-width: 2;
  overflow: visible;
  fill: #ff385c;
  stroke: #fff;
}

.modal_Snackbar p {
  font-size: 15px;
}

@media screen and (max-width: 700px) {
  .modal_Snackbar {
    width: max-content;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%, 250px);
  }

  .modal_Snackbar.active {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}