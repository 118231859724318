.modal_content {
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid var(--border-color);
}

.m_head h3 {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.m_body {
  padding: 20px 24px 50px;
  overflow-y: auto;
}

.m_body p.notice {
  font-size: 13px;
}

.block_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.m_body .block_flex:not(:last-child) {
  border-bottom: 1px solid rgb(235, 235, 235);
}

.block_flex h3 {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.block_flex p {
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir6);
}

.block_flex .occup_count {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  text-align: center;
  gap: 5px;
}

.block_flex button {
  width: 32px;
  height: 32px;
  cursor: pointer;
  font-size: 19px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #999;
  color: var(--noir5);
  outline: none;
  touch-action: manipulation;
  background: rgb(255, 255, 255);
  border-radius: 50%;
}

.block_flex button:hover {
  border: 1px solid #333;
  color: var(--noir);
}

.block_flex button.disable {
  cursor: not-allowed;
  color: rgb(235, 235, 235);
  border-color: rgb(235, 235, 235);
  background: rgb(255, 255, 255);
}

.block_flex button.disable:hover {
  border: 1px solid #f1f1f1;
  color: #ddd;
  cursor: no-drop;
}

.btn_action {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: var(--modal-footer-background-color);
}

.btn_action button {
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  cursor: pointer;
  height: 48px;
  border-radius: 8px;
  transition: 0.3s;
}

.btn_action button:nth-child(1) {
  background: transparent;
  color: var(--noir);
  text-decoration: underline;
  padding: 0 10px;
  margin-left: -10px;
}

.btn_action button:nth-child(1):hover {
  background-color: var(--gray-hover);
}

.btn_action button:nth-child(2) {
  background: var(--btn_noir1);
  color: #fff;
  padding: 0 24px;
}

.btn_action button:nth-child(2):hover {
  background: var(--btn_noir2);
}

.btn_action button:active {
  transform: scale(0.9);
}

.btn_action button.loading {
  border: none;
  outline: none;
  background: rgb(226, 226, 226);
  padding: 0 25px;
  width: 110px;
  height: 48px;
  border-radius: 8px;
  color: #fff;
  cursor: no-drop;
  transition: 0.3s;
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }
}
