.voyageur {
  position: relative;
}

.voyageur .infos {
  width: 100%;
  height: 50px;
  padding: 0 15px;
  border-radius: 30px;
  background: rgb(247, 247, 247);
  border: 1px solid rgb(233, 233, 233);
  overflow: hidden;
  cursor: pointer;
}

.voyageur .infos:hover {
  background: rgb(223, 223, 223);
}

.voyageur.active .infos {
  background: #fff;
  box-shadow: rgb(0 0 0 / 30%) 0px 2px 16px;
}

.voyageur.active .infos:hover {
  background: #fff;
}

/*-----info voyageurs----*/

.voyageur .infos {
  display: flex;
  align-items: center;
  gap: 10px;
}

.voyageur .infos_icon {
  display: flex;
  align-items: center;
  color: var(--noirc);
}

.voyageur .infos p {
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

@media screen and (max-width: 375px) {
  .voyageur .infos {
    padding: 0 10px;
  }
}

/*------------info modal---------*/
.info_search {
  position: absolute;
  z-index: 10;
  top: 65px;
  right: 0px;
  width: 400px;
  max-height: 70vh;
  overflow-y: auto;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 30px;
  box-shadow: rgb(0 0 0 / 20%) 0px 6px 20px;
  background: #fff;
  padding: 30px 30px 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  visibility: hidden;
  opacity: 0;
  transition: 0.1s ease-in-out;
}

.voyageur.active .info_search {
  visibility: visible;
  opacity: 1;
}

.info_search h2 {
  font-size: 17px;
  color: var(--noir);
  text-align: center;
  font-weight: var(--font_display_2_font-weight);
  padding-bottom: 10px;
  display: none;
}

.info_search .block_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.m_body .block_flex:not(:last-child) {
  border-bottom: 1px solid rgb(235, 235, 235);
}

.info_search .block_flex h3 {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.info_search .block_flex p {
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir6);
}

.info_search .block_flex .occup_count {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  text-align: center;
  gap: 5px;
}

.info_search .block_flex button {
  width: 32px;
  height: 32px;
  cursor: pointer;
  font-size: 19px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #999;
  color: var(--noir5);
  outline: none;
  touch-action: manipulation;
  background: rgb(255, 255, 255);
  border-radius: 50%;
}

.info_search .block_flex button:hover {
  border: 1px solid #333;
  color: var(--noir);
}

.block_flex button.disable {
  cursor: not-allowed;
  color: rgb(235, 235, 235);
  border-color: rgb(235, 235, 235);
  background: rgb(255, 255, 255);
}

.block_flex button.disable:hover {
  border: 1px solid #f1f1f1;
  color: #ddd;
  cursor: no-drop;
}

.info_search .m_footer {
  display: flex;
  justify-content: flex-end;
}

.info_search .m_footer button:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--noir);
  background: transparent;
  text-decoration: underline;
  height: 48px;
  padding: 0 8px;
  margin-left: -8px;
  border-radius: 8px;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  cursor: pointer;
}

.info_search .m_footer button:nth-child(1):hover {
  background: var(--gray);
}

.info_search .m_footer button:nth-child(2):active,
.info_search .m_footer button:nth-child(2):active {
  transition: 0.3s ease-in-out;
  transform: scale(0.9);
}

.info_search .m_footer button:nth-child(2) {
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  border: none;
  outline: none;
  background: var(--button1);
  height: 48px;
  padding: 0 25px;
  border-radius: 8px;
  overflow: hidden;
  z-index: 2;
  cursor: pointer;
  transition: 0.3s;
}

.info_search .m_footer button:nth-child(2) span {
  display: inline-block;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: currentColor;
}

.info_search .m_footer button:nth-child(2)::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--button2);
  z-index: -10;
  opacity: 0;
  transition: opacity 0.4s linear;
}

@media screen and (max-width: 953px) {
  .info_search {
    top: 55px;
    left: 0;
    right: 0;
    width: auto;
  }
}

@media screen and (max-width: 743px) {
  .voyageur .infos:hover {
    background: #fff;
  }

  .voyageur.active .infos {
    background: rgb(247, 247, 247);
    box-shadow: none;
  }

  .info_search::-webkit-scrollbar {
    display: none;
  }

  .infoModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vhw;
    background: var(--gray);
    z-index: 999;
    display: none;
  }

  .voyageur.active .infoModal {
    display: block;
  }

  .info_search {
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    border-radius: 24px 24px 0 0;
    padding: 30px 24px 100px;
    max-height: 90%;
    overflow-y: auto;
    visibility: visible;
    opacity: 1;
  }

  .info_search h2 {
    display: block;
  }

  .m_body {
    margin-bottom: 30px;
  }

  .info_search .m_footer {
    position: fixed;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 24px;
    background-color: var(--modal-footer-background-color);
  }

  .info_search .m_footer button:nth-child(2) {
    display: flex;
  }
}
