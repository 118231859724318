.header .block_button {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 50px;
}

.header .block_button h4 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 18px;
}

.header .block_button input {
  display: none;
}

.header label {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  background-color: transparent;
  border: 1px solid var(--noir);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.header label:hover {
  background-color: var(--gray);
}

.header label:active {
  transform: scale(0.9);
}

.header label svg {
  display: block;
  height: 24px;
  width: 24px;
  fill: currentcolor;
}

@media screen and (max-width: 1010px) {
  .header .block_button {
    padding: 10px 24px;
  }
}

@media screen and (max-width: 743px) {
  .header .block_button {
    flex-direction: column;
  }
}
