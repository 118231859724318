.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 90px;
}

.block_left h3 {
  font-size: 35px;
  font-weight: var(--font_heading_1_font-weight);
  font-family: var(--font-family_heading);
  padding-bottom: 10px;
  color: var(--noir);
}

.accordion_block:not(:first-child) {
  border-top: 1px solid var(--border-color);
}

@media screen and (max-width: 950px) {
  .container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 540px) {
  .block_left h3 {
    font-size: 25px;
    padding-bottom: 30px;
  }
}
