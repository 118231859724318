.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.wrapper_img_profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.wrapper_img_profile .image_block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #ddd;
  border: 0;
  outline: none;
  overflow: hidden;
}

.wrapper_img_profile .image_block svg {
  display: block;
  height: 100%;
  width: 100%;
  fill: #999;
  background: #fff;
  stroke-width: 3;
}

.wrapper_img_profile .image_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about_name {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--noir);
}

.about_name h2 {
  font-size: 16px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.wrapper_name p {
  font-size: 12px;
  color: var(--noir8);
  line-height: 13px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.btn_more_info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: transparent;
  border: 0;
  outline: none;
  transition: background-color 0.1s ease-out;
}

.btn_more_info:active {
  background-color: var(--gray-hover);
}

@media screen and (max-width: 800px) {
  .wrapper_img_profile h2 {
    font-size: 15px;
  }
}
@media screen and (max-width: 540px) {
  .wrapper_img_profile .image_block {
    width: 40px;
    height: 40px;
  }
  .wrapper_img_profile {
    gap: 7px;
  }
}
