.block_center {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ccc;
}
.map_block {
  width: 100%;
  height: 100%;
}
/*------autocomplete-----*/
.autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 100px;
  z-index: 100;
}
.city {
  position: relative;
  top: 10%;
}
.block_edit {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 15px;
  border-radius: 30px;
  border: 1px solid rgb(233, 233, 233);
  background: #fff;
  overflow: hidden;
  cursor: pointer;
}
.city.active .block_edit {
  cursor: default;
}
.city.active .block_edit {
  background: #fff;
  box-shadow: rgb(0 0 0 / 30%) 0px 2px 16px;
}
.block_edit .search_content {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  margin-right: 10px;
  height: 100%;
  color: rgb(34, 34, 34);
}
.block_edit svg.maps {
  display: block;
  height: 16px;
  width: 16px;
  fill: var(--noir3);
}
.block_edit input.auto {
  white-space: nowrap;
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  width: 100%;
  height: 100%;
  background: transparent;
  color: var(--noir);
  border: none;
  outline: none;
  overflow: hidden;
}
.block_edit input.auto::placeholder {
  color: var(--noir);
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
}
.city.active .block_edit input.auto {
  background: #fff;
}
.block_edit button.reset {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(221, 221, 221);
  border-radius: 50%;
  border: none;
  outline: none;
  color: rgb(34, 34, 34);
  cursor: pointer;
  touch-action: manipulation;
  transition: -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s;
}
.block_edit svg.close_svg {
  display: block;
  fill: none;
  height: 12px;
  width: 12px;
  stroke: var(--noir);
  stroke-width: 5.33333;
  overflow: visible;
}
.cite_search {
  position: absolute;
  z-index: 10;
  top: 60px;
  left: 0px;
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 30px;
  box-shadow: rgb(0 0 0 / 20%) 0px 6px 20px;
  background: #fff;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  transition: 0.1s ease-in-out;
}
.city.active .cite_search {
  visibility: visible;
  opacity: 1;
}
.cite_search .current_location button {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px;
  padding: 15px 24px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}
.cite_search .current_location button:hover {
  background-color: rgb(247, 247, 247);
}
.cite_search .position {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: rgb(247, 247, 247);
  border-radius: 50%;
}
.cite_search .position svg {
  display: block;
  height: 18px;
  width: 18px;
  fill: var(--noir);
}
.cite_search .current_location button p {
  font-size: 15px;
}
.cite_search button.name {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 7px 24px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}
.cite_search button.name:hover {
  background-color: rgb(247, 247, 247);
}
.cite_search button .block_icon {
  padding: 8px;
  color: var(--noir5);
  background-color: rgb(241, 241, 241);
  border: 1px solid rgba(176, 176, 176, 0.2);
  border-radius: 5px;
}
.cite_search button p {
  font-size: 15px;
  text-align: left;
}
.manuel_adress button {
  background: transparent;
  outline: none;
  border: none;
  color: var(--noir);
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  padding: 15px 24px 0;
  text-decoration: underline;
}
/***-formulaire-------*/
.form_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px 70px;
  z-index: 100;
  overflow: auto;
}
.form_block {
  background: #fff;
  padding: 24px;
  border-radius: 16px;
}
.form_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
}
.form_head button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgb(247, 247, 247);
  cursor: pointer;
}
.form_head button svg {
  display: block;
  fill: none;
  height: 18px;
  width: 18px;
  stroke: var(--noir);
  stroke-width: 4;
  overflow: visible;
}
.form_head h2 {
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}
.form_block label {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir3);
  cursor: pointer;
}
.form_block input,
.form_block select {
  box-sizing: border-box;
  padding: 0 15px;
  margin: 5px 0;
  width: 100%;
  height: 50px;
  font-size: 15px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}
.form_block input::placeholder {
  font-size: 15px;
  color: var(--noir9);
}
.form_block input:focus,
.form_block select:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}
.erreur_input input {
  box-shadow: rgb(193, 53, 21) 0px 0px 0px 1px inset;
  background-color: rgb(255, 248, 246);
}
.erreur_input input:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
  background-color: transparent;
}
.form_block .valid {
  display: flex;
  align-items: center;
  padding-top: 24px;
}
.form_block .error {
  display: flex;
  gap: 5px;
}
.form_block .error p {
  padding-bottom: 5px;
  font-size: 13px;
  color: rgb(193, 53, 21);
}
.form_block .error svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: rgb(193, 53, 21);
}
.form_block .valid button.submit {
  background: var(--btn_noir1);
  color: #fff;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}
.form_block .valid button.submit {
  background: var(--btn_noir2);
}
.form_block .valid button.submit:active {
  transform: scale(0.9);
}
.form_block .valid button.accepte {
  background: transparent;
  outline: none;
  border: none;
  color: var(--noir);
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  padding: 15px;
  text-decoration: underline;
  transition: 0.3s;
}
.form_block .valid button.accepte:active {
  transform: scale(0.9);
}
/*-------reviewMaps------*/
.reviewMaps_container {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ccc;
}
.reviewMap_block {
  width: 100%;
  height: 100%;
}
.reviewMaps {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 20px;
  width: 90%;
  max-width: 450px;
  background: #fff;
  padding: 20px 25px;
  border-radius: 30px;
  z-index: 100;
}
.reviewMaps svg.maps {
  display: block;
  height: 20px;
  width: 20px;
  fill: var(--noir);
}
.reviewMaps h3 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}
.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.marker svg.maps_marker {
  display: block;
  height: 45px;
  width: 45px;
  stroke: #000;
  fill: var(--noir);
}
@media screen and (max-width: 990px) {
  .autocomplete {
    padding: 0 24px;
  }
  .form_container {
    padding: 24px;
  }
}
@media screen and (max-width: 743px) {
  .map_block {
    height: 70vh;
  }
  .autocomplete {
    padding: 0 15px;
  }
  .reviewMaps_container {
    height: 64vh;
  }
  .city {
    top: 5%;
  }
  .form_container {
    position: fixed;
    padding: 0;
    bottom: 0;
    background: #fff;
    z-index: 999;
  }
  .form_block {
    top: 0;
    bottom: 0;
    padding: 24px;
    border-radius: 0;
  }
}
