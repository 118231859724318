/* container_load */
.container_load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/*------account_container------*/
.account_container {
  padding: 40px 170px 50px;
  min-height: 100vh;
}

.head {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 40px;
  gap: 10px 0;
}

.head .image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  background: #ccc;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image svg {
  display: block;
  height: 100%;
  width: 100%;
  fill: #999;
  background: #fff;
  stroke-width: 3;
}

.head h1 {
  font-size: 28px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
}

.head a {
  font-size: 16px;
  color: var(--noir);
}

.account_block {
  padding-bottom: 16px;
  border-bottom: 1px solid rgb(235, 235, 235);
}

.account_block h2 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  line-height: 26px;
  margin: 24px 0;
  padding-top: 16px;
}

.account_block a {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  color: var(--noir);
}

.left_block {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.left_block svg {
  display: block;
  height: 24px;
  width: 24px;
  stroke: currentColor;
}

.left_block svg.host_mode,
.left_block svg.account {
  fill: none;
}

.left_block svg.notification {
  stroke-width: 0.2;
}

.left_block svg.gerer {
  fill: currentColor;
}

.left_block svg.add_home,
.left_block svg.host_mode,
.left_block svg.account {
  stroke-width: 2;
}

.account_block h3 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  color: var(--noir);
  line-height: 20px;
}

.account_block h3 span {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgb(255, 56, 92);
  margin-top: -13px;
  margin-left: -7px;
  z-index: 100;
}

/**-footer----*/
.footer {
  padding: 50px 0 80px;
}

.footer button {
  border: 1px solid var(--noir);
  background: transparent;
  color: var(--noir);
  padding: 0 25px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.footer button:hover {
  background: #f1f1f1;
}

.footer button:active {
  transform: scale(0.9);
}

@media screen and (max-width: 743px) {
  .account_container {
    padding: 30px 24px 50px;
  }
}
