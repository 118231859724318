.container {
  padding-bottom: 30px;
}

@media screen and (max-width: 700px) {
  .container {
    padding-bottom: 20px;
  }
}

.container h3 {
  font-size: 22px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  padding: 24px 0;
  line-height: 1.2;
}

.container h4 {
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.container .block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px 0;
}

.container .block .profil_block_avis {
  display: flex;
  gap: 10px;
}

.profil_block_avis .image_block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  text-decoration: none;
  background: #f1f1f1;
  border-radius: 50%;
  overflow: hidden;
}

.profil_block_avis .image_block img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  object-fit: cover;
}

.profil_block_avis .image_block h2 {
  text-transform: uppercase;
  color: var(--noir);
  font-size: 18px;
}

.container .block .profil_block_avis p {
  color: var(--noir9);
  font-size: 14px;
  margin: 0;
}

.container .block p {
  margin-top: 10px;
  margin-bottom: 5px;
  margin-right: 100px;
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow-y: hidden !important;
}

.block .voir_plus {
  display: none;
}

.container .more button {
  border: 1px solid var(--noir);
  background: transparent;
  color: var(--noir);
  padding: 0 20px;
  margin-top: 40px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
}

.container .more button:hover {
  background: var(--gray);
}

.container .more button:active {
  transition: 0.3s;
  transform: scale(0.9);
}

.colone {
  display: none;
}

@media screen and (max-width: 768px) {
  .colone {
    display: block;
  }

  .container .block {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    margin: 0 -24px;
    scroll-snap-type: x mandatory;
  }

  .container .block::-webkit-scrollbar {
    display: none;
  }

  .container .content {
    min-width: 320px;
    height: 220px;
    padding: 20px;
    border: 1px solid var(--border-color);
    border-radius: 15px;
    scroll-snap-align: center;
  }

  .container p {
    width: 100%;
    margin-right: 0;
    -webkit-line-clamp: 5 !important;
  }

  .block .voir_plus {
    min-width: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    height: 220px;
    padding: 20px;
    border: 1px solid var(--border-color);
    border-radius: 15px;
    scroll-snap-align: center;
  }

  .block .voir_plus .voir_plus_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--border-color);
    color: var(--noir);
  }

  .block .voir_plus h5 {
    color: var(--noir);
    font-weight: var(--font_display_2_font-weight);
    font-size: 16px;
  }
}

@media screen and (max-width: 700px) {
  .container .more button {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .profil_block_avis .image_block {
    width: 40px;
    height: 40px;
  }
}

/*---modal-commentaire-----*/
.modal_content {
  display: flex;
  flex-direction: column;
  width: 900px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.modal_content .m_body {
  overflow-y: auto;
  padding: 20px 24px;
}

.modal_content .m_body .profil_block_avis {
  display: flex;
  gap: 10px;
}

.modal_content .m_body .profil_block_avis p {
  color: var(--noir9);
  font-size: 14px;
  margin: 0;
}

.modal_content .m_body h4 {
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.modal_content .m_body p {
  margin: 10px 0 40px;
  font-size: 15px;
}

.modal_content .m_body ul {
  padding: 10px 0;
  margin-left: 18px;
}

.modal_content .m_body ul li {
  padding: 5px 0;
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .modal_content h3 {
    padding-bottom: 5px;
  }

  .m_head {
    min-height: 48px;
  }

  .commentaire_content .m_body::-webkit-scrollbar {
    display: none;
  }
}
