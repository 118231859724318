.head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.head h3 {
  color: var(--noir);
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
}

.head button {
  border: none;
  background: transparent;
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  text-decoration: underline;
  padding: 6px 10px;
  margin-top: -6px;
  margin-right: -10px;
  border-radius: 5px;
}

.head button:hover {
  background-color: var(--gray);
}

.head button:active {
  transition: 0.3s;
  transform: scale(0.9);
}

.main p {
  color: #717171;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-y: hidden;
}

.main a {
  color: var(--noiir);
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
}
