/*---modal_fav-----*/

.modal_content {
  display: flex;
  flex-direction: column;
  width: 450px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  border-bottom: 1px solid var(--border-color);
}

.m_head .delete_btn {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border: none;
  color: var(--noir);
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
}

.m_head .delete_btn:hover {
  background-color: var(--gray);
}

.m_head h3 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.m_body {
  overflow: auto;
  padding: 30px 24px;
}

.addTolist h4 {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 5px;
}

.m_body input {
  width: 100%;
  height: 50px;
  padding: 5px 15px;
  font-size: 16px;
  border-radius: 8px;
  margin-bottom: 5px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.m_body input:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.m_body span {
  font-size: 14px;
  font-weight: 400;
  color: #999;
}

.m_body button.send,
.m_body button.loading {
  border: none;
  color: #fff;
  margin-top: 30px;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.m_body button.send {
  background: var(--btn_noir1);
}

.m_body button.send:hover {
  background: var(--btn_noir2);
}

.m_body button.send:active {
  transform: scale(0.9);
}

.m_body button.loading,
.m_body button.send.disable {
  background: rgb(226, 226, 226);
  cursor: no-drop;
}

.m_body button.loading:hover,
.m_body button.send.disable:hover {
  background: rgb(226, 226, 226);
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }

  .m_body {
    padding: 30px 24px 16px;
  }

  .m_body::-webkit-scrollbar {
    display: none;
  }
}

/**-------sweetalert--------*/
.actions_class {
  display: flex !important;
  padding: 0 30px !important;
  justify-content: space-between !important;
}

.cancel_button_class {
  color: #222222 !important;
  background-color: transparent !important;
  text-decoration: underline;
  margin-left: -15px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px !important;
}

.confirm_button_class {
  border: none;
  outline: none !important;
  color: #fff;
  background-color: var(--btn_noir1) !important;
  height: 45px;
  padding: 0 25px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px !important;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: none !important;
}

.confirm_button_class:hover {
  background-color: var(--btn_noir2);
}
