/*-----hotel block------*/
.loading_page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.hotel_bloc {
  display: grid;
  grid-template-columns: 500px 1fr;
  min-height: 100vh;
}

.hotel {
  padding: 10px 24px 150px;
}

.array_vide {
  width: var(--fav-list-width);
  max-width: 100%;
  margin: 0 auto;
  margin-top: var(--main-top);
}

.array_vide h2 {
  font-size: 22px;
  color: var(--noir);
}

.array_vide p {
  font-size: 15px;
  margin: 24px 0;
}

.array_vide a {
  text-decoration: none;
  color: #fff;
  display: inline-block;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border: none;
  border-radius: 8px;
  background: var(--btn_noir1);
  transition: 0.3s;
}

.array_vide a:hover {
  background: var(--btn_noir2);
}

.array_vide a:active {
  transform: scale(0.9);
}

@media screen and (min-width: 1440px) {
  .hotel_bloc {
    grid-template-columns: 35vw 1fr;
  }
}

@media screen and (max-width: 900px) {
  .hotel_bloc {
    display: block;
  }
}

/*------liste_hotel--------*/
.liste_hotel {
  display: block;
  width: var(--fav-list-width);
  max-width: 100%;
  margin: 0 auto;
}

.hotel_activite a {
  display: block;
  text-decoration: none;
  z-index: 2;
}

.liste_hotel .hotel_image {
  display: block;
  padding: 10px 0;
}

.liste_hotel .container {
  position: relative;
}

.block_photo_result {
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  background: hsl(180, 4%, 90%);
}

.hotel_image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  vertical-align: middle;
}

.prev,
.next {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.959);
  top: 45%;
  z-index: 20;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  opacity: 0;
}

.prev svg,
.next svg {
  display: block;
  fill: none;
  height: 12px;
  width: 12px;
  stroke: currentcolor;
  stroke-width: 4;
  overflow: visible;
}

.hotel_image:hover .prev,
.hotel_image:hover .next {
  opacity: 1;
}

.hotel_image:hover .prev.disable,
.hotel_image:hover .next.disable {
  opacity: 0;
}

.prev:hover,
.next:hover {
  background: #fff;
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
    rgb(0 0 0 / 12%) 0px 6px 16px;
  transform: scale(1.04);
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.prev.disable,
.next.disable {
  opacity: 0;
}

.hotel_image .container .infos {
  position: absolute;
  left: -5px;
  top: 10px;
  color: var(--noir5);
  padding: 3px 10px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  font-weight: var(--font_display_3_font-weight);
  border-radius: 4px;
  background: #fff;
  border: 1px solid #dcdfe4;
  max-width: 150px;
  z-index: 20;
}

.hotel_image .container svg.fav_svg {
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 20;
  cursor: pointer;
  display: block;
  height: 24px;
  width: 24px;
  stroke-width: 2;
  overflow: visible;
  fill: #ff385c;
  stroke: #fff;
}

.hotel_descrip {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 15px 0;
}

.hotel_descrip .hotel_header {
  display: grid;
  grid-template-columns: 1fr 180px;
  grid-gap: 10px;
  padding-bottom: 5px;
}

.hotel_header .type h3 {
  font-size: 14px;
  color: var(--noir6);
  font-weight: var(--font_display_1_font-weight);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.hotel_evaluation {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.hotel_evaluation .block_eval {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--noir);
}

.hotel_evaluation p {
  font-size: 14px;
}

.hotel_descrip h4.name {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: var(--font_display_1_font-weight);
  color: var(--noir);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.container_desc {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.hotel_descrip .text_descrip {
  font-size: 14px;
  font-weight: var(--font_display_1_font-weight);
  color: var(--noir6);
  padding: 2px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.descrip_detail {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
}

.descrip_detail p {
  color: var(--noir6);
  font-size: 14px;
}

.descrip_detail span {
  display: inline-block;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: var(--noir6);
}

.descrip_droite .prix_bloc {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 200px;
  height: 100%;
  text-align: right;
  text-align: bottom;
  font-size: 14px;
}

.descrip_droite .prix_bloc p {
  font-family: var(--font-family_heading);
  color: #000;
}

.descrip_droite .descrip_prix {
  font-weight: var(--font_heading_1_font-weight);
  font-size: 17px;
  color: var(--noir);
}

.descrip_droite p.descrip_prix span {
  display: inline-block;
}

@media screen and (max-width: 800px) {
  .hotel_activite:not(:last-child) {
    overflow: hidden;
    border: 0;
  }

  .hotel_activite:hover {
    box-shadow: none;
  }

  .liste_hotel .hotel_image {
    display: block;
    padding: 15px 0 0;
  }

  .hotel_image:hover .prev {
    display: none;
  }

  .hotel_image:hover .next {
    display: none;
  }

  .liste_hotel .hotel_descrip {
    padding: 10px 0px 5px;
  }

  .hotel_descrip .hotel_header {
    grid-template-columns: 1fr;
    grid-gap: 7px;
    padding-bottom: 0;
  }

  .hotel_evaluation {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .hotel_descrip h4.name {
    font-size: 15px;
    font-weight: var(--font_display_2_font-weight);
  }

  .hotel_descrip .text_descrip {
    display: none;
  }

  .container_desc {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 5px;
  }

  .container_desc .descrip_detail {
    width: 100%;
    text-align: left;
  }

  .descrip_droite .prix_bloc {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: row;
    text-align: right;
    gap: 8px;
  }

  .descrip_droite p.prix_barer {
    display: none;
  }

  .descrip_droite p.prix_barer.mobile {
    display: block;
    font-size: 16px;
  }

  .descrip_droite p.descrip_prix {
    font-size: 16px;
  }
}

@media screen and (max-width: 414px) {
  .block_photo_result img {
    height: 250px;
  }
}

@media screen and (max-width: 370px) {
  .descrip_detail {
    flex-wrap: wrap;
    gap: 0 5px;
  }
}

/**-------sweetalert--------*/
.actions_class {
  display: flex !important;
  padding: 0 30px !important;
  justify-content: space-between !important;
}

.cancel_button_class {
  color: #222222 !important;
  background-color: transparent !important;
  text-decoration: underline;
  margin-left: -15px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px !important;
}

.confirm_button_class {
  border: none;
  outline: none !important;
  color: #fff;
  background-color: var(--btn_noir1) !important;
  height: 45px;
  padding: 0 25px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px !important;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: none !important;
}

.confirm_button_class:hover {
  background-color: var(--btn_noir2);
}
