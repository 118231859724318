.wrapper_img_profile {
  display: block;
  width: 140px;
  height: 140px;
  text-decoration: none;
}

.wrapper_img_profile .image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #ddd;
  overflow: hidden;
}

.wrapper_img_profile .image svg {
  display: block;
  height: 100%;
  width: 100%;
  fill: #999;
  background: #fff;
  stroke-width: 3;
}

.wrapper_img_profile .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 743px) {
  .wrapper_img_profile {
    width: 80px;
    height: 80px;
  }
}
