/*---modal-price details-----*/

.detail_content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 750px;
  padding: 24px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.detail_content .dispositif {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  border: 2px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
}

.detail_content .dispositif h4 {
  padding: 15px 0;
  font-weight: var(--font_display_2_font-weight);
  font-size: 20px;
  color: var(--noir);
}

@media screen and (min-width: 1400px) {
  .detail_content {
    height: 730px;
  }
}

@media screen and (max-width: 393px) {
  .detail_content .dispositif {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 353px) {
  .detail_content .dispositif {
    width: 200px;
    height: 200px;
    padding: 24px 20px;
  }
}
