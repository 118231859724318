/* container_load */
.container_load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.profil_container {
  max-width: 999px;
  margin: 0 auto;
  padding: 20px 24px 24px;
  min-height: 100vh;
}

/*-----profil_box----*/
.profil_box {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.profil_infos {
  padding: 50px 50px 0;
}

@media screen and (max-width: 900px) {
  .profil_infos {
    padding: 50px 0 0;
  }
}

@media screen and (max-width: 743px) {
  .profil_container {
    padding: 20px 24px 24px;
  }
}

@media screen and (max-width: 540px) {
  .profil_infos {
    padding: 30px 0 0;
  }
}
