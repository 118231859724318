.date .selecte {
  width: 100%;
  height: 50px;
  padding: 0 15px;
  border-radius: 30px;
  background: rgb(247, 247, 247);
  border: 1px solid rgb(233, 233, 233);
  overflow: hidden;
  cursor: pointer;
}

.date .selecte:hover {
  background: rgb(223, 223, 223);
}

.date.active .selecte {
  background: #fff;
  box-shadow: rgb(0 0 0 / 30%) 0px 2px 16px;
}

.date.active .selecte:hover {
  background: #fff;
}

/*------------date search---------*/
.date .selecte {
  display: flex;
  align-items: center;
  gap: 10px;
}

.selecte svg.calendar {
  display: block;
  height: 16px;
  width: 16px;
  fill: var(--noirc);
  stroke: var(--noirc);
}

.date .selecte p {
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.dateContent {
  position: absolute;
  z-index: 2010;
  top: 75px;
  left: 24px;
  right: 24px;
  width: auto;
  max-height: 65vh;
  overflow-y: auto;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 30px;
  box-shadow: rgb(0 0 0 / 20%) 0px 6px 20px;
  background: #fff;
  padding: 30px 70px;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  transition: 0.1s ease-in-out;
}

.dateContent h3 {
  font-size: 17px;
  color: var(--noir);
  text-align: center;
  font-weight: var(--font_display_2_font-weight);
  padding-bottom: 30px;
  display: none;
}

.date.active .dateContent {
  visibility: visible;
  opacity: 1;
}

.dateContent .m_footer {
  display: none;
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 1018px) {
  .dateContent {
    top: 150px;
    padding: 30px 20px;
  }
}

@media screen and (max-width: 743px) {
  .date .selecte:hover {
    background: #fff;
  }

  .date.active .selecte {
    background: rgb(247, 247, 247);
    box-shadow: none;
  }

  .dateModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vhw;
    background: var(--gray);
    z-index: 2010;
    display: none;
  }

  .date.active .dateModal {
    display: block;
  }

  .dateContent {
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    border-radius: 24px 24px 0 0;
    padding: 30px 70px 100px;
    max-height: 90%;
    overflow-y: auto;
    visibility: visible;
    opacity: 1;
  }

  .dateContent::-webkit-scrollbar {
    display: none;
  }

  .dateContent h3 {
    display: block;
  }

  .dateContent .m_footer {
    position: fixed;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 24px;
    background-color: var(--modal-footer-background-color);
  }

  .dateContent .m_footer button:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--noir);
    background: transparent;
    text-decoration: underline;
    height: 48px;
    padding: 0 8px;
    margin-left: -8px;
    border-radius: 8px;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: var(--font_display_2_font-weight);
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .dateContent .m_footer button:nth-child(1):hover {
    background: var(--gray);
  }

  .dateContent .m_footer button:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: var(--btn_noir1);
    height: 48px;
    padding: 0 25px;
    border-radius: 8px;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: var(--font_display_2_font-weight);
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .dateContent .m_footer button:nth-child(2):active {
    transform: scale(0.9);
  }
}

@media screen and (max-width: 540px) {
  .dateContent {
    padding: 30px 24px 100px;
  }
}
