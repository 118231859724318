/*-----button carte and liste toggle------*/
.buttonCarte {
  position: fixed;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  bottom: 40px;
  display: none;
  justify-content: center;
  align-items: center;
  gap: 7px;
  width: 100px;
  padding: 11px;
  background: var(--noir);
  color: #fff;
  border: none;
  outline: none;
  border-radius: 24px;
  cursor: pointer;
  z-index: 700;
}

.buttonCarte span {
  display: inline-block;
  color: #fff;
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
}

.container {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #ddd;
  overflow: hidden;
}

.map_block {
  position: relative;
  width: 100%;
  height: 100%;
}

/*--------marker component------*/
button.anonce {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 0;
  color: #222222;
  font-weight: var(--font_display_3_font-weight);
  font-size: 15px;
  background: #fff;
  border-radius: 28px;
  box-shadow: rgb(0 0 0 / 4%) 0px 0px 0px 1px, rgb(0 0 0 / 18%) 0px 2px 4px;
  height: 28px;
  padding: 0px 8px;
  transform: scale(1);
  transform: translate(-50% -50%);
  transform-origin: 50% 50%;
  transition: transform 250ms cubic-bezier(0, 0, 0.1, 1) 0s;
  cursor: pointer;
  z-index: 1;
}

button.anonce svg {
  height: 14px;
  width: 14px;
  stroke-width: 0;
  overflow: visible;
  fill: #ff385c;
  stroke: #fff;
}

.anonce:hover {
  transform: scale(1.09);
  z-index: 3;
}

.anonce.active {
  background: var(--btn_noir1);
  color: #fff;
  transform: scale(1.09);
  z-index: 2;
}

.hoverActive .anonce {
  background: var(--btn_noir1);
  color: #fff;
  transform: scale(1.09);
  z-index: 3;
}

/*----card------*/
.hotel_image a {
  display: block;
  text-decoration: none;
  z-index: 2;
}

.hotel_image {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 250px;
  height: auto;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  z-index: 999;
  box-shadow: rgb(0 0 0 / 15%) 0px 5px 16px;
}

.hotel_image .block_img {
  position: relative;
}

.imgslider {
  width: 100%;
  transition: all 0.5s ease-in-out;
  background: hsl(180, 4%, 90%);
  padding: 0;
}

.hotel_image img {
  width: 100%;
  height: 170px;
  object-fit: cover;
  vertical-align: middle;
}

button.close_card {
  position: absolute;
  left: 10px;
  top: 10px;
  border: none;
  outline: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.568);
  z-index: 20;
  cursor: pointer;
}

.close_card svg.close_svg {
  display: block;
  fill: none;
  height: 12px;
  width: 12px;
  stroke: #fff;
  stroke-width: 5.33333;
  overflow: visible;
}

.prev,
.next {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.959);
  top: 45%;
  z-index: 20;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  opacity: 0;
}

.prev svg,
.next svg {
  display: block;
  fill: none;
  height: 12px;
  width: 12px;
  stroke: currentcolor;
  stroke-width: 4;
  overflow: visible;
}

.hotel_image:hover .prev,
.hotel_image:hover .next {
  opacity: 1;
}

.hotel_image:hover .prev.disable,
.hotel_image:hover .next.disable {
  opacity: 0;
}

.prev:hover,
.next:hover {
  background: #fff;
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
    rgb(0 0 0 / 12%) 0px 6px 16px;
  transform: scale(1.04);
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.prev.disable,
.next.disable {
  opacity: 0;
}

.hotel_image .block_img svg.fav_svg {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 20;
  cursor: pointer;
  display: block;
  height: 24px;
  width: 24px;
  stroke-width: 2;
  overflow: visible;
  fill: #ff385c;
  stroke: #fff;
}

.hotel_image .block_img svg.no_fav_svg {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 20;
  cursor: pointer;
  display: block;
  height: 24px;
  width: 24px;
  stroke-width: 2;
  overflow: visible;
  fill: rgba(0, 0, 0, 0.5);
  stroke: #fff;
}

.descript {
  padding: 10px 15px;
  color: var(--noir);
}

.descript h2 {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.hotel_evaluation {
  display: flex;
}

.hotel_evaluation .block_eval {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--noir);
}

.hotel_evaluation p {
  font-size: 14px;
}

.descript .price {
  display: flex;
  align-items: center;
  gap: 5px;
}

.descript .price h3 {
  font-size: 15px;
  font-weight: var(--font_display_3_font-weight);
}

@media screen and (max-width: 900px) {
  .buttonCarte {
    display: flex;
    bottom: 80px;
  }

  .buttonCarte.disable {
    display: none;
  }

  .container {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    border-radius: 0;
    z-index: 600;
  }

  .container.active {
    display: block;
  }

  .map_block {
    margin-left: var(--sidebar-with-hidded);
  }

  .hotel_image img {
    height: 150px;
  }
}

@media screen and (max-width: 743px) {
  .prev,
  .next {
    display: none;
  }
}

@media screen and (max-width: 280px) {
  .hotel_image {
    left: 0;
    right: 0;
    transform: none;
    width: 100%;
  }
}

/**-------sweetalert--------*/
.actions_class {
  display: flex !important;
  padding: 0 30px !important;
  justify-content: space-between !important;
}

.cancel_button_class {
  color: #222222 !important;
  background-color: transparent !important;
  text-decoration: underline;
  margin-left: -15px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px !important;
}

.confirm_button_class {
  border: none;
  outline: none !important;
  color: #fff;
  background-color: var(--btn_noir1) !important;
  height: 45px;
  padding: 0 25px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px !important;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: none !important;
}

.confirm_button_class:hover {
  background-color: var(--btn_noir2);
}
