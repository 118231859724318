.title {
  padding-bottom: 50px;
}
.title h1 {
  font-size: 20px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  padding-bottom: 24px;
}
.title p {
  font-size: 15px;
  padding-bottom: 24px;
}
.title textarea {
  width: 100%;
  height: 170px;
  resize: vertical;
  border-radius: 8px;
  padding: 20px;
  font-size: 25px;
  font-weight: var(--font_display_2_font-weight);
  max-height: 50vh;
  min-height: 144px;
  text-overflow: ellipsis;
  line-height: 28px;
  overflow-y: auto;
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}
.title textarea:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}
.title textarea::placeholder {
  color: var(--noir9);
}
.title span {
  padding-top: 10px;
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir6);
}
@media screen and (max-width: 743px) {
  .title textarea {
    padding: 16px;
    max-height: 25vh;
    min-height: 144px;
  }
}
