.modal_content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 550px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid var(--border-color);
}

.m_head h3 {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

@media screen and (max-width: 743px) {
  .m_head {
    min-height: 48px;
  }
}

.modal_content .m_body {
  overflow: auto;
  padding: 30px 24px 24px;
}

.no_list h4 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  text-align: center;
}

.no_list p {
  margin: 10px 0;
  font-size: 15px;
}

.creatList {
  width: 100%;
  background: transparent;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}

.creatList:hover {
  background: var(--gray);
}

.creatList svg {
  display: block;
  fill: none;
  height: 32px;
  width: 32px;
  stroke: var(--noir);
  stroke-width: 2;
  overflow: visible;
}

.creatList p {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  text-align: left;
}

.all_list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

button.addTolist {
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 15px;
  text-align: left;
}

.lists_card {
  width: 70px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--gray);
  overflow: hidden;
}

.lists_card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.addTolist h4 {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 5px;
}

.modal_content .m_body input {
  width: 100%;
  height: 50px;
  padding: 5px 15px;
  font-size: 16px;
  border-radius: 8px;
  margin-bottom: 5px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.modal_content .m_body input:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.modal_content .m_body span {
  font-size: 14px;
  font-weight: 400;
  color: #999;
}

.modal_content .m_body button.send,
.m_body button.loading {
  border: none;
  color: #fff;
  margin-top: 30px;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.m_body button.send {
  background: var(--btn_noir1);
}

.m_body button.send:hover {
  background: var(--btn_noir2);
}

.m_body button.send:active {
  transform: scale(0.9);
}

.m_body button.loading,
.m_body button.send.disable {
  background: rgb(226, 226, 226);
  cursor: no-drop;
}

.m_body button.loading:hover,
.m_body button.send.disable:hover {
  background: rgb(226, 226, 226);
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .modal_content .m_body {
    padding: 16px 24px;
  }

  .modal_content .m_body::-webkit-scrollbar {
    display: none;
  }
}
