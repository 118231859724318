.content {
  margin-bottom: 20px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--border-color);
}

.content .block {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  padding: 5px 0;
  color: var(--noir);
}

.content .block p {
  font-size: 16px;
  color: var(--noir);
}

.content .block a {
  color: #000;
  font-weight: var(--font_display_2_font-weight);
}

.content .block p strong {
  color: #000;
  font-weight: var(--font_display_2_font-weight);
  background-color: var(--gray);
  padding: 3px;
}

.button_copy_text {
  background-color: transparent;
  padding: 2px 10px;
  border-radius: 5px;
  border: 1px solid var(--noir9);
  margin-left: 3px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  transition: 0.3s;
}

.button_copy_text:active {
  transform: scale(0.9);
}

/*----become hote-----*/
.contact_etab {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--border-color);
}

.contact_etab h3 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 19px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.contact_etab_block h4 {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
}

.contact_etab p {
  font-size: 15px;
}

.block_link {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.block_link a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-decoration: none;
  border: 1px solid var(--noir);
  border-radius: 40px;
  height: 48px;
  padding: 0 24px;
  font-weight: var(--font_display_2_font-weight);
  font-size: 16px;
  transition: transform 0.3s;
}

.block_link a:nth-child(1) {
  background-color: var(--btn_noir1);
  color: white;
}

.block_link a:nth-child(1):hover {
  background-color: var(--btn_noir2);
}

.block_link a:nth-child(2) {
  background-color: transparent;
  color: var(--noir);
}

.block_link a:nth-child(2):hover {
  background-color: var(--gray);
}

.block_link a:active {
  transform: scale(0.9);
}

/*-----besoin d'aide------*/
.savoir {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.asavoir h4 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 19px;
  padding-bottom: 20px;
}

.asavoir p {
  font-size: 14px;
}

.asavoir a {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

@media screen and (max-width: 912px) {
  .content {
    margin-bottom: 20px;
    padding: 30px 0;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
  }
}

@media screen and (max-width: 540px) {
  .content h4 {
    padding-bottom: 7px;
    font-size: 15px;
  }

  .content p {
    font-size: 15px;
  }

  .block_link a {
    width: 100%;
  }
}
