.info_search {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.info_search .block_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info_search .block_flex h3 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}
.info_search .block_flex .occup_count {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  text-align: center;
  gap: 5px;
}
.info_search .block_flex button {
  width: 32px;
  height: 32px;
  cursor: pointer;
  font-size: 19px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #999;
  color: var(--noir5);
  outline: none;
  touch-action: manipulation;
  background: rgb(255, 255, 255);
  border-radius: 50%;
}
.info_search .block_flex button:hover {
  border: 1px solid #333;
  color: var(--noir);
}
.block_flex button.disable {
  cursor: not-allowed;
  color: rgb(235, 235, 235);
  border-color: rgb(235, 235, 235);
  background: rgb(255, 255, 255);
}
.block_flex button.disable:hover {
  border: 1px solid #f1f1f1;
  color: #ddd;
  cursor: no-drop;
}
svg.decr {
  display: block;
  fill: none;
  height: 12px;
  width: 12px;
  stroke: currentcolor;
  stroke-width: 5.33333;
  overflow: visible;
}
