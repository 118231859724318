.no_connecte {
  padding: var(--main-top) 115px 100px;
  min-height: 100vh;
}

.no_connecte h1 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 25px;
  color: var(--noir);
}

.no_connecte .connexion {
  padding: 50px 0;
}

.no_connecte .connexion h2 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 20px;
  color: var(--noir);
}

.no_connecte .connexion p {
  padding: 18px 0;
}

.no_connecte .connexion button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 25px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  border: 1px solid var(--btn_noir1);
  outline: none;
  color: var(--noir);
  background: transparent;
  transition: 0.3s;
}

.no_connecte .connexion button:hover {
  background: #f1f1f1;
}

.no_connecte .connexion button:active {
  transform: scale(0.9);
}

@media screen and (max-width: 1200px) {
  .no_connecte {
    padding: var(--main-top) 115px 100px;
  }
}

@media screen and (max-width: 743px) {
  .no_connecte {
    padding: var(--main-top) 24px 100px;
  }
}

/*------liste message-----*/
.liste_container {
  height: 100vh;
}

.liste_container h1.boite {
  color: var(--noir);
  font-size: 25px;
  font-weight: var(--font_display_3_font-weight);
  padding-left: 24px;
  padding-bottom: 24px;
  display: none;
}

.block_grid {
  display: grid;
  grid-template-columns: 450px 1fr;
  height: 100%;
}

.block_grid_1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  border-right: 1px solid rgb(235, 235, 235);
  overflow: hidden;
}

/*-------liste-----*/
.block_grid_1 .loading_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 24px;
}

.block_grid .no_result {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 24px;
}

.block_grid .no_result p {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir6);
}

.block_grid_1 .liste {
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 50px;
  overflow: auto;
}

.block_grid_1 .liste .liste_content {
  display: flex;
  gap: 15px;
  text-decoration: none;
  width: 100%;
  padding: 20px;
  margin-bottom: 5px;
  border-bottom: 1px solid rgb(235, 235, 235);
}

.block_grid_1 .liste .liste_content:last-child {
  border-bottom: none;
}

.liste .liste_content .img_block {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
  flex: 1;
}

.liste .liste_content .img_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.liste .liste_content .img_block h2 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir3);
}

.liste .profil_text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 2;
}

.liste .profil_text .non_lue h5,
.liste .profil_text .non_lue p {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.liste .profil_text h5 {
  font-size: 16px;
  color: var(--noir6);
  font-weight: 400;
  line-height: 24px;
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.liste .profil_text h5 span {
  display: inline-block;
  font-size: 14px;
  color: var(--noir8);
}

.liste .profil_text .last_message {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}

.liste .last_message span {
  font-size: 12px;
  color: var(--noir8);
}

.liste .profil_text p {
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.liste .profil_text h6 {
  text-align: right;
  font-size: 12px;
  color: var(--noir8);
}

/*-------block 2-detail----*/
.block_grid_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 100%;
  padding: 24px;
  overflow: hidden;
}

.detail_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--noir);
}

.detail_block h2 {
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  margin: 10px 0 5px;
}

.detail_block p {
  font-size: 13px;
  color: var(--noir8);
  text-align: center;
}

@media screen and (min-width: 1440px) {
  .liste_container {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

@media screen and (max-width: 1263px) {
  .block_grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 800px) {
  .block_grid {
    grid-template-columns: 1fr;
  }

  .liste_container h1.boite {
    display: block;
    padding-bottom: 15px;
  }

  .block_grid_1 {
    border-right: none;
  }

  .block_grid_1 .navigation {
    padding-top: 0;
    padding-left: 9px;
    padding-right: 20px;
  }

  .block_grid_1 .liste {
    padding: 6px;
  }

  .liste .liste_content .img_block {
    width: 40px;
    height: 40px;
  }

  .block_grid_2 {
    display: none;
  }
}

@media screen and (max-width: 743px) {
  .liste_container {
    padding-top: 30px;
  }

  .block_grid_1 .liste {
    padding-bottom: 80px;
  }

  .block_grid_1 .liste::-webkit-scrollbar {
    display: none;
  }
}
