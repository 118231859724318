/* container_load */
.container_load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/*------liste message-----*/
.liste_container {
  padding-top: 80px;
  height: 100vh;
}

.block_grid {
  display: grid;
  grid-template-columns: 260px 1fr;
  height: 100%;
}

/*-------block 2-detail----*/
.block_grid_2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.block_grid_2 .head .left {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 70px;
  border-bottom: 1px solid var(--border-color);
  padding: 20px 24px;
}

.block_grid_2 .head .left button {
  display: none;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  color: var(--noir);
  cursor: pointer;
}

.block_grid_2 .head .left button:hover {
  background: var(--gray-hover);
}

.block_grid_2 .head h2 {
  font-size: 18px;
  color: var(--noir);
  font-weight: var(--font_display_3_font-weight);
}

/*-------no result-----*/
.no_result {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  text-align: center;
  color: var(--noir8);
}

.no_result h3 {
  padding: 10px 0;
  color: var(--noir);
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
}

.no_result p {
  color: var(--noir);
  font-size: 15px;
}

.no_result a {
  display: inline-block;
  margin: 20px 0;
  padding: 13px 25px;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  background: var(--btn_noir1);
  border-radius: 8px;
}

.no_result a:hover {
  background: var(--btn_noir2);
}

/*-------liste-----*/
.block_grid_2 .loading_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 24px;
}

.block_grid_2 .liste {
  display: flex;
  flex-direction: column;
  padding: 10px 30px 50px;
  height: 100%;
  width: 50%;
  border-right: 1px solid var(--border-color);
  overflow: auto;
}

.block_grid_2 .liste .liste_content {
  display: flex;
  gap: 15px;
  text-decoration: none;
  width: 100%;
  height: 150px;
  padding: 20px 15px;
  margin-bottom: 5px;
  border-bottom: 1px solid var(--border-color);
}

.block_grid_2 .liste .liste_content:last-child {
  border-bottom: none;
}

.liste .liste_content .img_block {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: #f1f1f1;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
  flex: 1;
}

.liste .liste_content .img_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.liste .liste_content .img_block h2 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir3);
}

.liste .profil_text {
  flex: 2;
}

.liste .profil_text .non_lue h5,
.liste .profil_text .non_lue p {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.liste .profil_text h5 {
  font-size: 16px;
  color: var(--noir6);
  font-weight: 400;
  line-height: 24px;
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.liste .profil_text .last_message {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.liste .profil_text .last_message span {
  font-size: 14px;
  color: var(--noir8);
}

.liste .profil_text p {
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-y: hidden;
}

.liste .profil_text p span {
  margin-right: 5px;
  font-size: 12px;
  color: var(--noir6);
}

.liste .profil_text span.statut {
  font-size: 12px;
  color: var(--noir6);
}

.liste .profil_text h6 {
  text-align: right;
  font-size: 12px;
  color: var(--noir8);
}

@media screen and (max-width: 1200px) {
  .block_grid_2 .liste {
    width: 100%;
    padding: 10px 24px;
  }

  .block_grid_2 .liste::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .block_grid {
    grid-template-columns: 1fr;
  }

  .block_grid_2 .head .left {
    padding-left: 15px;
  }

  .block_grid_2 .head .left button {
    display: flex;
  }

  .block_grid_2 .liste {
    padding: 10px 24px 200px;
  }

  .block_grid_2 .liste .liste_content {
    padding: 20px 0px;
  }
}
