.voyage_container {
  padding-top: 120px;
  height: 100vh;
}

/*-----block-link-search_block-----*/
.navigation {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.navigation .block_link_search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 30px;
}

.block_link_search h1 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 25px;
  color: var(--noir3);
}

.flter_block {
  display: flex;
  align-items: center;
  gap: 15px;
}

.filter button {
  position: relative;
  background: transparent;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;
}

.filter button:hover {
  background-color: var(--gray);
}

.filter svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: #222222;
}

.filter button:active {
  transform: scale(0.9);
}

.filter button.active::after {
  position: absolute;
  content: "";
  display: block;
  inset: 0;
  border: 2px solid #000;
  background: transparent;
  border-radius: 50px;
}

.filter button span.length {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: -2px;
  right: -2px;
  width: 18px;
  height: 18px;
  font-size: 10px;
  font-weight: var(--font_display_3_font-weight);
  border-radius: 50%;
  background: #000;
  color: #fff;
  z-index: 10;
}

.filter button.active span.length {
  display: flex;
}

.search_block {
  width: 280px;
}

.navigation .no_result {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #f5f5f5;
  padding: 50px 15px;
  margin: 0 30px;
  margin-top: 60px;
  border-radius: 16px;
}

.navigation .no_result svg {
  display: block;
  height: 24px;
  width: 24px;
  fill: var(--noir6);
}

.navigation .no_result h2 {
  color: var(--noir);
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  line-height: 33px;
}

.navigation .no_result a {
  display: inline-block;
  align-items: center;
  margin-top: 10px;
  padding: 6px 24px;
  color: var(--noir4);
  border: 1px solid var(--noir);
  border-radius: 8px;
  text-decoration: none;
  background: #fff;
  transition: 0.3s;
}

.navigation .no_result a:hover {
  background: #f1f1f1;
}

.navigation .no_result a:active {
  transform: scale(0.9);
}

@media screen and (max-width: 900px) {
  .navigation .block_link_search {
    padding: 0 24px;
  }

  .navigation .no_result {
    margin: 0 24px;
  }
}

@media screen and (max-width: 760px) {
  .voyage_container {
    height: 100dvh;
    padding-top: 90px;
  }

  .navigation .block_link_search {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;
  }

  .search_block {
    width: 100%;
  }
}

/*-----------table------------*/
.container_arry {
  height: 100%;
  width: auto;
  padding-bottom: 24px;
  overflow: auto;
}

.customers {
  position: relative;
  display: inline-table;
  border-collapse: collapse;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  margin: 0 30px 24px;
}

.customers thead {
  position: sticky;
  top: 0;
  z-index: 10;
}

.customers th,
.customers tr {
  border-bottom: 1px solid #ebebeb;
  background-color: #fff;
}

.customers th,
.customers td {
  text-align: left;
  min-width: 170px;
}

.customers th {
  font-size: 13px;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: var(--font_display_2_font-weight);
  padding: 10px 8px;
  color: var(--noir3);
}

.customers th.parametre {
  font-size: 17px;
}

.customers tr:hover,
.customers tr:hover td.image,
.customers td.image:hover {
  background-color: var(--gray);
}

.customers td {
  display: table-cell;
  width: 100%;
  padding: 16px 8px;
  color: var(--noir);
  font-size: 15px;
  word-break: break-all;
}

.customers td p {
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.customers th.image,
.customers td.image {
  position: sticky;
  left: 0;
  top: 0;
  background-color: #fff;
  min-width: 300px !important;
}

.customers td.image {
  padding: 0 !important;
}

.customers td.image button {
  width: 100%;
  display: flex;
  background: transparent;
  padding: 16px 8px;
  border: none;
  background-clip: padding-box;
  text-align: left;
}

.customers td .card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 35px;
  background-color: #ddd;
  margin-right: 15px;
  border-radius: 5px;
  overflow: hidden;
}

.customers td .card svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: #fff;
}

.customers td .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.customers td.image p {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.customers .todo button {
  border: none;
  padding: 7px 10px;
  border-radius: 5px;
  color: var(--noir);
  font-size: 15px;
  background: var(--tertiary-background);
  background-clip: padding-box;
}

.customers .todo button:hover {
  background-color: #fff;
}

.statut {
  display: flex;
  justify-content: center;
  align-items: center;
}

.statut span {
  display: inline-flex;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.statut .status_inprogress {
  background-color: #ddd;
}
.statut .status_save {
  background-color: rgb(255, 175, 15);
}
.statut .status_publish {
  background-color: rgb(0, 138, 5);
}
.statut .status_hide {
  background-color: rgb(193, 53, 21);
}

.instabook {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}

.instabook svg {
  display: block;
  height: 16px;
  width: 16px;
}

.instabook svg.no {
  fill: rgb(221, 221, 221);
}

.instabook svg.yes {
  fill: rgb(255, 175, 15);
}

.instabook span {
  font-size: 14px;
  color: var(--noir);
}

@media screen and (max-width: 900px) {
  .customers {
    margin: 0 24px 24px;
  }
}

@media screen and (max-width: 743px) {
  .container_arry {
    padding: 0 24px 30px;
  }

  .customers {
    margin: 0;
    margin-bottom: 24px;
  }

  .customers th.image,
  .customers td.image {
    position: static;
  }

  .customers th {
    display: none;
  }

  .customers .rows,
  .customers .statut,
  .customers .todo,
  .customers .action {
    display: none;
  }

  .customers td.image button {
    align-items: center;
  }
  .customers tr:hover,
  .customers tr:hover td.image,
  .customers td.image:hover {
    background-color: transparent;
  }
}
