.close_btn {
  display: none;
}

@media screen and (max-width: 743px) {
  .close_btn {
    position: absolute;
    top: 15px;
    left: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--noir);
    outline: none;
    background-color: #fff;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    border: 1px solid var(--border-color);
  }
  .close_btn svg {
    display: block;
    fill: none;
    height: 16px;
    width: 16px;
    stroke: currentcolor;
    stroke-width: 4;
    overflow: visible;
  }
}
