.main {
  max-width: 800px;
  min-height: 100vh;
  padding: 50px 24px;
  padding-left: 115px;
}

.main h1 {
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir6);
  margin-bottom: 30px;
}

.main h2 {
  font-size: 23px;
  font-weight: var(--font_heading_1_font-weight);
  margin: 20px 0;
  font-family: var(--font-family_heading);
}

.main h3 {
  font-size: 24px;
  font-weight: var(--font_heading_1_font-weight);
  font-family: var(--font-family_heading);
  margin: 20px 0 15px;
}

.main h4 {
  font-size: 18px;
  font-weight: var(--font_heading_1_font-weight);
  font-family: var(--font-family_heading);
}

.main strong {
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
}

.main p {
  margin: 15px 0;
  color: var(--noir4);
  font-size: 16px;
}

.main ul,
.main ol {
  margin-left: 45px;
  padding: 15px 0;
}

.main ul li,
.main ol li {
  padding: 5px 0;
}

.main a {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.main a.btn_action {
  display: inline-block;
  padding: 12px 20px;
  margin: 20px 0;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  text-decoration: none;
  color: #fff;
  border-radius: 8px;
  background: var(--btn_noir1);
}

.main a.btn_action:hover {
  background: var(--btn_noir2);
}

.main a.btn_action:active {
  transition: 0.3s;
  transform: scale(0.9);
}

@media screen and (max-width: 914px) {
  .main {
    padding: 50px 24px;
  }
}

@media screen and (max-width: 743px) {
  .main {
    padding: 24px 24px 50px;
  }

  .main h2 {
    font-size: 22px;
    margin-top: 0;
  }

  .main h3 {
    font-size: 17px;
  }
}
