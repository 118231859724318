.block {
  padding-top: 10px;
}

.block p {
  font-size: 13px;
  padding-bottom: 3px;
}

.redirect_link {
  display: inline-block;
  padding: 0 10px;
  border: 1px solid var(--noir);
  border-radius: 5px;
  color: var(--noir);
  font-size: 11px;
  text-decoration: none;
}

.redirect_link span {
  vertical-align: middle;
  display: inline-block;
  line-height: 1;
  margin-left: 6px;
}