/*---modal_content-----*/
.modal_content {
  position: absolute;
  max-height: 180px;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: rgb(0 0 0 / 28%) 0px 8px 28px;
  animation-duration: 400ms;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.modal_content {
  animation-name: modal_content_slideOutn;
}

.modal_content.active {
  animation-name: modal_content_slideIn;
}

@keyframes modal_content_slideIn {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: none;
  }
}

@keyframes modal_content_slideOutn {
  from {
    transform: none;
  }

  to {
    transform: translateY(-100%);
  }
}

.m_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  min-height: 48px;
}

.m_body {
  padding: 0 150px 20px;
}

.m_body h3.head {
  font-size: 20px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  text-align: center;
  margin-bottom: 20px;
}

.search_block_nav {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 270px 270px 1fr 155px;
  grid-gap: 10px;
  background: #fff;
  border: 1px solid rgb(221, 221, 221);
  padding: 10px;
  border-radius: 50px;
}

.submit_btn button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 50px;
  color: #fff;
  border: none;
  outline: none;
  background: var(--button1);
  border-radius: 30px;
  overflow: hidden;
  z-index: 2;
  padding: 0 20px;
  cursor: pointer;
  transition: 0.3s;
}

.submit_btn button span {
  display: inline-block;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: currentColor;
}

.submit_btn button::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--button2);
  z-index: -10;
  opacity: 0;
  transition: opacity 0.4s linear;
}

.submit_btn button:hover::before {
  opacity: 1;
}

.submit_btn button:active {
  transform: scale(0.9);
}

@media screen and (min-width: 1440px) {
  .m_body {
    max-width: 1315px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1211px) {
  .m_body {
    padding: 0 50px 20px;
  }
}

@media screen and (max-width: 1003px) {
  .m_body {
    padding: 0 24px 20px;
  }
}

@media screen and (max-width: 953px) {
  .modal_content {
    max-height: none;
    top: 10px;
    bottom: 0;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
  }

  .m_body {
    overflow-y: auto;
    height: 100vh;
    padding: 0 120px 20px;
  }

  @keyframes modal_content_slideIn {
    from {
      transform: translateY(100%);
    }

    to {
      transform: none;
    }
  }

  @keyframes modal_content_slideOutn {
    from {
      transform: none;
    }

    to {
      transform: translateY(100%);
    }
  }

  .search_block_nav {
    grid-template-columns: 1fr;
    padding: 30px 24px;
    grid-gap: 15px;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 16%);
  }
}

@media screen and (max-width: 743px) {
  .search_block_nav {
    border-radius: 20px;
  }

  .m_body {
    overflow-y: auto;
    height: 100%;
    padding: 0 24px 20px;
  }

  .m_body h3.head {
    font-size: 16px;
  }
}

@media screen and (max-width: 414px) {
  .m_body {
    padding: 0 15px 50px;
  }

  .search_block_nav {
    padding: 30px 20px;
  }
}
