.block {
  padding: 24px;
  border: 1px solid #dcdfe4;
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.block h2 {
  color: var(--noir);
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  padding-bottom: 24px;
}

/*-------content------*/
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/*-------body------*/
.body {
  width: 100%;
  max-width: 600px;
}

.input_block,
.loi_block {
  padding: 24px 0;
}

.input_block p {
  color: #717171;
  font-size: 13px;
  padding-bottom: 10px;
}

.loi_block p {
  color: var(--noir);
  font-size: 16px;
  padding-bottom: 20px;
}

.body label {
  display: inline-block;
  font-size: 16px;
  color: var(--noir3);
  cursor: pointer;
}

.body input[type="text"],
.body input[type="number"],
.body input[type="tel"],
.body select {
  box-sizing: border-box;
  padding: 0 15px;
  margin: 5px 0;
  width: 100%;
  height: 55px;
  font-size: 15px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.body input[type="text"]:focus,
.body input[type="number"]:focus,
.body input[type="tel"]:focus,
.body select:focus,
.body textarea:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.body input[type="number"]::-webkit-outer-spin-button,
.body input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.body textarea {
  width: 100%;
  height: 170px;
  resize: vertical;
  border-radius: 8px;
  padding: 20px;
  font-size: 16px;
  max-height: 50vh;
  min-height: 144px;
  text-overflow: ellipsis;
  appearance: none;
  line-height: 28px;
  overflow-y: auto;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.body .input_block.errorServver input[type="text"],
.body .input_block.errorServver input[type="number"],
.body .input_block.errorServver input[type="tel"],
.body .input_block.errorServver select {
  box-shadow: rgb(193, 53, 21) 0px 0px 0px 1px inset;
}

.body .input_block.errorServver input[type="text"]:focus,
.body .input_block.errorServver input[type="number"]:focus,
.body .input_block.errorServver input[type="tel"]:focus,
.body .input_block.errorServver select:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.input_block p.error {
  font-size: 15px;
  padding-top: 5px;
  color: rgb(193, 53, 21);
}

.input_block span {
  display: block;
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir9);
}

/*----create_link------*/
.create_link {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 15px;
  margin: 5px 0;
  width: 100%;
  height: 55px;
  font-size: 15px;
  border-radius: 8px;
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
  color: var(--noir);
}

.create_link input {
  border: none !important;
  outline: none;
  padding: 0 !important;
  box-shadow: none !important;
}

.create_link.focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.body .input_block.errorServver .create_link {
  box-shadow: rgb(193, 53, 21) 0px 0px 0px 2px inset;
}

/*----statut------*/
.checkox_block {
  display: flex;
  padding: 10px 0;
  gap: 15px;
  width: 70%;
}

.statut {
  display: flex;
  align-items: center;
  gap: 8px;
}

.statut svg.publier {
  height: 10px;
  width: 10px;
  fill: rgb(0, 138, 5);
}

.statut svg.masquer {
  height: 10px;
  width: 10px;
  fill: rgb(193, 53, 21);
}

.statut svg.desactiver {
  display: block;
  height: 14px;
  width: 14px;
  fill: rgb(193, 53, 21);
}

.statut h5 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.checkox_block label p {
  color: #717171;
  font-size: 14px;
  padding-bottom: 0;
}

.block_btn_link_payment {
  margin-bottom: 30px;
}

.btn_link {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

/*--------vue panoramique--------*/
.main_block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 30px;
}

/*--------chambre--------*/
.info_search {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 0;
}

.info_search .block_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info_search .block_flex h3 {
  font-size: 16px;
  font-weight: 400;
  color: var(--noir);
}

.info_search .block_flex .occup_count {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  text-align: center;
  gap: 5px;
}

.info_search .block_flex button {
  width: 32px;
  height: 32px;
  cursor: pointer;
  font-size: 19px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #999;
  color: var(--noir5);
  outline: none;
  touch-action: manipulation;
  background: rgb(255, 255, 255);
  border-radius: 50%;
}

.info_search .block_flex button:hover {
  border: 1px solid #333;
  color: var(--noir);
}

.block_flex button.disable {
  cursor: not-allowed;
  color: rgb(235, 235, 235);
  border-color: rgb(235, 235, 235);
  background: rgb(255, 255, 255);
}

.block_flex button.disable:hover {
  border: 1px solid #f1f1f1;
  color: #ddd;
  cursor: no-drop;
}

svg.decr {
  display: block;
  fill: none;
  height: 12px;
  width: 12px;
  stroke: currentcolor;
  stroke-width: 5.33333;
  overflow: visible;
}

/*--------tarif et disponibilite--------*/
.price {
  padding: 5px 0;
}

.price h5 {
  font-size: 16px;
}

.price p {
  font-size: 15px;
  padding-top: 10px;
  color: rgb(193, 53, 21);
}

.input_block.error input {
  border-color: rgb(193, 53, 21);
  outline-color: rgb(193, 53, 21);
}

.grid_system {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.checkbox_container {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.checkbox_container label {
  width: 100%;
  font-size: 15px;
  padding-left: 10px;
  cursor: pointer;
}

/*----Plage horaire pour les arrivées---*/
.body .input_block .select_block {
  margin-bottom: 10px;
}

/*----information pour les voyageurs---*/
.textarea_block {
  margin-top: 30px;
}

.textarea_block label {
  margin-bottom: 10px;
}

/*-----payment methods---*/

.section_head {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.checkox_container_payment {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding: 24px 0;
}

.checkox_container_payment:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.checkox_container_payment label {
  width: 100%;
  font-size: 16px;
  color: var(--noir);
  cursor: pointer;
}

.checkox_container_payment h5 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.checkox_container_payment p {
  color: #717171;
  font-size: 14px;
  padding-bottom: 0;
}

@media screen and (max-width: 974px) {
  .body,
  .checkox_block,
  .radio_block_statut {
    width: 100%;
  }
}
