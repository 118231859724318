.block_grid_1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  background: rgb(250, 250, 250);
}

.block_grid_1 .close_modal {
  display: none;
}

.block_grid_1 .navigation {
  display: flex;
  align-items: center;
  height: 70px;
  padding: 20px 30px;
}

.navigation h1.boite {
  color: var(--noir);
  font-size: 20px;
  font-weight: var(--font_display_3_font-weight);
}

/*-------left_bar-----*/
.block_grid_1 .left_bar {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  padding-bottom: 50px;
  overflow: auto;
}

.block_grid_1 .left_bar a {
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 3px;
  border-radius: 0 10px 10px 0;
  color: var(--noir3);
  font-size: 14px;
}

.block_grid_1 .left_bar a:hover {
  background: var(--border-color);
}

.block_grid_1 .left_bar a svg {
  margin-right: 8px;
}

.block_grid_1 .left_bar a.active {
  color: #fff;
  font-weight: var(--font_display_2_font-weight);
  background: rgb(124, 124, 124);
}

.block_grid_1 .left_bar a.active::after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  background: transparent;
  width: 5px;
  height: 100%;
  background: var(--noir);
}

.block_grid_1 .left_bar a.active:hover {
  background: rgb(124, 124, 124);
}

@media screen and (max-width: 800px) {
  .block_grid {
    grid-template-columns: 1fr;
  }

  .block_grid_1 {
    position: fixed;
    width: 100%;
    top: 0;
    left: -200%;
    bottom: 0;
    z-index: 999;
    background: #fff;
    transition: 0.3s ease;
  }

  .block_grid_1.active {
    left: 0;
  }

  .block_grid_1 .close_modal {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }

  .block_grid_1 .navigation {
    padding: 20px 24px;
  }

  .block_grid_1 .navigation {
    padding: 20px 30px;
  }

  .block_grid_1 .left_bar {
    padding: 10px 10px;
  }
}
