.reduction_content {
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 30px;
}

.bonne_affaire {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  margin-bottom: 30px;
  border: 1px solid #dcdfe4;
  border-radius: 0.5rem;
}

.affaire_text h4 {
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
}

.affaire_text p {
  font-size: 16px;
  color: var(--noir);
}

.bonne_affaire svg {
  display: block;
  height: 32px;
  width: 32px;
  fill: rgb(227, 28, 95);
  stroke: currentcolor;
}

@media screen and (max-width: 540px) {
  .bonne_affaire {
    padding: 10px;
  }

  .affaire_text h4 {
    padding-bottom: 7px;
    font-size: 15px;
  }

  .affaire_text p {
    font-size: 15px;
  }
}

/*--------modifier-reservation-----*/
.block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modifier_date {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modifier_date h5 {
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  line-height: 25px;
}

.modifier_date p {
  font-size: 16px;
  font-weight: var(--font_display_1_font-weight);
  line-height: 35px;
}

.modifier_date .button {
  padding: 0 15px;
  height: 35px;
  font-size: 15px;
}

@media screen and (max-width: 360px) {
  .modifier_date h5 {
    font-size: 16px;
  }

  .modifier_date p {
    font-size: 15px;
  }
}
