.navbar {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 0 24px;
  height: 80px;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 0px;
  background-color: #fff;
}
.navbar a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.navbar img {
  width: 150px;
}
.main {
  min-height: 100vh;
  max-width: 600px;
  margin: 20px auto 50px;
}
.container {
  border: 1px solid var(--border-color);
  border-radius: 16px;
  padding: 24px;
  padding-top: 0;
}
@media screen and (max-width: 743px) {
  .navbar {
    height: 64px;
  }
  .navbar img {
    width: 100px;
  }
  .main {
    margin-top: 10px;
  }
}
@media screen and (max-width: 600px) {
  .container {
    border: none;
    border-radius: none;
  }
}
