.description h1 {
  font-size: 20px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  padding-bottom: 24px;
}
.description textarea {
  width: 100%;
  height: 170px;
  resize: vertical;
  border-radius: 8px;
  padding: 20px;
  font-size: 16px;
  max-height: 50vh;
  min-height: 144px;
  text-overflow: ellipsis;
  line-height: 28px;
  overflow-y: auto;
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}
.description textarea:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}
.description p {
  padding-top: 10px;
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir5);
}
@media screen and (max-width: 743px) {
  .description textarea {
    padding: 16px;
    max-height: 25vh;
    min-height: 144px;
  }
}
