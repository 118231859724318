.block_link {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  width: 100%;
  background: transparent;
  border: none;
  font-weight: var(--font_display_2_font-weight);
  padding: 10px 15px;
  color: var(--noir);
  transition: 0.1s ease-in-out;
  cursor: pointer;
}

.block_link:hover {
  background: #f5f5f5;
}

.left_block {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.left_block svg,
.rigth_block {
  display: none;
}

.left_block svg {
  height: 24px;
  width: 24px;
  stroke: currentColor;
}

.left_block svg.host_mode,
.left_block svg.account {
  fill: none;
}

.left_block svg.message,
.left_block svg.reservation,
.left_block svg.notification {
  stroke-width: 0.2;
}

.left_block svg.logout_svg {
  stroke-width: 1;
}

.left_block svg.gerer,
.left_block svg.calendar {
  fill: currentColor;
}

.left_block svg.add_home,
.left_block svg.host_mode,
.left_block svg.account {
  stroke-width: 2;
}

.block_link h3 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  line-height: 20px;
}

.block_link h3 span {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgb(255, 56, 92);
  margin-top: -13px;
  margin-left: -7px;
  z-index: 100;
}

@media screen and (max-width: 540px) {
  .block_link {
    padding: 15px 0;
    font-size: 15px;
  }

  .block_link:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
  }

  .block_link:hover {
    background: transparent;
  }
  .left_block svg,
  .rigth_block {
    display: block;
  }
}
