.block_error {
  display: flex;
  align-items: center;
  gap: 8px;
}
.block_error p {
  font-size: 13px;
  color: #c13515;
  font-weight: var(--font_display_2_font-weight);
}
.invalid_date button {
  background-color: var(--btn_noir1);
  color: #fff;
  margin-top: 15px;
  padding: 0 30px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}
.invalid_date button:hover {
  background-color: var(--btn_noir2);
}
.invalid_date button:active {
  transform: scale(0.9);
}
