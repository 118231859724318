/*---a savoir-----*/
.section {
  padding-bottom: 30px;
}

@media screen and (max-width: 700px) {
  .section {
    padding-bottom: 20px;
  }
}

.section h3 {
  font-size: 22px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  padding: 24px 0;
  position: relative;
}

.asavoir {
  border-bottom: 1px solid #dcdfe4;
  padding: 30px 0 50px;
}

.asavoir .asavoir_block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.asavoir .asavoir_block h4 {
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  padding-bottom: 10px;
  color: var(--noir);
}
.block_content {
  display: flex;
  align-items: center;
  gap: 15px;
  color: var(--noir);
}
.asavoir .asavoir_block p {
  padding: 3px 0;
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow-y: hidden;
}

p.no {
  text-decoration: line-through;
}

.asavoir .savoir_btn button {
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
}
.asavoir .savoir_btn button:active {
  color: var(--noir9);
}
/*---modal_savoir-----*/
.modal_content {
  display: flex;
  flex-direction: column;
  width: 700px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.modal_content .m_body {
  overflow: auto;
  padding: 0 24px 50px;
}

.modal_content .m_body h2 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  padding-bottom: 24px;
}

.p_head_cancellation {
  margin-bottom: 20px;
}

.modal_content .infos {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
}

.infos .content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.infos .content h3 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 3px;
}

.modal_content .content p {
  font-size: 16px;
}

@media screen and (max-width: 743px) {
  .asavoir {
    padding: 0 0 24px;
  }

  .asavoir .asavoir_block {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .asavoir .asavoir_block p,
  .modal_content .content p {
    font-size: 15px;
  }

  .asavoir .savoir_btn button {
    margin-top: 0;
  }

  .modal_savoir {
    padding-top: 16px;
  }

  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }

  .modal_content .m_body::-webkit-scrollbar {
    display: none;
  }
}
