/*------account_container------*/
.account_container {
  padding: 40px 170px 50px;
  min-height: 100vh;
}

.account_container .account_header h1 {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.account_container .account_header p {
  padding: 10px 0 30px;
  font-size: 17px;
}

.account_container .account_header p strong {
  font-weight: var(--font_display_2_font-weight);
  font-size: 17px;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.rigth_block {
  height: 500px;
}

.account_container .desactive {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.account_container .desactive p {
  font-size: 14px;
}

.account_container .desactive button {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  font-size: 14px;
  display: inline-block;
  color: var(--noir);
  margin-top: 10px;
}

@media screen and (min-width: 1440px) {
  .account_container {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

@media screen and (max-width: 1280px) {
  .account_container {
    padding: 40px 100px 50px;
  }
}

@media screen and (max-width: 950px) {
  .account_container {
    padding: 40px 50px 50px;
  }
}

@media screen and (max-width: 743px) {
  .account_container {
    padding: 30px 24px 50px;
  }

  .account_container .account_header h1 {
    font-size: 20px;
    font-weight: var(--font_display_3_font-weight);
  }

  .account_container .account_header p {
    font-size: 16px;
    padding: 10px 0 40px;
  }

  .content {
    display: block;
  }

  .rigth_block {
    display: none;
  }
}
