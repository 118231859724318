/* container_load */
.container_load_liste {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.no_result {
  margin-top: 60px;
  text-align: center;
}

.no_result h2 {
  color: var(--noir);
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  line-height: 33px;
}

.no_result p,
.no_result a {
  color: var(--noir4);
}

.no_result a:hover {
  text-decoration: none;
}

/*-----------card------------*/
.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.card {
  text-decoration: none;
  display: flex;
  gap: 15px;
  border-radius: 10px;
  box-shadow: 0 0 0 1px var(--border-color);
  background: transparent;
  padding: 15px;
}

.card_img {
  width: 150px;
  height: 65px;
  background-color: var(--gray);
  border-radius: 10px;
  overflow: hidden;
}

.card_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card_body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.card_body h2 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.card_footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  margin-top: auto;
}

.card_date p {
  font-size: 12px;
  color: var(--noir8);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

@media screen and (max-width: 1195px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 743px) {
  .wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
  }

  .card_img {
    width: 90px;
    height: 60px;
  }

  .card_body {
    gap: 5px;
  }
}
