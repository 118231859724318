.block p {
  display: flex;
  gap: 5px;
  align-items: center;
  width: fit-content;
  padding: 6px 8px;
  border-radius: 5px;
}
.block p span {
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
}
.block p.incomplet {
  color: rgb(84 89 105);
  background-color: #ebeef1;
}
.block p.complet {
  color: #006908;
  background-color: #d7f7c2;
}
