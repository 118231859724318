/*-----connexion------*/
.connexion {
  padding: 150px 24px;
}
.connexion h2 {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}
.connexion p {
  padding: 18px 0;
}
.connexion button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 25px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  border: 1px solid var(--btn_noir1);
  outline: none;
  color: var(--noir);
  background: transparent;
  transition: 0.3s;
}
.connexion button:hover {
  background: #f1f1f1;
}
.connexion button:active {
  transform: scale(0.9);
}
