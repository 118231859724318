.block_center {
  padding: 30px 50px 50px;
  overflow-y: auto;
}

.block_center::-webkit-scrollbar {
  display: none;
}

.block_center .block_form {
  width: 100%;
  max-width: 564px;
  margin-left: auto;
  margin-right: auto;
}

.block_checkbox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin: 2px;
}

.block_checkbox label {
  cursor: pointer;
}

.block_checkbox label input[type="checkbox"] {
  display: none;
}

.block_checkbox label .block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 150px;
  border-radius: 10px;
  padding: 25px 20px;
  border: 1px solid var(--border-color);
  background: transparent;
  transition: 0.3s transform;
}

.block_checkbox label .block h2 {
  font-weight: 400;
  font-size: 16px;
  color: var(--noir);
}

.block_checkbox .block:hover {
  box-shadow: 0 0 0 1px #000000;
}

.block_checkbox .block:active {
  transform: scale(0.9);
}

.block_checkbox input[type="checkbox"]:checked~.block {
  background: var(--gray);
  border-color: transparent;
  box-shadow: var(--box-shadow-border);
}

@media screen and (max-width: 1010px) {
  .block_center {
    padding: 10px 0 100px;
  }

  .block_center .block_form {
    padding: 0 24px 24px;
  }
}

@media screen and (max-width: 882px) {
  .block_checkbox {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}

@media screen and (max-width: 743px) {
  .block_checkbox .block:hover {
    box-shadow: none;
  }
}