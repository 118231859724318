.modal_content {
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid var(--border-color);
}

.m_head h3 {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.modal_content .m_body {
  overflow-y: auto;
  padding: 50px 24px 24px;
}

.modal_content .m_body h4 {
  text-align: center;
  font-weight: 400;
  color: var(--noir);
  padding-bottom: 15px;
}

.detail_price {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.detail_price .details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail_price .details p:nth-child(1) {
  text-decoration: underline;
  cursor: pointer;
}

.detail_price .details p.price {
  text-decoration: none;
  cursor: default;
}

.detail_price .details p.promo {
  color: green;
  font-weight: var(--font_display_2_font-weight);
}

.detail_price .total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #dcdfe4;
  margin-top: 20px;
  padding-top: 20px;
}

.detail_price .total p {
  font-weight: var(--font_display_3_font-weight);
}

/*--------pupup-detail_price------*/
.detail_price .details {
  position: relative;
}

.detail_price .details button.left {
  outline: none;
  border: none;
  background: transparent;
  font-size: 16px;
}

.detail_price .descript {
  position: absolute;
  bottom: 20px;
  padding: 15px;
  background: rgb(255, 255, 255);
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 16px;
  visibility: hidden;
  transform: scale(0);
  opacity: 0;
  transition: 0.3s;
  transform-origin: left bottom;
}

.detail_price .descript.active {
  visibility: visible;
  transform: scale(1);
  opacity: 1;
}

.detail_price .descript p {
  text-align: left;
  font-size: 13px;
  color: var(--noir6);
  cursor: default !important;
  text-decoration: none !important;
}

.detail_price .descript span.close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  color: var(--noir);
  border-radius: 50%;
  margin-top: -5px;
  margin-right: -10px;
  outline: none;
  border: none;
  background: transparent;
  font-size: 16px;
  cursor: pointer;
}

.detail_price .descript span:hover {
  background: rgb(247, 247, 247);
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }

  .modal_content .m_body::-webkit-scrollbar {
    display: none;
  }
}
