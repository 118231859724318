/*-----voir toutes les photos----*/
.modal_content {
  position: absolute;
  background: #fff;
  display: flex;
  flex-direction: column;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px 16px 0 0;
  box-shadow: rgb(0 0 0 / 28%) 0px 8px 28px;
  animation-duration: 400ms;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.modal_content {
  animation-name: modal_content_slideOutn;
}

.modal_content.active {
  animation-name: modal_content_slideIn;
}

@keyframes modal_content_slideIn {
  from {
    transform: translateY(100%);
  }

  to {
    transform: none;
  }
}

@keyframes modal_content_slideOutn {
  from {
    transform: none;
  }

  to {
    transform: translateY(100%);
  }
}

.image_titre {
  display: flex;
  align-items: center;
  padding: 10px 50px;
}

.image_titre button.close {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: var(--noir);
  outline: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.image_titre button.close:hover {
  background: var(--gray);
}

.image_titre button.close:active {
  transition: 0.2s;
  transform: scale(0.9);
}

.image_titre button.close:focus-visible {
  border: none;
  background: var(--gray);
  box-shadow: 0 0 0 2px var(--noir), 0 0 0 4px rgba(255, 255, 255, 0.8);
}

.content {
  padding: 10px 50px 100px;
  overflow-y: auto;
}

.block_photo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  max-width: 1000px;
  margin: 0 auto;
}

.modal_content .card_block {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 330px;
  min-height: 1px;
  overflow: hidden;
  background-color: var(--gray);
}

.modal_content .card_block::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
  opacity: 0;
  transition: 0.1s ease-in;
  cursor: pointer;
}

.modal_content .card_block:hover::before {
  opacity: 1;
}

.block_photo img {
  display: inline-block;
  vertical-align: bottom;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .image_titre {
    padding: 10px 30px;
  }
}

@media screen and (max-width: 700px) {
  .modal_content {
    width: 100%;
  }

  .image_titre {
    padding: 10px;
  }

  .content {
    padding: 10px 15px 100px;
  }

  .block_photo {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 540px) {
  .modal_content .card_block {
    height: 270px;
  }
}

@media screen and (max-width: 414px) {
  .content {
    padding: 10px 0 50px;
  }
}
