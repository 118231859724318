.container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.block_checkbox {
  display: flex;
  height: 40px;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 8px;
  background: #fff;
}

.block_checkbox label {
  cursor: pointer;
}

.block_checkbox label input[type="checkbox"] {
  display: none;
}

.block_checkbox label span {
  position: relative;
  display: inline-block;
  border-radius: 50px;
  padding: 8px 20px;
  box-shadow: 0 0 0 1px var(--border-color) inset;
  background: transparent;
  font-weight: var(--font_display_1_font-weight);
  font-size: 15px;
  color: var(--noir);
  user-select: none;
  overflow: hidden;
}

.block_checkbox label span:hover {
  box-shadow: 0 0 0 1px var(--noir) inset;
}

.block_checkbox label input[type="checkbox"]:checked ~ span {
  background: var(--gray);
  box-shadow: var(--box-shadow-border) inset;
}

.all_filter {
  position: relative;
}

.all_filter button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  background: #fff;
  border-radius: 8px;
  padding: 8px 20px;
  border: none;
  box-shadow: 0 0 0 1px var(--border-color) inset;
  font-weight: var(--font_display_1_font-weight);
  font-size: 15px;
  color: var(--noir);
  cursor: pointer;
  transition: transform 0.3s;
}

.container button svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: #222222;
}

.container button:hover {
  background: rgb(247, 247, 247);
}

.container button:active {
  transform: scale(0.9);
}

.all_filter.active button {
  box-shadow: var(--box-shadow-border) inset;
}

.all_filter span.length {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: -4px;
  right: -4px;
  width: 18px;
  height: 18px;
  font-size: 10px;
  font-weight: var(--font_display_3_font-weight);
  border-radius: 50%;
  background: #000;
  color: #fff;
  border: 2px solid #fff;
}

.all_filter.active span.length {
  display: flex;
}

@media screen and (max-width: 743px) {
  .container {
    gap: 5px;
    padding-right: 39px;
  }

  .content {
    position: relative;
    width: 100%;
  }

  .content::before {
    display: block;
    content: "";
    position: absolute;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.9) 70%,
      rgb(255, 255, 255)
    );
    top: 0;
    left: -24px;
    bottom: 0;
    width: 24px;
    z-index: 10;
  }

  .content::after {
    display: block;
    content: "";
    position: absolute;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.9) 70%,
      rgb(255, 255, 255)
    );
    top: 0;
    right: 0;
    bottom: 0;
    width: 10px;
    z-index: 10;
  }

  .block_checkbox {
    gap: 7px;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-left: -24px;
    padding-left: 24px;
    overflow: auto;
  }

  .block_checkbox::-webkit-scrollbar {
    display: none;
  }

  .block_checkbox label span {
    font-size: 14px;
    padding: 5px 20px;
  }

  .block_checkbox label span:hover {
    box-shadow: 0 0 0 1px var(--border-color) inset;
  }

  .all_filter button span {
    display: none;
  }

  .all_filter span.length {
    top: -3px;
    right: -3px;
  }

  .all_filter button {
    padding: 0;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: linear-gradient(to left, rgb(255, 255, 255) 56.77%, rgba(255, 255, 255, 0) 94.47%);
  }
}
