.container {
  display: grid;
  grid-template-columns: 1fr 240px;
  grid-gap: 30px;
}

.profil_block_img {
  display: flex;
  gap: 30px;
}

/*------about_block------*/
.about_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about_name {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--noir);
}

.about_name h2 {
  font-size: 18px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.about_block h3 {
  font-size: 14px;
  color: var(--noir8);
}

.category_block p {
  font-size: 14px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.about_pc {
  margin-top: 8px;
}

.about_mobile {
  display: none;
}

@media screen and (max-width: 950px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

@media screen and (max-width: 743px) {
  .container {
    gap: 0;
  }

  .profil_block_img {
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
  }

  .about_block h2 {
    font-size: 20px;
  }

  .about_pc {
    display: none;
  }

  .about_mobile {
    display: block;
  }
}
