.main {
  padding: 130px 30px 50px;
  min-height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 30px;
}

.header button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  margin-left: -10px;
  color: var(--noir);
  font-size: 19px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.header button:hover {
  background: var(--gray);
  cursor: pointer;
}

.header h1 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 25px;
  color: var(--noir);
}

@media screen and (min-width: 1440px) {
  .main {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

@media screen and (max-width: 1200px) {
  .main {
    padding: 130px 24px 30px;
  }

  .header {
    gap: 10px;
  }

  .header h1 {
    font-size: 20px;
  }
}

@media screen and (max-width: 743px) {
  .main {
    padding-top: 90px;
  }
}

@media screen and (max-width: 540px) {
  .header {
    gap: 10px;
    padding-bottom: 15px;
  }
}

.container {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 50px;
}

/*----navigations-----*/
.navigations {
  position: sticky;
  top: 100px;
}

.left_block {
  display: flex;
  flex-direction: column;
}

.left_block button {
  position: relative;
  padding: 10px;
  margin-bottom: 3px;
  border: none;
  text-align: left;
  background-color: transparent;
  color: var(--noir);
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 0 10px 10px 0;
}

.left_block button:hover {
  background-color: var(--gray);
}

.left_block button.active {
  color: #fff;
  font-weight: var(--font_display_2_font-weight);
  background-color: rgb(124, 124, 124);
}

.left_block button.active::after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  background: transparent;
  width: 5px;
  height: 100%;
  background: #000;
}

.image {
  width: 100%;
  height: 200px;
  background-color: var(--gray);
  border-radius: 15px;
  overflow: hidden;
  margin-top: 24px;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*----block_rigth-----*/
.block h2 {
  color: var(--noir);
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
}

.content {
  padding: 24px 0;
  border-bottom: 1px solid var(--border-color);
}

.content h3 {
  color: var(--noir);
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  margin-bottom: 5px;
}

.content h4 {
  color: var(--noir);
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  margin: 10px 0;
}

.content .demande {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}

.content .demande svg {
  display: block;
  height: 16px;
  width: 16px;
}

.content .demande svg.no {
  fill: var(--border-color);
}

.content .demande svg.yes {
  fill: #008a05;
}

.content .demande span {
  font-size: 14px;
  color: var(--noir);
}

.content p {
  color: #717171;
  font-size: 15px;
  margin-bottom: 5px;
}

.content a {
  color: var(--noiir);
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
}

.content .btn_block {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.dates_block {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.content button {
  background-color: transparent;
  border: 1px solid var(--noir);
  border-radius: 8px;
  margin-top: 8px;
  padding: 10px 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
  transition: 0.3s;
}

.content button:hover {
  background-color: var(--gray);
}

.content button:active {
  transform: scale(0.9);
}

.main button.delete {
  background-color: #d33;
  color: #fff;
  border: 1px solid #d33;
}

.main button.delete:hover {
  background-color: rgb(224, 19, 19);
}

.commentes button.open_commente {
  background-color: transparent;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  margin-left: -15px;
  text-decoration: underline;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
}

.commentes button.open_commente:hover,
.commentes button.icon:hover:hover {
  background-color: var(--gray);
}

.commentes button.open_commente:active,
.commentes button.icon:active:active,
.content .sup a:active,
.content .sup button:active {
  transition: 0.3s;
  transform: scale(0.9);
}

.commentes {
  display: flex;
  align-items: center;
}

.commentes button.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
}

.secure {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 30px;
  padding: 24px 0;
}

.secure p {
  color: #717171;
  font-size: 14px;
}

.content .sup a {
  display: inline-block;
  margin-top: 8px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
  transition: 0.3s;
}

.content .sup a:hover {
  color: #717171;
}

@media screen and (max-width: 743px) {
  .container {
    display: block;
  }

  .navigations {
    position: none;
    top: auto;
  }

  .image {
    width: 80px;
    height: 60px;
    margin-bottom: 24px;
    border-radius: 8px;
  }

  .left_block {
    flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 10px;
  }

  .left_block button {
    background-color: var(--gray);
    margin-left: 5px;
    border-radius: 10px;
  }

  .left_block button.active {
    background-color: var(--btn_noir1);
  }

  .left_block button.active::after {
    display: none;
  }

  .left_block button.active:hover {
    background-color: var(--btn_noir2);
  }
}
