/*----------pages redirect good------*/
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  overflow: hidden;
}

.container .good_logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
}

.container .good_logo a {
  display: flex;
  align-items: center;
}

.good_logo h1 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  margin-bottom: 3px;
}

.progress_bar_container {
  position: relative;
  width: 150px;
  height: 8px;
  background: #ddd;
  border-radius: 4px;
}

.progress_bar_container span {
  position: absolute;
  height: 100%;
  background: var(--noir);
  border-radius: 4px;
}
/*----------.block left------*/
.left {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.block_center {
  padding: 30px 50px 50px;
  overflow-y: auto;
}

.block_center::-webkit-scrollbar {
  display: none;
}

.block_center .block_form {
  width: 100%;
  max-width: 464px;
  margin-left: auto;
  margin-right: auto;
}

/*----------.formulaire------*/
.step_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 50px;
  background-color: rgba(255, 255, 255);
  border-top: 1px solid rgb(221, 221, 221);
  z-index: 500;
}

.step_buttons button.retour {
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  padding: 10px;
  margin-left: -10px;
  color: var(--noir);
  text-decoration: underline;
  cursor: pointer;
}

.step_buttons button.retour:hover {
  background: var(--gray);
}

.step_buttons button.next {
  position: relative;
  border: none;
  outline: none;
  color: #fff;
  background: var(--btn_noir1);
  padding: 0 25px;
  width: 110px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.step_buttons button.next:hover {
  background: var(--btn_noir2);
}

.step_buttons button.next:active {
  transform: scale(0.9);
}

.step_buttons button.next.disable {
  background: rgb(226, 226, 226);
  color: #fff;
  cursor: no-drop;
}

.step_buttons button.loading {
  border: none;
  outline: none;
  background: #e2e2e2;
  padding: 0 25px;
  width: 110px;
  height: 48px;
  border-radius: 8px;
  color: #fff;
  cursor: no-drop;
  transition: 0.3s;
}

/*--buttons publish--*/
.step_buttons button.publish {
  position: relative;
  border: none;
  outline: none;
  color: #fff;
  background: var(--button1);
  z-index: 2;
  overflow: hidden;
  padding: 0 25px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
}

.step_buttons button.publish::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--button2);
  z-index: -10;
  opacity: 0;
  transition: opacity 0.4s linear;
}

.step_buttons button.publish:hover::before {
  transition: 0.1s;
  opacity: 1;
}

.step_buttons button.publish.disable {
  background: rgb(226, 226, 226);
  color: #fff;
  cursor: no-drop;
}

.step_buttons button.publish.disable:hover::before {
  display: none;
}

.step_buttons button.publish:active {
  transition: 0.3s;
  transform: scale(0.9);
}

/*****container right-----*/
.right_block {
  width: 100%;
  height: 100vh;
  padding: 30px 50px;
  background-color: #000;
}

.right_head {
  display: flex;
  justify-content: flex-end;
}
.right_head_buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.right_head .btn_action {
  display: inline-block;
  background-color: #fff;
  color: var(--noir);
  text-decoration: none;
  border: none;
  outline: none;
  padding: 8px 20px;
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 40px;
  transition: 0.2s ease-in-out;
}

.right_head .btn_action:hover {
  background-color: var(--gray);
}

.right_head .btn_action:active {
  transform: scale(0.9);
}

.description {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 464px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.description_text {
  transform: translateY(-100px);
}

.right_block .description h1 {
  color: #fff;
  font-size: 40px;
  font-weight: var(--font_heading_1_font-weight);
  font-family: var(--font-family_heading);
}

.right_block .description p {
  color: #fff;
  font-size: 20px;
}

@media screen and (max-width: 900px) {
  .container .good_logo {
    padding: 20px 24px;
  }

  .block_center {
    padding: 10px 0 100px;
  }

  .block_center .block_form {
    padding: 0 24px 24px;
  }

  .step_buttons {
    padding: 15px 24px;
  }

  .right_block {
    padding: 24px;
  }

  .description_text {
    transform: translateY(0);
  }
}

@media screen and (max-width: 743px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }

  .container .good_logo {
    padding: 10px 24px 24px;
  }

  .container .good_logo a {
    display: none;
  }

  .step_buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .right_block {
    height: 100%;
    max-height: 40vh;
  }
}

@media screen and (max-width: 540px) {
  .right_block .description h1 {
    font-size: 22px;
  }
  .right_block .description p {
    font-size: 15px;
  }
}

/*------annimation-------*/
.annimcontent {
  animation-duration: 1s;
  animation-name: slidein;
  overflow: hidden;
}

@keyframes slidein {
  from {
    transform: translateY(100px);
    opacity: 0;
  }

  to {
    transform: none;
    opacity: 1;
  }
}
