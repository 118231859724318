.search_container {
  margin: 30px 0;
  display: flex;
}

.flex_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.sponsor_card {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 12px;
  padding: 20px;
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
}

.wrapper_img_profile {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  text-decoration: none;
}

.wrapper_img_profile .image_block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #ddd;
  border: 0;
  outline: none;
  overflow: hidden;
}

.wrapper_img_profile .image_block svg {
  display: block;
  height: 100%;
  width: 100%;
  fill: #999;
  background: #fff;
  stroke-width: 3;
}

.wrapper_img_profile .image_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper_name h2 {
  font-size: 16px;
  display: table-cell;
  vertical-align: middle;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.wrapper_name p {
  font-size: 12px;
  color: var(--noir8);
  line-height: 13px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.sponsor_card .progress_text {
  font-size: 13px;
}

.progress_block {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 10px;
}

.progress_step {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: var(--gray-hover);
}

.progress_step.active {
  background-color: var(--noir);
}

.footer_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-top: 15px;
}

.footer_block p {
  font-size: 14px;
  color: var(--noir);
}

.send_message {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: var(--tertiary-background);
  color: var(--noir);
  height: 40px;
  width: 40px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s;
}

.send_message:hover {
  background: var(--tertiary-background-hover);
}

.send_message:active {
  transform: scale(0.9);
}

.progress_footer_date {
  margin-top: 5px;
  font-size: 13px;
  color: var(--noir8);
}

.container .home_link {
  display: inline-flex;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-top: 50px;
}

@media screen and (max-width: 990px) {
  .flex_container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 540px) {
  .search_block {
    width: 100%;
  }

  .flex_container {
    grid-template-columns: 1fr;
  }

  .sponsor_card {
    padding: 16px;
  }
}
