.container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.header_title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.header_title a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: none;
  background: transparent;
  margin-left: -15px;
  color: var(--noir);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: transform 3s;
}

.header_title a:hover {
  background: var(--gray);
  cursor: pointer;
}

.header_title a:active {
  transform: scale(0.9);
}

.container h1 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  margin-bottom: 5px;
  color: var(--noir);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-y: hidden;
}

.container .actions {
  display: flex;
  margin-left: -15px;
}

.actions a,
.actions button,
.search_block a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background: transparent;
  color: var(--noir);
}

.search_block a {
  background-color: var(--gray);
}

.search_block a:hover {
  background-color: var(--gray-hover);
}

.actions button {
  cursor: default;
}

.actions a:hover {
  background-color: var(--gray);
}

.actions svg.masquer {
  height: 10px;
  width: 10px;
  fill: rgb(193, 53, 21);
}

.actions svg.publier {
  height: 10px;
  width: 10px;
  fill: rgb(0, 138, 5);
}

.actions svg.desactiver {
  display: block;
  height: 14px;
  width: 14px;
  fill: rgb(193, 53, 21);
}

.actions svg.book_now {
  display: block;
  height: 16px;
  width: 16px;
  fill: rgb(255, 175, 15);
}

.actions svg.not_book_now {
  display: block;
  height: 16px;
  width: 16px;
  fill: #717171;
}

.actions svg.calendar {
  display: block;
  height: 16px;
  width: 16px;
  fill: currentColor;
  stroke: currentColor;
}

.actions svg.preview {
  display: block;
  height: 16px;
  width: 16px;
  fill: currentcolor;
}
