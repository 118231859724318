.sidenav {
  position: absolute;
  top: 60px;
  right: 0px;
  width: 235px;
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  padding: 15px 0;
  border-radius: 12px;
  z-index: 1000;
  background: #fff;
  transition: 0.3s ease-in-out;
  box-shadow: rgb(0 0 0 / 12%) 0px 2px 16px;
}

.side_head {
  display: none;
  padding-bottom: 15px;
}

@media screen and (max-width: 540px) {
  .sidenav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    max-height: 100dvh;
    height: 100dvh;
    padding: 24px 24px 50px;
    border-radius: 0;
    box-shadow: none;
  }

  .side_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .side_head h1 {
    font-size: 20px;
    font-weight: var(--font_display_3_font-weight);
  }
}
