.destination_detail_block {
  padding: 0 50px;
}

@media screen and (min-width: 1440px) {
  .destination_detail_block {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

@media screen and (max-width: 900px) {
  .destination_detail_block {
    padding: 0 24px;
  }
}