.question {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.question h2 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 24px;
}

.question .head_p {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 10px;
}

.grid_system {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkox_contener {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  padding: 5px 0;
}

.checkox_contener label {
  width: 100%;
  font-size: 15px;
  cursor: pointer;
}

.checkox_contener span.description {
  display: block;
  font-size: 13px;
  color: var(--noir7);
  margin-top: 5px;
}

.checkox_contener input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(176, 176, 176);
  background: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 50%;
  vertical-align: top;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  height: 22px;
  width: 22px;
  margin: 0px;
}

.checkox_contener input[type="radio"]:hover {
  border-color: rgb(34, 34, 34);
}

.checkox_contener input[type="radio"]:checked {
  border-color: rgb(34, 34, 34);
  border-width: 7px;
  background: white;
}

.checkox_contener input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 23px;
  height: 23px;
  padding: 0;
  margin: 0px;
  cursor: pointer;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #c6c6c6;
}

.checkox_contener input[type="checkbox"]:hover {
  border: 1px solid #333;
}

.checkox_contener input[type="checkbox"]:after {
  content: "";
  display: none;
  position: absolute;
  top: 2px;
  left: 8px;
  width: 5px;
  height: 14px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.checkox_contener input[type="checkbox"]:checked {
  background: var(--btn_noir1);
  border: 1px solid #333;
}

.checkox_contener input[type="checkbox"]:checked:after {
  display: block;
}

/* infos_container */
.infos_container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.infos_section {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.infos_section h3 {
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 5px;
}

.infos_section p {
  font-size: 14px;
  color: var(--noir);
}

.infos_section svg {
  display: block;
  height: 24px;
  width: 24px;
}

.infos_section svg.verify,
.infos_section svg.calendar {
  fill: var(--star_color);
}

.infos_section svg.parameter {
  fill: none;
  stroke: var(--noir);
  stroke-width: 2;
}

@media screen and (max-width: 743px) {
  .question h2 {
    font-size: 19px;
  }

  .infos_section h3 {
    font-size: 16px;
  }
}
