.container {
  position: fixed;
  bottom: 0;
  left: var(--sidebar-with-hidded);
  right: 0px;
  background: #fff5f5;
  z-index: 2010; /*superieur a la cookie-consent z-index:2000 */
}

.content {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 115px;
}

@media screen and (min-width: 1440px) {
  .content {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

.notif_message {
  display: flex;
  gap: 15px;
}

.container .notif_message h1 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 16px;
  color: var(--noir4);
  margin-bottom: 5px;
}

.container .notif_message p {
  font-size: 14px;
  color: var(--noir4);
  text-align: left;
}

.container button.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: none;
  border: none;
  color: var(--noir);
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.container button.icon:hover {
  background-color: #ffe9e9;
}

.container .icon i {
  font-size: 16px;
  color: var(--noir4);
  cursor: pointer;
}

@media screen and (max-width: 912px) {
  .content {
    padding: 20px 50px;
  }
}

@media screen and (max-width: 743px) {
  .content {
    gap: 2px;
  }

  .content {
    padding: 20px 24px;
  }
}
