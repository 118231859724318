.connexion_button_block h3 {
  padding: 20px 0;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  font-size: 19px;
}
.connexion_button_block button.cotunue_button {
  position: relative;
  width: 100%;
  border: none;
  outline: none;
  color: #fff;
  background: var(--button1);
  padding: 0 20px;
  height: 50px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  overflow: hidden;
  z-index: 2;
}
.connexion_button_block button.cotunue_button::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--button2);
  z-index: -10;
  opacity: 0;
  transition: opacity 0.4s linear;
}
.connexion_button_block button.cotunue_button:hover::before {
  opacity: 1;
}
.connexion_button_block button.cotunue_button:active {
  transform: scale(0.9);
}
