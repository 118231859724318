svg.fav_svg {
  cursor: pointer;
  display: block;
  height: 16px;
  width: 16px;
  stroke-width: 2;
  overflow: visible;
  fill: #ff385c;
  stroke: #ff385c;
}

svg.no_fav_svg {
  cursor: pointer;
  display: block;
  height: 16px;
  width: 16px;
  stroke-width: 2;
  overflow: visible;
  fill: #fff;
  stroke: var(--noir);
}

@media screen and (max-width: 700px) {

  svg.fav_svg,
  svg.no_fav_svg {
    stroke-width: 4;
    stroke: currentColor;
  }

  svg.fav_svg {
    fill: #ff385c;
    stroke: #ff385c;
  }

  svg.no_fav_svg {
    fill: #fff;
    stroke: currentcolor;
  }
}