/* container_load */
.container_load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.voyage_container {
  padding: 30px 50px 50px;
  min-height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 30px;
}

.header button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  margin-left: -10px;
  color: var(--noir);
  font-size: 19px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.header button:hover {
  background: var(--gray);
  cursor: pointer;
}

.header h1 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 25px;
  color: var(--noir);
}

.header h1 span {
  display: inline-flex;
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
}

@media screen and (min-width: 1440px) {
  .voyage_container {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

/*------main-----*/
.main {
  max-width: 700px;
  margin: 0 auto;
  padding: 24px 0 10px;
}

.le_pourquoi {
  padding: 24px 0 10px;
}
.le_pourquoi h4 {
  font-size: 20px;
  font-weight: var(--font_display_2_font-weight);
}
.le_pourquoi p {
  font-size: 16px;
  margin: 7px 0 24px;
}

.radios_button {
  display: flex;
  gap: 15px;
  margin: 10px 0;
}

.radios_button label {
  font-size: 15px;
  cursor: pointer;
}
.le_pourquoi input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 20px;
  height: 20px;
  padding: 10px;
  margin: 0px;
  cursor: pointer;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #c6c6c6;
}

.le_pourquoi input[type="radio"]:after {
  position: absolute;
  content: "";
  display: none;
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
}

.le_pourquoi input[type="radio"]:hover {
  border: 1px solid #333;
}

.le_pourquoi input[type="radio"]:checked {
  background-color: #000;
}

.le_pourquoi input[type="radio"]:checked:after {
  display: block;
}

.le_pourquoi select {
  height: 40px;
  width: 100%;
  font-size: 14px;
  background: transparent;
  cursor: pointer;
  padding: 0 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: none;
  outline: none;
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
  color: var(--noir);
  background-clip: padding-box;
}

.le_pourquoi select.first {
  margin-top: 10px;
}

.le_pourquoi textarea {
  width: 100%;
  height: 200px;
  resize: vertical;
  padding: 10px 20px;
  font-size: 14px;
  background: #fff;
  margin-top: 10px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.le_pourquoi select:focus,
.le_pourquoi textarea:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-top: 30px;
}

.buttons button {
  position: relative;
  border: none;
  outline: none;
  color: #fff;
  background: var(--button1);
  overflow: hidden;
  z-index: 2;
  padding: 0 20px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.buttons button::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--button2);
  z-index: -10;
  opacity: 0;
  transition: opacity 0.4s linear;
}

.buttons button:hover::before {
  opacity: 1;
}

.buttons button.disabled {
  background: rgb(226, 226, 226);
  color: #fff;
  cursor: no-drop;
}

.buttons button.disabled:hover::before {
  opacity: 0;
}

.buttons a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: var(--font_display_2_font-weight);
  font-size: 16px;
  color: var(--noir4);
  border: 1px solid #999;
  background: #fff;
  transition: 0.3s;
}

.buttons a:hover {
  background: #f1f1f1;
}

.buttons button:active,
.buttons a:active {
  transform: scale(0.9);
}

@media screen and (max-width: 850px) {
  .buttons {
    flex-direction: column;
  }
}

@media screen and (max-width: 743px) {
  .voyage_container {
    padding: 20px 24px 50px;
  }
}

@media screen and (max-width: 540px) {
  .header {
    gap: 15px;
    padding-bottom: 10px;
  }
  .header h1 {
    font-size: 20px;
  }

  .le_pourquoi {
    padding: 24px 0 10px;
  }
}
