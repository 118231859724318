.block_center {
  padding: 30px 50px 50px;
  overflow-y: auto;
}

.block_center::-webkit-scrollbar {
  display: none;
}

.block_center .block_form {
  position: relative;
  width: 100%;
  max-width: 564px;
  margin-left: auto;
  margin-right: auto;
}

/*----loading uploads------*/
.loading_upload {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.945);
  z-index: 100;
}

/*----photo--couverture ------*/
.couverture {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 330px;
  background: var(--gray);
  border-radius: 5px;
  margin-bottom: 15px;
  overflow: hidden;
}

.couverture img {
  display: inline-block;
  vertical-align: bottom;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.couverture .info {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 15px 0;
}

.couverture .info h1 {
  background: #fff;
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  color: #000;
  padding: 8px;
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgb(0 0 0 / 18%) 0px 2px 4px;
}

/*------lists photos------*/
.lists {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  padding-bottom: 100px;
  user-select: none;
}

.item_img {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 200px;
  background-color: var(--gray);
}

.item_img.incopatible::before {
  position: absolute;
  display: block;
  content: "";
  inset: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.item_img img {
  display: inline-block;
  vertical-align: bottom;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.item_img button.first {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: rgba(255, 255, 255, 0.9);
  color: var(--noir);
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-clip: padding-box;
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgb(0 0 0 / 18%) 0px 2px 4px;
  transition: 0.2s ease-in-out;
  z-index: 2;
}

.item_img button.first:hover {
  background-color: #fff;
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
    rgb(0 0 0 / 12%) 0px 6px 16px;
}

.item_img svg.tree_points,
.item_img svg.remove_incompatible {
  display: block;
  height: 16px;
  width: 16px;
}

.dropdown_menu {
  position: relative;
  z-index: 100;
}

.dropdown_menu .option {
  position: absolute;
  top: 25px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: rgb(0 0 0 / 12%) 0px 2px 16px;
  border-radius: 8px;
  width: 200px;
  padding: 8px 0;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 16px;
  overflow: hidden;
}

.dropdown_menu .option span {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  text-align: left;
  text-decoration: none;
  background: #fff;
  color: var(--noir3);
  cursor: pointer;
}

.dropdown_menu .option span:hover {
  background: #f1f1f1;
}

.item_list .addLegende {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.item_list .addLegende p {
  font-size: 15px;
  color: var(--noir4);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.item_list .addLegende button {
  background: transparent;
  text-decoration: underline;
  font-size: 14px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  margin-top: 8px;
  border: none;
}

.item_list .message_error p {
  font-size: 13px;
  color: #c13515;
  font-weight: var(--font_display_2_font-weight);
  margin-top: 8px;
}

@media screen and (max-width: 1010px) {
  .block_center {
    padding: 10px 0 0;
  }

  .block_center .block_form {
    padding: 0 24px 24px;
  }
}

@media screen and (max-width: 743px) {
  /*----loading ------*/
  .loading_upload {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
  }

  .lists {
    grid-template-columns: repeat(1, 1fr);
  }

  .item_img {
    height: 220px;
  }
  .dropdown_menu .option span:hover {
    background: #fff;
  }
  .dropdown_menu .option span:active {
    background: #f1f1f1;
  }
}
