.verify_button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  text-decoration: none;
  margin: 10px 0;
  border: 1px solid var(--noir);
  color: var(--noir);
  background: transparent;
  padding: 0 20px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
}

.verify_button span {
  display: inline-block;
  color: currentColor;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
}

.verify_button:hover {
  background: var(--gray);
}

.verify_button:active {
  transition: 0.3s;
  transform: scale(0.9);
}

.verify_button.disabled {
  border: 1px solid var(--noirc);
  color: var(--noirc);
  cursor: default;
}

.verify_button.disabled:hover {
  background: transparent;
}

.verify_button.disabled:active {
  transform: scale(1);
}

.about_me_desc p {
  font-size: 15px;
}
