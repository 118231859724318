.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 24px;
}

/*-------loading-----*/
.loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

/*------container-----*/
.container,
.step_container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 100px 24px 150px;
}

.block_2 {
  display: flex;
  align-items: center;
  height: 100px;
  border-radius: 14px;
  border: 1px solid var(--border-color);
  overflow: hidden;
}

.block_2 .photo {
  width: 130px;
  height: 100%;
  background-color: var(--gray);
}

.block_2 .photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: middle;
}

.block_2 .content {
  padding: 20px;
}

.block_2 .content h4 {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.block_2 .content p {
  color: var(--noir6);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.block_1 .head h1 {
  font-size: 22px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
  margin-bottom: 15px;
}

.block_1 .head p {
  font-size: 14px;
  color: var(--noir4);
}

.block_1 .link_block {
  text-decoration: none;
  display: flex;
  align-items: center;
  text-align: left;
  gap: 15px;
  color: var(--noir);
  padding: 30px 0;
  background-color: transparent;
  border: none;
}

.block_1 .link_block:not(:first-child) {
  border-top: 1px solid var(--border-color);
}

.block_1 .link_block h2 {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  padding-bottom: 10px;
}

.link_block p {
  font-size: 14px;
  color: var(--noir6);
  padding-bottom: 10px;
}

.link_block .status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.link_block .status h3 {
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
}

.link_block .status h3.error_text {
  color: #c13515;
}

.block_1 .link_block svg.checkSgv {
  display: block;
  height: 16px;
  width: 16px;
  fill: #008a05;
}

/*-----footer----*/
.step_buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  border-top: 1px solid var(--border-color);
}
.step_container {
  padding: 24px;
  display: flex;
  align-items: center;
}
.step_buttons button.next {
  position: relative;
  border: none;
  outline: none;
  color: #fff;
  background: var(--button1);
  z-index: 2;
  overflow: hidden;
  padding: 0 25px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.step_buttons button.next::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--button2);
  z-index: -10;
  opacity: 0;
  transition: opacity 0.4s linear;
}

.step_buttons button.next:hover::before {
  transition: 0.1s;
  opacity: 1;
}

.step_buttons button.next.disable {
  background: rgb(226, 226, 226);
  color: #fff;
  cursor: no-drop;
}

.step_buttons button.next.disable:hover::before {
  display: none;
}

.step_buttons button.loading {
  border: none;
  outline: none;
  background: rgb(226, 226, 226);
  width: 140px;
  height: 48px;
  border-radius: 8px;
  color: #fff;
  cursor: no-drop;
  transition: 0.3s;
}

@media screen and (max-width: 743px) {
  .navbar {
    height: 64px;
  }

  .container {
    gap: 24px;
    padding: 90px 24px 150px;
  }

  .block_2 {
    width: 100%;
    height: 60px;
    border-radius: 5px;
  }

  .block_2 .photo {
    width: 70px;
  }

  .step_container {
    padding: 16px 24px;
  }

  .step_buttons button.next,
  .step_buttons button.loading {
    width: 100%;
  }
}
