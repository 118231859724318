.modal_content {
  display: flex;
  flex-direction: column;
  width: 600px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid var(--border-color);
}

.m_head h3 {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.m_body {
  height: 100%;
  padding: 20px 24px;
  overflow-y: auto;
}

.max_and_min_container {
  font-size: 15px;
}

.infos {
  margin-bottom: 10px;
}

.infos p.error {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #c13515;
}

.verif_block_detail {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dates_content {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

/*------------date_content search---------*/
.date_content {
  position: relative;
  width: 100%;
}

.date_content h2 {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 3px;
}

.date_content .selecte {
  display: flex;
  align-items: center;
}

.date_content .selecte svg.calendar {
  margin-right: 10px;
  display: block;
  height: 16px;
  width: 16px;
  fill: var(--noirc);
  stroke: var(--noirc);
}

.date_content .selecte p {
  font-size: 15px;
}

.date_content .selecte {
  height: 50px;
  padding: 0 15px;
  border-radius: 30px;
  background-color: var(--gray);
  color: var(--noir5);
  cursor: pointer;
  border: 1px solid rgb(233, 233, 233);
}

.date_content .selecte:hover {
  background-color: #e6e6e6;
}

.date_checkin,
.date_checkout {
  position: absolute;
  z-index: 10;
  top: 80px;
  width: 180%;
  max-height: 57vh;
  overflow-y: auto;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 30px;
  box-shadow: rgb(0 0 0 / 20%) 0px 6px 20px;
  background: #fff;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  transition: 0.1s ease-in-out;
}

.date_checkin {
  left: 0px;
}

.date_checkout {
  right: 0px;
}

.calendar_head {
  padding: 0 24px 15px;
}

.calendar_head h3 {
  font-size: 17px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.calendar_head p {
  font-size: 15px;
  color: var(--noir6);
}

.max_and_min {
  display: none;
  margin-top: 10px;
}

.calendar_head p.error {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #c13515;
}

.date_checkin.active,
.date_checkout.active {
  visibility: visible;
  opacity: 1;
}

.tileClassName:disabled {
  text-decoration: line-through;
}

.date_checkin .close,
.date_checkout .close {
  display: flex;
  justify-content: flex-end;
  padding: 24px 24px 0;
}

.date_checkin .close button.close_only,
.date_checkout .close button.close_only {
  color: var(--noir);
  background: transparent;
  text-decoration: underline;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  cursor: pointer;
}

@media screen and (max-width: 743px) {
  .modal_content {
    width: 100%;
    height: 100dvh;
    max-height: 99dvh;
  }

  .m_head {
    min-height: 48px;
  }
}

@media screen and (max-width: 540px) {
  .date_checkin,
  .date_checkout {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    border: 0;
    border-radius: 0;
    padding: 30px 24px 100px;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    z-index: 100;
    visibility: visible;
    opacity: 1;
    transform: translateY(200%);
    transition: 0.3s ease-in-out;
  }

  .calendar_head {
    padding: 0 0 15px;
  }

  .max_and_min {
    display: block;
  }

  .date_checkin.active,
  .date_checkout.active {
    transform: none;
  }

  .date_checkin .close,
  .date_checkout .close {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 24px;
    background-color: var(--modal-footer-background-color);
  }

  .date_checkin .close button.close_only,
  .date_checkout .close button.close_only {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: var(--btn_noir1);
    text-decoration: none;
    height: 48px;
    padding: 0 25px;
    border-radius: 8px;
    outline: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
}

.content_price {
  display: flex;
  flex-direction: column;
  width: 300px;
  max-width: 100%;
  padding-top: 24px;
}

.content_price h3 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 20px;
  color: var(--noir);
  margin-bottom: 10px;
}

.price_block {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  padding: 10px 0;
}

.price_block h4 {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.price_block strong {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.price_block strong.reduce_price {
  color: green;
}

.message {
  margin-top: 15px;
}

.message label {
  font-size: 15px;
}

.message textarea {
  box-sizing: border-box;
  width: 100%;
  height: 150px;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  resize: vertical;
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.message textarea:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.newpayment {
  margin-top: 10px;
}

.newpayment p {
  font-size: 15px;
}

.newpayment p strong {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin: 0 5px;
}

.submit_btn button {
  border: none;
  color: #fff;
  margin-top: 30px;
  padding: 0 20px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.submit_btn button {
  background: var(--btn_noir1);
}

.submit_btn button:hover {
  background: var(--btn_noir2);
}

.submit_btn button:active {
  transform: scale(0.9);
}

.submit_btn button.disable,
.submit_btn button.loading {
  background: rgb(226, 226, 226);
  cursor: no-drop;
}

.submit_btn button.disable:hover,
.submit_btn button.loading:hover {
  background: rgb(226, 226, 226);
}

@media screen and (max-width: 540px) {
  .content_price {
    width: 100%;
  }
}
