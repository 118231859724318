.block_filter button {
  position: relative;
  background: transparent;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid var(--noir);
  padding: 5px 10px;
  transition: 0.3s all;
}
.block_filter button:hover {
  background-color: var(--gray);
}
.block_filter svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: #222222;
}
.block_filter button span.text {
  font-size: 16px;
  color: var(--noir);
}
.block_filter button:active {
  transform: scale(0.9);
}
.block_filter button.active::after {
  position: absolute;
  content: "";
  display: block;
  inset: 0;
  border: 1px solid var(--noir);
  background: transparent;
  border-radius: 5px;
}
.block_filter button span.length {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: -5px;
  width: 18px;
  height: 18px;
  font-size: 10px;
  font-weight: var(--font_display_3_font-weight);
  border-radius: 50%;
  background: #000;
  color: #fff;
  z-index: 10;
}
.block_filter button.active span.length {
  display: flex;
}

@media screen and (max-width: 743px) {
  .block_filter {
    display: flex;
    justify-content: flex-end;
  }
}
