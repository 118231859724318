.info_image {
  position: -webkit-sticky;
  position: sticky;
  top: var(--main-top);
}

.info_image {
  padding: 20px 24px;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
}

.head {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 24px;
}

.head .image {
  width: 130px;
  height: 100px;
  border-radius: 0.5rem;
  background: #dddddd;
  overflow: hidden;
}

.head img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.head h5 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
  color: var(--noir);
}

.head .avis {
  display: flex;
  align-items: center;
  gap: 4px;
}

.head .avis p {
  font-size: 14px;
}

.head .avis span {
  display: inline-block;
  font-size: 13px;
  color: var(--noir4);
}

/*------info prix-----*/
.dispositif h4 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 22px;
  padding: 15px 0;
}

.detail_price {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.detail_price .details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail_price .details p:nth-child(1) {
  text-decoration: underline;
  cursor: pointer;
}

.detail_price .details p.price {
  text-decoration: none;
  cursor: default;
}

.detail_price .details p.promo {
  color: green;
  font-weight: var(--font_display_2_font-weight);
}

.detail_price .total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--border-color);
  padding-top: 24px;
  margin-top: 8px;
}

.detail_price .total p {
  font-weight: var(--font_display_3_font-weight);
}

/*--------pupup-------*/
.detail_price .details .left {
  position: relative;
  outline: none;
  border: none;
  background: transparent;
  font-size: 16px;
}

.detail_price .descript {
  position: absolute;
  bottom: 20px;
  padding: 15px;
  background: rgb(255, 255, 255);
  width: 350px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 16px;
  visibility: hidden;
  transform: scale(0);
  opacity: 0;
  transition: 0.3s;
  transform-origin: left bottom;
}

.detail_price .descript.active {
  visibility: visible;
  transform: scale(1);
  opacity: 1;
}

.detail_price .descript p {
  text-align: left;
  font-size: 13px;
  color: var(--noir6);
  cursor: default !important;
  text-decoration: none !important;
}

.detail_price .descript button.close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  color: var(--noir);
  border-radius: 50%;
  margin-top: -5px;
  margin-right: -10px;
  outline: none;
  border: none;
  background: transparent;
  font-size: 16px;
  cursor: pointer;
}

.detail_price .descript button:hover {
  background: rgb(247, 247, 247);
}

@media screen and (min-width: 1440px) {
  .hotel_confirmation {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

@media screen and (max-width: 1100px) {
  .hotel_confirmation {
    padding: 50px;
  }

  .recap_form {
    padding-right: 0;
  }
}

@media screen and (max-width: 790px) {
  .hotel_confirmation {
    padding: 50px;
    padding-top: 120px;
  }

  .hotel_confirmation h2 {
    font-size: 23px;
  }

  .hotel_confirmation .recap_block {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
  }

  .info_image {
    position: none;
    border: none;
    padding: 0px;
  }

  .head .image {
    width: 100px;
    height: 90px;
  }

  .detail_price .total {
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 540px) {
  .hotel_confirmation {
    padding: 30px 24px;
  }

  .hotel_confirmation h2 {
    font-size: 20px;
  }

  .recap_form .input_bloc {
    display: block;
  }

  .recap_form form button {
    width: 100%;
  }

  .recap_form button.submit {
    width: 100%;
  }
}

@media screen and (max-width: 414px) {
  .hotel_confirmation {
    padding-top: 90px;
  }

  .hotel_confirmation .recap_block {
    padding-top: 20px;
  }

  .hotel_confirmation .recap_block h3 {
    font-size: 18px;
  }

  .detail_price .descript {
    width: 300px;
  }
}

@media screen and (max-width: 540px) {
  .dispositif h4 {
    font-size: 20px;
  }
}
