.container {
  min-height: 100vh;
}
.content {
  padding: 20px 115px;
  display: flex;
  gap: 15px;
}

.notif_message h1 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 16px;
  color: var(--noir4);
  margin-bottom: 5px;
}

.notif_message p {
  font-size: 14px;
  color: var(--noir4);
  text-align: left;
}

.notif_message button {
  margin-top: 24px;
  border: 1px solid var(--noir);
  background: transparent;
  color: var(--noir);
  padding: 0 25px;
  height: 45px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
}

.notif_message button:hover {
  background: var(--gray);
}
.notif_message button:active {
  transition: 0.3s;
  transform: scale(0.9);
}

@media screen and (min-width: 1440px) {
  .content {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}
@media screen and (max-width: 912px) {
  .content {
    padding: 20px 50px;
  }
}

@media screen and (max-width: 743px) {
  .content {
    padding: 20px 24px;
  }
}
