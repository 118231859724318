.container {
  height: 100vh;
  max-width: 1000px;
}
.container_load_liste {
  margin-top: 100px;
}

.main {
  padding: 0 50px 100px;
}

@media screen and (max-width: 950px) {
  .main {
    padding: 0 24px 100px;
  }
}
