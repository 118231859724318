 /*pc*/
 .containerClassName {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 10px;
   padding: 50px 24px 0;
   list-style: none;
   -webkit-tap-highlight-color: transparent;
 }

 .pageLinkClassName {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 35px;
   height: 35px;
   border-radius: 50%;
   color: var(--noir);
   font-size: 15px;
   background: #fff;
   cursor: pointer;
   transition: 0.2s ease-in-out;
 }

 .pageLinkClassName:active {
   transform: scale(0.9);
 }

 .pageLinkClassName:hover {
   background-color: var(--gray);
 }

 .activeLinkClassName {
   cursor: default;
   color: var(--white);
   background-color: var(--noir);
 }

 .activeLinkClassName:hover {
   color: var(--white);
   background-color: var(--noir);
 }

 .navigate {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 35px;
   height: 35px;
   border-radius: 50%;
   color: #ebebeb;
   background-color: var(--white);
   cursor: no-drop;
 }

 .navigate:active {
   transform: scale(1);
 }

 .navigate.show {
   cursor: pointer;
   color: var(--noir);
 }

 .navigate.show:hover {
   background-color: var(--gray);
 }

 .navigate svg {
   display: block;
   fill: none;
   height: 16px;
   width: 16px;
   stroke: currentColor;
   stroke-width: 4;
   overflow: visible;
 }

 @media screen and (max-width: 743px) {
   .containerClassName {
     gap: 80px;
   }

   .pageClassName,
   .breakClassName {
     display: none;
   }

   .navigate {
     width: 50px;
     height: 50px;
     border: 1px solid #ebebeb;
   }

   .navigate.show {
     border: 1px solid rgba(0, 0, 0, 0.08);
     box-shadow: 0 0 0 1px transparent, 0 0 0 4px transparent, 0 2px 4px rgba(0, 0, 0, 0.18);
   }
 }