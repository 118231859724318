.container {
  display: grid;
  gap: 100px;
  grid-template-columns: 1fr 310px;
  max-width: 1100px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 30px 100px;
}

.block_1 h1 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 20px;
}

.block_1 p {
  color: var(--noir6);
  margin-bottom: 20px;
}

.block_1 form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}

.block_1 label {
  display: inline-block;
  font-size: 16px;
  color: var(--noir3);
  cursor: pointer;
}

.block_1 select,
.block_1 textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  margin: 5px 0;
  width: 100%;
  height: 55px;
  font-size: 15px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.block_1 select:focus,
.block_1 textarea:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.block_1 textarea {
  height: 200px;
  padding: 15px;
  appearance: none;
  resize: vertical;
}

.block_1 button.next {
  border: none;
  outline: none;
  color: #fff;
  background: var(--btn_noir1);
  width: 130px;
  height: 45px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.block_1 button.next:hover {
  background: var(--btn_noir2);
}

.block_1 button.next:active {
  transform: scale(0.9);
}

.block_1 button.next.disable {
  background: rgb(226, 226, 226);
  color: #fff;
  cursor: no-drop;
}

.block_1 button.btn_loading {
  width: 130px;
  height: 45px;
  border: none;
  outline: none;
  background: rgb(226, 226, 226);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

/*----block 2----*/
.block_2 h2 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.block_2 p {
  color: var(--noir6);
  margin: 15px 0;
}

.block_2 a {
  text-decoration: none;
  display: inline-block;
  background-color: transparent;
  padding: 14px 25px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  border-radius: 8px;
  border: 1px solid var(--noir);
  cursor: pointer;
}

.block_2 a:hover {
  background-color: var(--gray);
}

.block_2 a:active {
  transition: 0.3s;
  transform: scale(0.9);
}

/*---confirmation-----*/
.confirmation {
  max-width: 1100px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 30px 100px;
}

.confirmation h1 {
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 20px;
}

.confirmation p {
  color: var(--noir6);
  margin-bottom: 50px;
}

/*---connexion-----*/
.connexion {
  border: none;
  outline: none;
  color: #fff;
  background: var(--btn_noir1);
  height: 50px;
  padding: 0 25px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.connexion:hover {
  background: var(--btn_noir2);
}

.connexion:active {
  transform: scale(0.9);
}

@media screen and (max-width: 978px) {
  .container {
    gap: 50px;
    grid-template-columns: 1fr;
  }

  .confirmation {
    padding: 50px 24px;
  }
}

@media screen and (max-width: 743px) {
  .container {
    padding: 50px 24px;
  }

  .block_1 button.next,
  .block_1 button.btn_loading {
    width: 100%;
  }
}
