.container {
  margin-top: 100px;
  padding: 50px 24px;
  background-color: var(--gray);
  border-radius: 12px;
}

.container h3 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 24px;
  margin-bottom: 24px;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.wrapper .button_action {
  display: flex;
  gap: 20px;
  border: 1px solid var(--border-color);
  background-color: var(--white);
  outline: none;
  color: var(--noir);
  border-radius: 10px;
  text-decoration: none;
  padding: 15px 20px;
}

.wrapper .button_action svg {
  display: block;
  height: 32px;
  width: 32px;
}

.wrapper .button_action h4 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
  margin-bottom: 10px;
  text-align: left;
}

.wrapper .button_action p {
  font-size: 14px;
  color: var(--noir8);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: left;
}

@media screen and (max-width: 1100px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 743px) {
  .container {
    margin-left: -24px;
    margin-right: -24px;
    border-radius: 0;
  }
  .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
