.container {
  padding: 0 50px;
}

.container_title button {
  background: transparent;
  border: none;
  outline: none;
  color: var(--noir);
  display: flex;
  align-items: center;
  gap: 10px;
  transition: transform 0.3s;
}

.container_title button:active {
  transform: scale(0.9);
}

.container_title button p {
  text-align: left;
  font-size: 16px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.modal_content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 550px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid var(--border-color);
}

.m_head h3 {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

@media screen and (max-width: 743px) {
  .m_head {
    min-height: 48px;
  }
}

.modal_content .m_body {
  overflow: auto;
  padding: 30px 24px 24px;
}

.m_body_title button {
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  color: var(--noir);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.m_body_title button p {
  font-size: 15px;
  color: var(--noir4);
  font-weight: var(--font_display_1_font-weight);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.m_body_title button p.active {
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
}

.all_list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.all_list button {
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
  color: var(--noir);
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.lists_block {
  display: flex;
  align-items: center;
  gap: 10px;
}

.lists_card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 60px;
  border-radius: 8px;
  background-color: #ddd;
  overflow: hidden;
}

.lists_card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lists_card svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: #fff;
}

.lists_text {
  text-align: left;
}

.lists_text h4 {
  font-size: 15px;
  font-weight: var(--font_display_1_font-weight);
  color: var(--noir4);
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.all_list button.active h4 {
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
}
.lists_text p {
  font-size: 13px;
  color: var(--noir8);
}

@media screen and (max-width: 900px) {
  .container {
    padding: 0 24px;
  }
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .modal_content .m_body {
    padding: 16px 24px 30px;
  }

  .modal_content .m_body::-webkit-scrollbar {
    display: none;
  }
  .lists_card {
    width: 55px;
    height: 40px;
    border-radius: 4px;
  }
  .m_body_title button p,
  .lists_text h4 {
    font-size: 14px;
  }
  .lists_text p {
    font-size: 12px;
  }
}
