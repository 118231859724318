.container {
  display: flex;
  gap: 30px;
  padding: 20px 0 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
}
.container svg {
  display: block;
  height: 32px;
  width: 32px;
  fill: var(--noir);
  stroke: var(--noir);
}
.container h3 {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}
.container p {
  color: var(--noir4);
}
@media screen and (max-width: 743px) {
  .container {
    gap: 16px;
  }
  .container svg {
    height: 24px;
    width: 24px;
  }
  .container h3 {
    font-size: 13px;
  }
  .container p {
    font-size: 12px;
  }
}
