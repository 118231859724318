.detail_images {
  display: none;
}

.swiper_image_1 {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  -webkit-tap-highlight-color: transparent;
}

.swiper_image_1 img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .image_slide {
    display: block;
    width: 100%;
  }

  .swiper_image_1 img {
    height: 350px;
  }

  .detail_images {
    position: relative;
    display: block;
  }

  .detail_images .count {
    position: absolute;
    bottom: 10px;
    font-size: 13px;
    font-weight: var(--font_display_2_font-weight);
    right: 15px;
    color: #fff;
    padding: 3px 9px;
    background: rgba(0, 0, 0, 0.534);
    border-radius: 20px;
    z-index: 5;
  }
}

@media screen and (max-width: 700px) {
  .buttonms_mobile {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    z-index: 5;
  }

  .buttonms_mobile button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #222222;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    background-clip: padding-box;
    border-color: rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 0px 1px transparent, 0px 0px 0px 4px transparent,
      0px 2px 4px rgb(0 0 0 / 18%);
  }

  .buttonms_mobile svg.go_back {
    display: block;
    height: 20px;
    width: 20px;
    stroke-width: 4;
    overflow: visible;
    fill: currentColor;
  }

  .bottoms_droit svg.share_hotel {
    cursor: pointer;
    display: block;
    height: 16px;
    width: 16px;
    stroke-width: 4;
    overflow: visible;
    stroke: currentColor;
  }

  .bottoms_droit {
    display: flex;
    gap: 10px;
  }

  .bottoms_droit button span {
    display: none;
  }
}

@media screen and (max-width: 414px) {
  .swiper_image_1 img {
    height: 270px;
  }
}
