/*---modal-message-----*/
.modal_content {
  display: flex;
  flex-direction: column;
  width: 700px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.m_head h3 {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.m_body {
  overflow: auto;
  padding: 0 24px 50px;
}

.m_body h2 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
}

.m_body p {
  line-height: 24px;
  margin-top: 24px;
  font-size: 15px;
}

.m_body ul {
  margin-top: 24px;
  margin-left: 50px;
}

.m_body ul li {
  margin: 10px 0;
}

.m_body a {
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.m_body h3 {
  margin-top: 24px;
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }
}
