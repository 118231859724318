/* Modal Overlay */
.modal_overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3000;
  animation-duration: 600ms;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-name: modal_Overlay_SlideOut;
}

.modal.active .modal_overlay {
  animation-name: modal_Overlay_SlideIn;
}

.container {
  position: fixed;
  inset: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4000;
  animation-duration: 400ms;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-name: modal_Overlay_SlideOut;
}

.modal.active .container {
  animation-name: modal_Overlay_SlideIn;
}

/* Modal Content */
.modal_content {
  background: #fff;
  width: fit-content;
  max-width: 90vw;
  height: fit-content;
  box-shadow: rgb(0 0 0 / 28%) 0px 8px 28px;
  border-radius: 16px;
  overflow: hidden;
  animation-duration: 400ms;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.modal .modal_content {
  animation-name: modal_content_slideOutn;
}

.modal.active .modal_content {
  animation-name: modal_content_slideIn;
}

/* Animation Keyframes modal_Overlay_SlideIn*/
@keyframes modal_Overlay_SlideIn {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

/* Animation Keyframes modal_Overlay_SlideOut*/
@keyframes modal_Overlay_SlideOut {
  to {
    visibility: hidden;
    opacity: 0;
  }
}

/* Animation Keyframes modal_content_slideIn*/
@keyframes modal_content_slideIn {
  from {
    transform: translateY(100%);
  }

  to {
    transform: none;
  }
}

/* Animation Keyframes modal_content_slideOutn*/
@keyframes modal_content_slideOutn {
  from {
    transform: none;
  }

  to {
    transform: translateY(100%);
  }
}

@media screen and (max-width: 743px) {
  .container {
    align-items: flex-end;
  }

  .modal_content {
    max-width: 100%;
    border-radius: 16px 16px 0 0;
  }
}
