/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  color: var(--noir);
  cursor: pointer;
  padding: 16px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  border: none;
  outline: none;
}

/* Style the accordion content title */
.accordion__title {
  font-size: 20px;
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

/* Style the accordion content text */
.accordion__text {
  color: var(--noir8);
  font-size: 16px;
  opacity: 0;
  transition: opacity 0.2s ease;
  padding-bottom: 16px;
}

.accordion__section.active .accordion__text {
  opacity: 1;
}

@media screen and (max-width: 743px) {
  .accordion__section {
    padding: 8px 0;
  }

  .accordion__title {
    font-size: 16px;
  }
  .accordion__text {
    font-size: 14px;
  }
}
