.modal_content {
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.modal_content .m_body {
  overflow: auto;
  padding: 10px 24px 24px;
}

.m_body .body_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.body_wrapper button,
.body_wrapper a {
  width: 100%;
  border: none;
  outline: none;
  color: var(--noir);
  text-decoration: none;
}

.body_wrapper button:active,
.body_wrapper a:active {
  background-color: var(--tertiary-background-hover);
}

.head_btn {
  display: flex;
  gap: 10px;
}

.head_btn button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 10px 2px;
  border-radius: 10px;
  background-color: var(--tertiary-background);
}

.head_btn button p {
  font-size: 15px;
}

.block_btn {
  border-radius: 10px;
  background-color: var(--tertiary-background);
  overflow: hidden;
}

.block_btn a {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  padding: 15px 20px;
}

.block_btn a:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.block_btn a p {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
}

@media screen and (max-width: 743px) {
  .modal_content {
    width: 743px;
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }
}
