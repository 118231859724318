.wrapper_img_profile {
  position: relative;
  width: 100%;
  height: 200px;
  background-color: var(--noirc);
  border-radius: 16px;
  margin-bottom: 24px;
}

.wrapper_img_profile .image {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: #ddd;
  overflow: hidden;
}

.wrapper_img_profile .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn_update {
  position: absolute;
  top: 20px;
  right: 20px;
}

.btn_update input {
  display: none;
}

.btn_update label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;
  color: var(--noir);
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
    rgb(0 0 0 / 12%) 0px 6px 16px;
  cursor: pointer;
}

.btn_update label svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: currentcolor;
}

@media screen and (max-width: 890px) {
  .wrapper_img_profile {
    height: 150px;
  }
}

@media screen and (max-width: 743px) {
  .wrapper_img_profile {
    height: 140px;
    margin-bottom: 15px;
  }

  .btn_update {
    top: 10px;
    right: 10px;
  }

  .btn_update label {
    width: 24px;
    height: 24px;
  }

  .btn_update label svg {
    height: 12px;
    width: 12px;
  }
}

@media screen and (max-width: 540px) {
  .wrapper_img_profile {
    height: 97px;
  }
}
