.block {
  padding-top: 20px;
}
.block h2 {
  color: var(--noir);
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
}
.block p {
  color: #717171;
  font-size: 15px;
  padding-bottom: 10px;
}
.checkbox_container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding: 24px 0;
  border-bottom: 1px solid #dcdfe4;
}
.text {
  display: flex;
  flex-direction: column;
}
.checkbox_container label {
  width: 100%;
  font-size: 16px;
  color: var(--noir4);
  cursor: pointer;
}
.checkbox_container p {
  color: #717171;
  font-size: 13px;
}
