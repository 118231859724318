/*---------images header----*/
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.image_text {
  background-image: url(../../assets/profile/camping.png);
  background-size: 500px;
  background-position-x: right;
  background-position-y: 80%;
  background-repeat: no-repeat;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 80px;
}

@media screen and (min-width: 1440px) {
  .image_text {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

.image_text_content {
  transform: translateY(-62px);
}

.image_text h1 {
  width: fit-content;
  font-size: 32px;
  font-family: var(--font-family_heading);
  font-weight: var(--font_heading_1_font-weight);
  color: var(--noir);
  padding: 10px;
  margin-left: -10px;
}

.search_block {
  position: relative;
  display: grid;
  grid-template-columns: 270px 270px 1fr 155px;
  grid-gap: 10px;
  background: #fff;
  border: 1px solid #dddddd;
  padding: 10px;
  border-radius: 50px;
  box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 16%);
}

.search_block .submit_btn button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 50px;
  color: #fff;
  border: none;
  outline: none;
  background: var(--button1);
  border-radius: 30px;
  overflow: hidden;
  z-index: 2;
  padding: 0 20px;
  cursor: pointer;
  transition: 0.3s;
}

.search_block .submit_btn button span {
  display: inline-block;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: currentColor;
}

.search_block .submit_btn button::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--button2);
  z-index: -10;
  opacity: 0;
  transition: opacity 0.4s linear;
}

.search_block .submit_btn button:hover::before {
  opacity: 1;
}

.search_block .submit_btn button:active {
  transform: scale(0.9);
}

/*------home_block_2 photo-------*/
.home_block_2 {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 24px 0;
  margin-top: 35px;
}

.home_block_2 h2 {
  font-size: 25px;
  color: var(--noir);
  font-weight: var(--font_heading_1_font-weight);
  font-family: var(--font-family_heading);
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 15px;
  margin-left: -10px;
}

.home_block_2 a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: var(--noir);
  margin-top: 5px;
  background-color: #fff;
  padding: 0 25px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border: 1px solid var(--noir);
  border-radius: 40px;
  cursor: pointer;
  transition: 0.3s;
}

.home_block_2 a:hover {
  background: var(--gray);
}

.home_block_2 a:active {
  transform: scale(0.9);
}

@media screen and (max-width: 1302px) {
  .image_text {
    padding: 50px 50px 0;
  }
}

@media screen and (min-width: 1390px) {
  .image_text_content {
    max-width: 1000px;
  }

  .image_text {
    background-size: 500px;
    background-position-y: 60%;
  }
}

@media screen and (max-width: 1018px) {
  .header {
    min-height: 80vh;
    padding: 0;
  }

  .image_text {
    background-size: 60vw;
    background-position-x: center;
    background-position-y: 57%;
    padding: 50px 115px 0;
  }

  .image_text h1 {
    margin: 0 auto;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 15px;
    margin-bottom: 5px;
  }

  .image_text_content {
    transform: none;
  }

  .search_block {
    grid-template-columns: 1fr;
    padding: 30px 24px;
    grid-gap: 15px;
  }

  .home_block_2 {
    text-align: center;
    justify-content: center;
  }

  .home_block_2 h2 {
    text-align: center;
    margin-left: 0;
  }
}

@media screen and (max-width: 743px) {
  .image_text {
    background-size: contain;
    padding: 0 24px;
  }

  .image_text h1 {
    font-size: 22px;
    padding: 24px 0 15px;
  }

  .search_block {
    border-radius: 20px;
  }
}

@media screen and (max-width: 540px) {
  .image_text {
    justify-content: start;
    background-position-y: 53%;
  }

  .home_block_2 {
    padding: 9px;
    margin-top: 20px;
  }

  .home_block_2 h2 {
    font-size: 24px;
    background-color: transparent;
    backdrop-filter: none;
  }
}

@media screen and (max-width: 414px) {
  .image_text {
    padding: 0 20px;
  }

  .search_block {
    padding: 30px 20px;
  }

  .home_block_2 h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 375px) {
  .search_block {
    padding: 20px 15px;
  }
}

@media screen and (max-width: 360px) {
  .image_text {
    padding: 0 15px;
  }
}
