.block {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--border-color);
}

.head {
  display: flex;
  justify-content: space-between;
}

.head h3 {
  color: var(--noir);
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
}

.bonne_affaire {
  text-decoration: none;
}

.bonne_affaire svg {
  display: block;
  height: 32px;
  width: 32px;
  fill: rgb(227, 28, 95);
  stroke: currentcolor;
}

.main {
  margin-top: 20px;
}
