.main_home {
  padding: 0 115px;
}

@media screen and (max-width: 1024px) {
  .main_home {
    padding: 0 50px;
  }
}

@media screen and (max-width: 743px) {
  .main_home {
    padding: 0 24px;
  }
}

@media screen and (min-width: 1440px) {
  .main_home {
    max-width: var(--maxWith);
    margin: 0 auto;
    padding: 0 50px;
  }
}
