/*------loading-pages details---*/
.container_loading {
  padding: 30px 50px 0;
  min-height: 90dvh;
}

@media screen and (min-width: 1440px) {
  .container_loading {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;
}

.header .search_box {
  width: 150px;
  height: 40px;
  border-radius: 30px;
  background-color: var(--white);
  border: 1px solid rgb(221, 221, 221);
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 4px 12px;
}

.print_block {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.print_block .partage {
  width: 80px;
  height: 20px;
}

.print_block .imprimer {
  width: 80px;
  height: 20px;
}

.links {
  width: 400px;
  height: 25px;
}

.links_2 {
  width: 250px;
  height: 25px;
  margin-top: 10px;
}

.block_loading_image {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, calc(38vh - 80px));
  overflow: hidden;
  grid-gap: 8px;
  margin: 15px 0 24px;
}

@media screen and (min-width: 1428px) {
  .block_loading_image {
    grid-template-rows: repeat(2, calc(30vh - 80px));
  }
}

@media screen and (max-width: 1200px) {
  .block_loading_image {
    grid-template-rows: repeat(2, 200px);
  }
}

.box1,
.box2,
.box3,
.box4,
.box5 {
  border-radius: 12px;
  overflow: hidden;
}

.block_loading_image .box1 {
  grid-column: 2 / 4;
  grid-row: 1 / 3;
}
/* avaibility */
.avaibility {
  display: grid;
  grid-template-columns: 150px 1fr 250px;
  grid-gap: 16px;
  align-items: center;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 12px;
  padding: 20px 24px;
  margin-bottom: 24px;
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
}

.avaibility .price {
  height: 25px;
  border-radius: 8px;
}
.avaibility .search {
  height: 50px;
  border-radius: 8px;
}
.avaibility .btn {
  height: 50px;
  border-radius: 8px;
}

.destination_detail_block.loading {
  margin-bottom: 20px;
}

.destination_detail_block.loading .hotel_type {
  width: 400px;
  height: 25px;
}

.destination_detail_block.loading .hotel_nom {
  width: 200px;
  height: 20px;
  margin: 20px 0;
}

/*------pour mobile------*/
.destination_detail_block.loading .mobile {
  display: none;
}

.mobile .bar {
  width: 200px;
  height: 1px;
}

.mobile .commente {
  width: 250px;
  height: 20px;
  margin: 20px 0;
}

.mobile .text {
  width: 100px;
  height: 15px;
}

.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

@media screen and (max-width: 1100px) {
  .destination_detail_block.loading {
    margin: 40px 0;
  }
}

@media screen and (max-width: 900px) {
  .container_loading {
    padding: 30px 24px 0;
  }
}

@media screen and (max-width: 768px) {
  .avaibility {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .container_loading {
    padding: 0;
  }
  .detail_block_1 {
    display: none;
  }

  .block_loading_image {
    display: block;
    margin-top: 0;
    border-radius: 0;
  }

  .block_loading_image .box1 {
    height: 60vh;
    border-radius: 0;
  }

  .block_loading_image .box2,
  .block_loading_image .box3,
  .block_loading_image .box4,
  .block_loading_image .box5 {
    display: none;
  }

  .destination_detail_block.loading .hotel_type.none {
    display: none;
  }

  .destination_detail_block.loading .hotel_nom.none {
    display: none;
  }

  .destination_detail_block.loading {
    margin: 40px 24px;
  }
}

@media screen and (max-width: 540px) {
  .block_loading_image .box1 {
    height: 300px;
  }

  .destination_detail_block.loading .hotel_type {
    width: 300px;
    height: 25px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 414px) {
  .block_loading_image .box1 {
    height: 270px;
  }
}

/*------pour mobile------*/
@media screen and (max-width: 768px) {
  .destination_detail_block.loading .mobile {
    display: block;
  }
}
