.line_block {
  display: flex;
  align-items: center;
  margin: 18px 0;
}
.line {
  height: 1px;
  width: 100%;
  background-color: var(--border-color);
}
.line_block span {
  color: var(--noir6);
  margin: 0 10px;
  font-size: 14px;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.block button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 45px;
  background-color: transparent;
  border: 1px solid var(--noir);
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}
.block button span {
  color: var(--noir);
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
}
.block button:hover {
  background-color: var(--gray);
}
.block button:active {
  transform: scale(0.9);
}
