/*-----envoyer un message a l'etablissement----*/
.send_message {
  padding: 50px 0;
}

.profil_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 100px;
}

.profil_block {
  display: flex;
  align-items: center;
  gap: 15px;
}

.profil_block .profil_img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f1f1f1;
  text-decoration: none;
}

.profil_block .profil_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profil_block .profil_img h2 {
  color: var(--noir);
  font-size: 18px;
}

.profil_block h4 {
  font-size: 20px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  padding-bottom: 5px;
  line-height: 1.2;
}

.profil_block p {
  font-size: 15px;
  color: var(--noir8);
}

.profil_container .status {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 30px 0;
}

.profil_container .comment {
  display: flex;
  align-items: center;
  gap: 10px;
}

.identity {
  display: flex;
  align-items: center;
  gap: 10px;
}

.identity svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: var(--star_color);
}

.send_message .button {
  height: 48px;
}

.profil_container .profil_about h4 {
  font-size: 20px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  padding-bottom: 10px;
}

.profil_container .profil_about p {
  font-size: 16px;
  color: var(--noir3);
}

@media screen and (max-width: 750px) {
  .send_message {
    padding: 24px 0 50px;
  }

  .profil_container {
    grid-template-columns: 1fr;
    grid-gap: 50px;
  }

  .profil_block .profil_img {
    width: 45px;
    height: 45px;
  }
  .profil_container .profil_about p {
    font-size: 15px;
  }
}

/*---modal_message-----*/
.modal_content {
  display: flex;
  flex-direction: column;
  width: 650px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.modal_content .m_body {
  overflow: auto;
  padding: 10px 24px 50px;
}

.modal_content .header h3 {
  color: var(--noir);
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  padding: 5px 0 15px;
}

.modal_content .header ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}

.modal_content .header ul li {
  padding: 5px;
  color: var(--noir);
}

.modal_content label {
  display: inline-block;
  padding-bottom: 10px;
  color: var(--noir4);
  cursor: pointer;
}

.modal_content textarea {
  box-sizing: border-box;
  width: 100%;
  height: 200px;
  padding: 10px;
  border-radius: 8px;
  resize: vertical;
  font-size: 16px;
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.modal_content textarea:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.modal_content textarea.erreur_text {
  box-shadow: rgb(193, 53, 21) 0px 0px 0px 1px inset;
}

.modal_content textarea.erreur_text:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.modal_content .notif p i {
  color: rgb(193, 53, 21);
}

.modal_content button.send {
  border: none;
  color: #fff;
  margin-top: 30px;
  padding: 0 20px;
  width: 230px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.modal_content button.send {
  background: var(--btn_noir1);
}

.modal_content button.send:hover,
.button_action button.next:hover {
  background: var(--btn_noir2);
}

.modal_content button.send:active,
.button_action button.next:active {
  transform: scale(0.9);
}

.modal_content button.send.loading {
  background: rgb(226, 226, 226);
  cursor: no-drop;
}

.modal_content button.send.loading:hover {
  background: rgb(226, 226, 226);
}

.succes_message {
  overflow: auto;
  padding: 0 30px 30px;
  margin-top: 10px;
}

.succes_message h3 {
  color: var(--noir);
  font-weight: var(--font_display_3_font-weight);
  font-size: 22px;
  margin-bottom: 5px;
}

.succes_message p {
  color: var(--noir6);
}

.button_action {
  padding: 16px 30px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--border-color);
}

.button_action button.next {
  border: none;
  background-color: var(--btn_noir1);
  color: #fff;
  padding: 0 20px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

@media screen and (max-width: 743px) {
  .modal_content button.send,
  .send_message .button {
    width: 100%;
  }

  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }

  .m_body::-webkit-scrollbar {
    display: none;
  }

  .m_body button {
    width: 100%;
  }
}

@media screen and (max-width: 414px) {
  .send_message h3 {
    font-size: 20px;
  }
}
