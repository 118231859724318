.main_home {
  min-height: 100svh;
  padding: 0 100px 90px;
}

@media screen and (min-width: 1440px) {
  .main_home {
    max-width: var(--maxWith);
    margin: 0 auto;
    padding: 0 50px;
  }
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.header a {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  margin-left: -10px;
  color: var(--noir);
  font-size: 19px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.header a:hover {
  background: var(--gray);
  cursor: pointer;
}

.header h1 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 25px;
  color: var(--noir);
}

.connexion_block {
  margin-top: 30px;
}

.connexion_block h2 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 18px;
  color: var(--noir);
  margin-bottom: 15px;
}

.connexion_btn {
  border: none;
  outline: none;
  color: #fff;
  background: var(--btn_noir1);
  padding: 0 24px;
  height: 50px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s;
}

.connexion_btn:hover {
  background: var(--btn_noir2);
}

.connexion_btn:active {
  transform: scale(0.9);
}

@media screen and (max-width: 1100px) {
  .main_home {
    padding: 0 50px 90px;
  }
}

@media screen and (max-width: 743px) {
  .main_home {
    padding: 0 24px 90px;
  }
}

@media screen and (max-width: 540px) {
  .header h1 {
    font-size: 20px;
  }
}
