.container {
  padding: 20px 0 100px;
}

.hero_section {
  display: grid;
  grid-template-columns: 1fr 400px;
  align-items: center;
  height: 100%;
  gap: 50px;
}

.title_section h1 {
  font-size: 30px;
  font-family: var(--font-family_heading);
  font-weight: var(--font_heading_2_font-weight);
}

.title_section p {
  margin-top: 10px;
  font-size: 18px;
}

.title_section a {
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.action_block_content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.action_block_link {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 14px;
  word-break: break-all;
  font-weight: var(--font_display_2_font-weight);
  padding: 0 15px;
  height: 50px;
}

.action_block {
  margin-top: 10px;
}

.action_block .connexion_btn,
.action_block_content button {
  border: none;
  outline: none;
  color: #fff;
  background: var(--btn_noir1);
  padding: 0 15px;
  height: 50px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s;
}

.action_block .connexion_btn:hover,
.action_block_content button:hover {
  background: var(--btn_noir2);
}

.action_block_content button:active,
.action_block .connexion_btn:active {
  transform: scale(0.9);
}

.img_block {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: var(--gray);
  overflow: hidden;
}

.img_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 912px) {
  .container {
    height: 100%;
    padding: 50px 0 90px;
  }

  .hero_section {
    display: flex;
    flex-direction: column;
  }

  .title_section {
    display: flex;
    flex-direction: column;
  }

  .title_section h1 {
    font-size: 24px;
  }

  .title_section p,
  .title_section a {
    font-size: 16px;
  }
}

@media screen and (max-width: 743px) {
  .container {
    height: 100%;
    padding: 24px 0 90px;
  }

  .img_block {
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 487px) {
  .action_block_content button {
    width: 100%;
  }
}
