.voyage_container {
  padding-top: 80px;
  height: 100vh;
  overflow: hidden;
}

.voyage_container {
  display: grid;
  grid-template-columns: 380px 1fr;
}

/*-----block_details--------*/
.loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block_details {
  padding: 30px 30px 100px;
  height: 100%;
  overflow: auto;
}

@media screen and (max-width: 743px) {
  .voyage_container {
    display: block;
    padding-top: 70px;
  }

  .block_details {
    padding: 30px 24px 100px;
  }
}