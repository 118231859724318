.main {
  padding: 30px 0;
}

.container {
  display: flex;
  flex-direction: column-reverse;
  gap: 30px;
}

.dateContent h4 {
  font-size: 22px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  padding-bottom: 10px;
  line-height: 1.2;
}

.gauch p {
  font-size: 15px;
  color: var(--noir6);
}

.block_error {
  display: flex;
  align-items: center;
  gap: 8px;
}

.block_error p {
  font-size: 14px;
  color: #c13515;
}

.droit {
  margin-top: 15px;
}

.droit p {
  font-size: 15px;
  color: var(--noir6);
}

.calendrier_block {
  max-width: 750px;
}

.tileClassName {
  height: 46px;
}

.tileClassName:disabled {
  text-decoration: line-through;
}

@media screen and (max-width: 743px) {
  .gauch p {
    font-size: 14px;
  }
}
