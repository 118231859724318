@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"); */

@font-face {
  font-family: "Inter";
  src:
    url("../assets/fonts/Inter-Regular.woff2") format("woff2"),
    url("../assets/fonts/Inter-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src:
    url("../assets/fonts/Inter-Medium.woff2") format("woff2"),
    url("../assets/fonts/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src:
    url("../assets/fonts/Inter-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src:
    url("../assets/fonts/Inter-Bold.woff2") format("woff2"),
    url("../assets/fonts/Inter-Bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
}

:root {
  --noir: #222222;
  --noir3: #333;
  --noir4: #444;
  --noir5: #555;
  --noir6: #666;
  --noir7: #777;
  --noir8: #888;
  --noir9: #999;
  --noirc: #ccc;
  --gray: #f7f7f7;
  --gray-hover: #ebebeb;
  --modal-footer-background-color: rgb(247, 247, 247);
  --tertiary-background: #f0f0f0;
  --tertiary-background-hover: #ebebeb;
  --extra-light-gray: #f5f5f5;
  --light-gray-background: #f8f8f8;
  --border-color: #dddddd;
  --white: #fff;
  --bluetext: #0071eb;
  --color-bottom-bar: #222222;
  --color-notif: #ff385c;
  --box-shadow-border: 0 0 0 2px #000000;

  --btn_noir1: #222222;
  --btn_noir2: #0c0c0c;
  --button1: linear-gradient(to right, #238dff, #1a57ff);
  /* --button1: linear-gradient(to right, #b24592, #f15f79); */
  --button2: #007bff;
  --bg-svg-image: #ff3078;
  --star_color: #333;
  --star_background_color: #fff;

  --maxWith: 1215px;
  --sidebar-with: 240px;
  --sidebar-with-hidded: 72px;

  --main-top: 30px;
  --fav-list-width: 400px;

  --font-family_heading: "Poppins", sans-serif;

  --font_heading_1_font-weight: 600;
  --font_heading_2_font-weight: 700;

  --font_display_1_font-weight: 400;
  --font_display_2_font-weight: 500;
  --font_display_3_font-weight: 600;

  --PhoneInputCountrySelectArrow-width: 8px;
  --PhoneInputCountrySelectArrow-borderWidth: 2px;
}

@media screen and (min-width: 1920px) {
  :root {
    --sidebar-with: 335px;
  }
}

@media screen and (max-width: 1263px) {
  :root {
    --sidebar-with: var(--sidebar-with-hidded);
  }
}

@media screen and (max-width: 743px) {
  :root {
    --sidebar-with: 0;
    --sidebar-with-hidded: 0;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1.43;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* p,
h1,
h2,
h3,
h4,
h5,
h6,
li,
span,
label {
  color: var(--noir);
}

label {
  -webkit-tap-highlight-color: transparent;
}

input,
textarea,
select,
a {
  background-clip: padding-box;
  -webkit-tap-highlight-color: transparent;
}

input,
textarea,
a {
  -webkit-appearance: none;
  -moz-appearance: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  appearance: button;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

/* Pour WebKit (Chrome, Safari) */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-clear-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="search"]::-webkit-search-inner-box {
  -webkit-appearance: none;
  margin: 0;
}

/* Pour Firefox */
input[type="search"] {
  -moz-appearance: none;
}

/* Pour Edge */
input[type="search"]::-ms-clear {
  display: none;
}

/*--------swiper dote styles------*/
.swiper-pagination-bullet {
  width: 7px;
  height: 7px;
  opacity: 1;
  background: rgba(240, 240, 240, 0.979);
}

.swiper-pagination-bullet-active {
  background: #fff;
}

/*-------swiper config------*/
.swiper-scrollbar {
  height: 10px;
  width: 94%;
  left: 3%;
  bottom: 10px;
  position: absolute;
  background: rgb(82, 82, 82);
  box-shadow: 1px 1px 1px rgba(63, 63, 63, 0.8) inset;
}

.swiper-scrollbar-drag {
  background: #fff;
}

/*-----pour le modal react datepiker-----*/
.react-calendar {
  width: 100%;
  border: none;
}

.react-calendar button {
  font-size: 14px;
}

.react-calendar__navigation__label,
.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button,
.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--weekend {
  color: var(--noir);
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 27px !important;
  border-radius: 50%;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar abbr,
.react-calendar button span {
  font-weight: var(--font_display_2_font-weight);
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  padding-bottom: 15px;
}

.react-calendar__navigation__label {
  pointer-events: none;
}

.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}

.react-calendar__navigation button:disabled {
  background-color: #fff;
  color: #ccc;
}

.react-calendar__tile {
  height: 46px;
}

.react-calendar__tile:disabled {
  background-color: #fff;
  color: #ccc;
}

.react-calendar__tile--now {
  background: #fff;
  color: var(--noir);
}

.react-calendar__tile--now:enabled:hover {
  background: #f1f1f1;
}

.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  background: var(--noir);
  color: #fff;
}

.react-calendar__tile--rangeStart:enabled:hover,
.react-calendar__tile--rangeStart:enabled:focus,
.react-calendar__tile--rangeEnd:enabled:hover,
.react-calendar__tile--rangeEnd:enabled:focus {
  background: var(--noir);
  color: #fff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus,
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  background: var(--noir) !important;
  color: #fff !important;
}

.react-calendar__tile--active {
  background: #f1f1f1;
  color: var(--noir);
}

.react-calendar__tile--active:enabled:hover {
  background: #dddddd;
}

/* slider */
.rc-slider {
  user-select: none;
}

.rc-slider-handle {
  background-color: #fff !important;
  border-color: #dddddd !important;
  width: 32px !important;
  height: 32px !important;
  margin-top: -16px !important;
  border-width: 1px !important;
  opacity: 1 !important;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12) !important;
  transition: transform 0.1s cubic-bezier(0.2, 0, 0, 1);
}

.rc-slider-handle.rc-slider-handle-dragging {
  box-shadow: 0 0 19px rgba(0, 0, 0, 0.25) !important;
}

.rc-slider-track,
.rc-slider-rail,
.rc-slider-step {
  height: 2px !important;
}

.rc-slider-track {
  background-color: #222222 !important;
}

@media screen and (max-width: 540px) {
  .react-calendar__month-view__weekdays__weekday {
    padding: 10px 6.6667px;
    padding-bottom: 15px;
  }

  .react-calendar__month-view__days button {
    padding: 10px 6.6667px;
  }
}

/**-------PhoneInputCountry--------*/
.PhoneInputCountry {
  margin-right: 10px;
}
.PhoneInputCountry select {
  box-sizing: border-box;
  padding: 0 15px;
  width: 100%;
  font-size: 15px;
  background-color: transparent;
  color: var(--noir);
}

/**-------sweetalert--------*/
.actions_class {
  display: flex !important;
  padding: 0 30px !important;
  justify-content: space-between !important;
}

.cancel_button_class {
  color: #222222 !important;
  background-color: transparent !important;
  text-decoration: underline;
  margin-left: -15px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px !important;
}

.confirm_button_class {
  border: none;
  outline: none !important;
  color: #fff;
  background-color: var(--btn_noir1) !important;
  height: 45px;
  padding: 0 25px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px !important;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: none !important;
}

.confirm_button_class:hover {
  background-color: var(--btn_noir2);
}

@media screen and (max-width: 743px) {
  input,
  textarea {
    font-size: 16px !important;
  }

  *:hover {
    background-color: none;
  }
}
