.container {
  position: fixed;
  bottom: 24px;
  left: 50%;
  max-width: 600px;
  transform: translateX(-50%);
  background: #fff5f5;
  box-shadow: rgb(0 0 0 / 20%) 0px 6px 20px;
  border-radius: 16px;
  z-index: 2050; /* superieur a la cookie-consent z-index:2000 */
}

.content {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 24px;
}

.left svg {
  display: block;
  height: 34px;
  width: 34px;
  stroke: #c13515;
  fill: #c13515;
}

.notif_message h1 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 16px;
  color: var(--noir);
  margin-bottom: 5px;
}

.notif_message p {
  font-size: 13px;
  color: var(--noir4);
  text-align: left;
}

.notif_message button,
.notif_message a {
  background-color: transparent;
  border: none;
  outline: none;
  text-decoration: underline;
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.close_btn button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: none;
  border: none;
  color: var(--noir);
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.close_btn button:hover {
  background-color: #ffe9e9;
}

.notif_message button:active,
.notif_message a:active,
.close_btn button:active {
  transition: transform 0.3s ease-in-out;
  transform: scale(0.9);
}

@media screen and (max-width: 854px) {
  .container {
    left: 80px;
    right: 80px;
    max-width: 100%;
    transform: none;
  }
}

@media screen and (max-width: 743px) {
  .container {
    left: 15px;
    right: 15px;
  }
}

@media screen and (max-width: 414px) {
  .content {
    gap: 10px;
    padding: 20px 10px;
  }
}
