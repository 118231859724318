.modal_content {
  display: flex;
  flex-direction: column;
  width: 550px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid var(--border-color);
}

.m_head h3 {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.m_body {
  overflow: auto;
  padding: 0 24px 30px;
}

.button_action {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  background-color: var(--modal-footer-background-color);
}

.button_action button {
  border: none;
  color: #fff;
  padding: 0 20px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.button_action button.retour {
  margin-left: -20px;
  background-color: transparent;
  color: var(--noir);
  text-decoration: underline;
}

.button_action button.retour:hover {
  background-color: var(--gray-hover);
}

.button_action button.next {
  background-color: var(--btn_noir1);
  color: #fff;
}

.button_action button.next:hover {
  background-color: var(--btn_noir2);
}

.button_action button.next:active,
.button_action button.retour:active {
  transform: scale(0.9);
}

.button_action button.next.disabled {
  cursor: no-drop;
  background-color: rgb(226, 226, 226);
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }

  .m_body {
    padding-bottom: 50px;
  }
}
