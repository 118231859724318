.container {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 24px 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 700;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 0px;
}

.result_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 70px;
  width: 100%;
}

.button_searchResult {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(221, 221, 221);
  border-radius: 40px;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 4px 12px;
  color: rgb(34, 34, 34);
  transition: box-shadow 0.2s ease-in-out;
}

.button_searchResult:hover {
  box-shadow: none;
}

.block_racap {
  display: flex;
}

.block_racap .colone,
.block_racap .colone {
  border-right: 1px solid rgb(221, 221, 221);
}

.block_racap .city {
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  padding: 0 15px;
  max-width: 250px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.block_racap .date_voyageuer {
  display: flex;
}

.block_racap .colone.date,
.block_racap .capacite {
  font-size: 14px;
  padding: 0 15px;
  font-weight: var(--font_display_2_font-weight);
}

.block_racap .colone.date {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.block_racap .capacite {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.button_searchResult .icon_search {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--noir);
  width: 32px;
  height: 32px;
  border-radius: 50px;
  background-color: #f0f0f0;
}

.button_searchResult .icon_search svg {
  width: 12px;
  height: 12px;
  stroke-width: 5.333333333333333;
}

@media screen and (max-width: 970px) {
  .block_racap .city {
    font-size: 14px;
    font-weight: var(--font_display_2_font-weight);
    padding: 0 8px;
  }
}

@media screen and (max-width: 743px) {
  .container {
    padding: 14px 24px 5px;
    gap: 10px;
  }

  .result_nav {
    display: block;
    height: 100%;
  }

  .button_searchResult {
    width: 100%;
    background: rgb(247, 247, 247);
    border: 0;
    padding: 10px 19px;
    padding-right: 10px;
    box-shadow: none;
  }

  .block_racap {
    display: block;
  }

  .block_racap .colone.city {
    border: none;
  }

  .block_racap .date_voyageuer {
    display: flex;
  }

  .block_racap .colone.date,
  .block_racap .capacite {
    padding: 0 8px;
    font-size: 12px;
    font-weight: 400;
    color: var(--noir6);
  }

  .button_searchResult .icon_search {
    width: 36px;
    height: 0;
    background: transparent;
    border: 0;
    color: var(--noir4);
  }
  .button_searchResult .icon_search svg {
    width: 16px;
    height: 16px;
    stroke-width: 4;
  }
}

@media screen and (max-width: 414px) {
  .block_racap .colone.city,
  .block_racap .colone.date,
  .block_racap .capacite {
    padding: 0 7px;
  }
}

@media screen and (max-width: 280px) {
  .container {
    padding: 14px 5px 5px;
  }
}
