.favori_container {
  padding: var(--main-top) 50px 100px;
  min-height: 100vh;
}

.favori_container .loading_favori {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.favori_container h1 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 25px;
  color: var(--noir);
}

.favori_container .no_favori {
  display: flex;
  gap: 30px;
  padding-top: var(--main-top);
}

.no_favori .no_fav_1 {
  display: flex;
  gap: 15px;
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 16px;
}

.no_favori .no_fav_1 .card {
  width: 200px;
  height: 150px;
  background: #ccc;
  border-radius: 16px;
}

.no_favori .no_fav_1 p {
  width: 100px;
  height: 20px;
  margin: 5px 0;
  background: #ccc;
  border-radius: 5px;
}

.no_favori .no_fav_2 h2 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 20px;
  color: var(--noir);
}

.no_favori .no_fav_2 p {
  margin: 10px 0 20px;
}

.no_favori .no_fav_2 a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 270px;
  height: 48px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  border: 1px solid var(--btn_noir1);
  outline: none;
  color: var(--noir);
  background: transparent;
  transition: 0.3s;
}

.no_favori .no_fav_2 a:hover {
  background: #f1f1f1;
}

.no_favori .no_fav_2 a:active {
  transform: scale(0.9);
}

.favori_container .connexion {
  padding: 50px 0;
}

.favori_container .connexion h2 {
  font-size: 20px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.favori_container .connexion p {
  padding: 18px 0;
}

.favori_container .connexion button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 25px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  border: 1px solid var(--btn_noir1);
  outline: none;
  color: var(--noir);
  background: transparent;
  transition: 0.3s;
}

.favori_container .connexion button:hover {
  background: #f1f1f1;
}

.favori_container .connexion button:active {
  transform: scale(0.9);
}

@media screen and (min-width: 1440px) {
  .favori_container {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

@media screen and (max-width: 900px) {
  .favori_container {
    padding: 30px 24px 100px;
  }
}

@media screen and (max-width: 760px) {
  .favori_container .no_favori {
    flex-direction: column;
  }

  .no_favori .no_fav_1 .card {
    width: 150px;
    height: 110px;
  }

  .no_favori .no_fav_2 a {
    width: 100%;
  }
}

/*------liste favoris-------*/
.container_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  margin-top: 50px;
}

.container_list a {
  text-decoration: none;
  display: inline-block;
  color: var(--noir);
}

.card_block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2px;
  width: 100%;
  height: 200px;
  background-color: var(--gray);
  border-radius: 16px;
  overflow: hidden;
}

.content_img {
  width: 100%;
  height: 200px;
  background-color: var(--gray);
}

.content_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_list h3 {
  font-size: 20px;
  font-weight: var(--font_display_2_font-weight);
  font-size: 16px;
  margin-top: 10px;
}

@media screen and (max-width: 743px) {
  .container_list {
    grid-template-columns: 1fr;
  }

  .container_list a {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .card_block {
    display: block;
    width: 65px;
    height: 60px;
    border-radius: 5px;
  }

  .content_img {
    width: 100%;
    height: 60px;
  }

  .card_block:nth-child(2) {
    display: none;
  }

  .container_list h3 {
    font-size: 15px;
  }
}
