.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 20px 20px 20px 10px;
  border-bottom: 1px solid rgb(235, 235, 235);
}
.navigation .links {
  display: flex;
  align-items: center;
  gap: 10px;
}
.navigation .links a {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  padding: 8px 15px;
  background: transparent;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  transition: 0.1s ease-in-out;
}
.navigation .links a span {
  display: none;
  justify-content: center;
  font-size: 11px;
  font-weight: var(--font_display_2_font-weight);
  width: 20px;
  height: 16px;
  color: #fff;
  border-radius: 8px;
  background: #ff385c;
  animation-duration: 0.3s;
  animation-name: notification;
}
@keyframes notification {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}
.navigation .links a:hover {
  background: rgb(247, 247, 247);
}
.navigation .links a.active {
  background: #fff;
  cursor: default;
}
.navigation .links a.active::before {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  inset: 0;
  height: 100%;
  background: transparent;
  border: 2px solid #222;
  border-radius: 8px;
}
.icon_block {
  position: relative;
}
.navigation .icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--noir);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: transform 0.3s;
  background: transparent;
}
.navigation .icon:active {
  transform: scale(0.9);
}

.navigation .icon:hover {
  background: rgb(247, 247, 247);
}
.navigation .icon.active {
  background: rgb(247, 247, 247);
}
.navigation .icon.active::before {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  inset: 0;
  height: 100%;
  background: transparent;
  border: 2px solid #222;
  border-radius: 50%;
}
.icon_block .modal {
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  width: 235px;
  height: auto;
  padding: 10px 0;
  border-radius: 12px;
  z-index: 999;
  background: #fff;
  transition: 0.3s ease-in-out;
  box-shadow: rgb(0 0 0 / 12%) 0px 2px 16px;
}
.icon_block .modal.active {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.icon_block .modal a {
  background: transparent;
  text-decoration: none;
  text-align: left;
  padding: 10px 15px;
  cursor: pointer;
  color: var(--noir6);
  font-size: 14px;
}
.icon_block .modal a:hover {
  background: #f1f1f1;
}
.icon_block .modal a span {
  margin-left: 5px;
}
.icon_block .modal a.active {
  background: #f1f1f1;
}
@media screen and (max-width: 800px) {
  .navigation .links a span {
    display: flex;
  }
}
