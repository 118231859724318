.main {
  padding: 70px 50px 0px;
}

@media screen and (min-width: 1440px) {
  .main {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  background-color: #f1f1f1;
  border-radius: 24px;
  padding: 15vh 50px;
}

.img_block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container img {
  width: 100%;
  max-width: 400px;
}

.droit {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.droit h3 {
  font-size: 40px;
  color: var(--noir);
  font-weight: var(--font_heading_2_font-weight);
  font-family: var(--font-family_heading);
}

.droit p {
  font-size: 20px;
  color: var(--noir8);
}

@media screen and (max-width: 1200px) {
  .main {
    padding: 50px 50px 0;
  }
}

@media screen and (max-width: 964px) {
  .main {
    padding: 50px 100px 0;
  }

  .container {
    grid-template-columns: 1fr;
    grid-gap: 30px;
    padding: 50px;
  }
}

@media screen and (max-width: 743px) {
  .main {
    padding: 50px 24px 0;
  }

  .container {
    padding: 24px;
  }

  .droit h3 {
    font-size: 25px;
  }

  .droit p {
    font-size: 16px;
  }
}
