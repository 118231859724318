/*---------footer-------*/
.container {
  border-top: 1px solid #dddddd;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 24px 50px;
}

.footer .link p {
  color: var(--noir4);
  font-size: 14px;
}

.footer .link {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 10px;
}

.footer .link a {
  font-size: 14px;
  color: var(--noir4);
  text-decoration: none;
  transition: 0.2s ease-in-out;
}

.footer .link a:hover {
  text-decoration: underline;
}

.block2 {
  display: flex;
  align-items: center;
  gap: 24px;
}

.footer .block2 a {
  color: var(--noir4);
  text-decoration: none;
}

.block2 svg {
  height: 18px;
  width: 18px;
  display: block;
  fill: currentcolor;
}

@media screen and (min-width: 1440px) {
  .footer {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

@media screen and (max-width: 900px) {
  .footer {
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
  }
}