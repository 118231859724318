.container {
  border-bottom: 1px solid #dcdfe4;
  padding: 24px 0 35px;
  background: #fff;
  box-sizing: border-box;
}

.form {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 12px;
  padding: 20px 24px;
  margin-bottom: 50px;
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
}

.form .form_action {
  display: grid;
  grid-template-columns: 150px 1fr 250px;
  grid-gap: 16px;
}

.form .head {
  display: flex;
  align-items: center;
}

.form .head .promo {
  font-family: var(--font-family_heading);
  font-size: 14px;
  font-weight: var(--font_heading_1_font-weight);
  color: #999;
  text-decoration: line-through;
}

.form .head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-right: 1px solid var(--border-color);
}

.form .head .default_price {
  display: flex;
  align-items: center;
}

.form .head .default_price p {
  margin-right: 5px;
}

.form .head h3 {
  font-family: var(--font-family_heading);
  font-size: 14px;
}

.form .head button {
  background: transparent;
  border: none;
  font-family: var(--font-family_heading);
  font-size: 18px;
  font-weight: var(--font_heading_1_font-weight);
  text-decoration: underline;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.form .head button:active {
  color: #999;
  transform: scale(0.96);
}

.form .head span {
  font-size: 13px;
  font-weight: 400;
  margin-left: 5px;
}

.form .price_normal {
  display: flex;
  align-items: center;
  gap: 5px;
}

.form .price_normal h3 {
  font-family: var(--font-family_heading);
  font-size: 20px;
  font-weight: var(--font_heading_1_font-weight);
}

.block_recap {
  display: grid;
  grid-template-columns: 1fr 200px;
  border: 1px solid var(--noir9);
  border-radius: 8px;
  overflow: hidden;
}

.block_recap button {
  background-color: transparent;
  border: none;
  overflow: hidden;
  transition: 0.1s ease-in;
}

.dates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  justify-content: space-between;
}

.dates_start,
.dates_end {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  padding: 10px 15px;
  cursor: pointer;
  flex: 1;
}

.dates_end {
  border-left: 1px solid var(--noir9);
  border-right: 1px solid var(--noir9);
}

.dates_start:hover,
.dates_end:hover,
.block_recap .voyageur:hover {
  background: rgb(247, 247, 247);
}

.dates_start.date_invalid,
.dates_end.date_invalid {
  background-color: rgb(255, 248, 246);
}

.dates_start.date_invalid:hover,
.dates_end.date_invalid:hover {
  background-color: rgb(255, 235, 230);
}

.dates h3 {
  font-size: 11px;
  text-transform: uppercase;
  color: var(--noir);
  font-weight: var(--font_display_3_font-weight);
}

.block_recap p {
  font-size: 14px;
  color: var(--noir);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.dates_start .block_error,
.dates_end .block_error {
  display: none;
}

.dates_start.date_invalid .block_error,
.dates_end.date_invalid .block_error {
  display: flex;
  gap: 8px;
}

.dates p.invalid,
.dates p.invalid {
  font-size: 12px;
  font-weight: var(--font_display_2_font-weight);
  color: #c13515;
}

.block_recap button.voyageur {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  width: 100%;
  padding: 10px 15px;
  cursor: pointer;
}

.container .btn_block {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.verif_button button,
.container .btn_block a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: var(--button1);
  border: none;
  outline: none;
  color: #fff;
  overflow: hidden;
  z-index: 2;
  padding: 0 20px;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.verif_button button::before,
.container .btn_block a::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--button2);
  z-index: -10;
  opacity: 0;
  transition: opacity 0.4s linear;
}

.verif_button button:hover::before,
.container .btn_block a:hover::before {
  opacity: 1;
}

.verif_button button:active,
.container .btn_block a:active {
  transform: scale(0.9);
}

.container .desactive {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 24px;
}

.container .desactive p {
  font-size: 14px;
  color: rgb(193, 53, 21);
}

@media screen and (max-width: 1015px) {
  .form .form_action {
    grid-template-columns: 150px 1fr 250px;
  }
  .block_recap {
    grid-template-columns: 1fr 100px;
  }
}

@media screen and (max-width: 743px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
  }

  .form {
    margin: 24px 0 0;
    padding: 24px;
  }

  .form .price_normal,
  .form .head {
    display: none;
  }

  .form .form_action {
    display: block;
  }

  .block_recap {
    display: block;
    border-radius: 12px;
  }

  .dates_end {
    border-right: 0;
  }

  .block_recap button.voyageur {
    border-top: 1px solid var(--noir9);
  }

  .dates_start,
  .dates_end,
  .block_recap button.voyageur {
    padding: 15px;
  }

  .verif_button,
  .container .btn_block {
    display: none;
  }

  .container .desactive {
    margin: 24px 0 0;
  }
}
