.container {
  padding: 0 24px;
}

.container .header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0 12px;
}

.container .header p {
  width: 250px;
  height: 20px;
  border-radius: 10px;
}

.container .header p.nombre {
  width: 150px;
  height: 20px;
  border-radius: 10px;
}

.list_hotel {
  padding: 15px 0;
}

.list_hotel:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.profile_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.profile_image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.show_more {
  width: 20px;
  height: 5px;
  border-radius: 5px;
}

.card_container {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 20px;
  overflow: hidden;
}

.card_container .card_image {
  width: 100%;
  height: 200px;
  border-radius: 15px;
}

.content_block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.head_content {
  display: flex;
  justify-content: space-between;
}

.head_content_left {
  width: 100%;
}

.head_content h4 {
  width: 65%;
  height: 20px;
  margin: 5px 0;
  border-radius: 10px;
}

.head_content h4.hotel_name {
  width: 80%;
}

.hotel_comment {
  width: 30%;
  height: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.price_block {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.price_block h4 {
  width: 40%;
  height: 20px;
  border-radius: 10px;
}

@media screen and (max-width: 800px) {
  .container {
    padding: 0 24px 30px;
  }

  .container .header {
    margin: 20px 0 10px;
  }

  .container .header p {
    width: 150px;
    height: 15px;
  }

  .container .header p.nombre {
    width: 100px;
    height: 15px;
  }

  .list_hotel {
    padding: 15px 0 0;
  }

  .list_hotel:not(:last-child) {
    border-bottom: 0;
  }

  .card_container {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .card_container .card_image {
    height: 400px;
  }

  .head_content {
    flex-direction: column;
  }

  .head_content h4 {
    height: 15px;
    margin: 5px 0;
  }

  .head_content .hotel_comment {
    width: 40%;
    margin-top: 5px;
  }
}

@media screen and (max-width: 540px) {
  .profile_image {
    width: 40px;
    height: 40px;
  }

  .card_container .card_image {
    height: 350px;
  }
}

@media screen and (max-width: 414px) {
  .card_container .card_image {
    height: 250px;
  }
}

.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
