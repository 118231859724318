.verify h2 {
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 10px;
}

.verify h2 strong {
  font-size: 18px;
  font-weight: var(--font_display_3_font-weight);
}

.verify p {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;
  color: var(--noir);
}

.verify p span {
  display: inline-block;
  color: var(--noir);
  font-size: 16px;
}

@media screen and (max-width: 743px) {
  .verify {
    margin-top: 15px;
  }
  .verify h2 {
    font-size: 18px;
  }
}
